import { useState, cloneElement, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { debounce } from 'lodash-es';
import { MENU_SEPARATOR_NAME } from './MenuSeparator';
import { ReactComponent as Logo } from '../../../assets/images/academy-logo.svg';
import { ReactComponent as OpenIcon } from '../../../assets/images/icn-burger-opened.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icn-burger-closed.svg';
import { sideNavContainer, logoContainer, logo, iconToggler, menuListContent } from './styles';

const MenuList = forwardRef(({ sideNavChildrens }, ref) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 992);
  const [activeMenu, setActiveMenu] = useState({});

  const childsData = sideNavChildrens.map(({ props }, i) => ({
    ...props,
    index: i,
  }));

  useImperativeHandle(ref, () => ({
    setIsOpen: () => setIsOpen(!isOpen),
  }));

  useEffect(() => {
    const currentMenu = childsData.find((el) => matchPath(location.pathname, el.url)) || {};
    currentMenu?.index !== activeMenu?.index && setActiveMenu(currentMenu);
  }, [location.key]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleWindowResize = debounce(() => {
    if (window.innerWidth < 992 && isOpen) setIsOpen(false);
  }, 500);

  const MenuListItems = sideNavChildrens.map((menu, i) =>
    menu.type.displayName !== MENU_SEPARATOR_NAME
      ? cloneElement(menu, {
          key: `sideNavMenu${i}`,
        })
      : cloneElement(menu, {
          key: `sideNavMenu${i}`,
          isOpen,
        }),
  );

  return (
    <nav css={sideNavContainer(isOpen)}>
      <div css={logoContainer}>
        <div role="none" css={iconToggler} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <OpenIcon /> : <CloseIcon />}
        </div>
        {isOpen && (
          <Link to="/" css={logo}>
            <Logo />
          </Link>
        )}
      </div>
      <div css={menuListContent}>{MenuListItems}</div>
    </nav>
  );
});

MenuList.propTypes = {
  sideNavChildrens: PropTypes.array,
};

export default MenuList;
