export const wrap = {
  marginBottom: 32,
};

export const body = {
  '& > h3': {
    marginBottom: 16,
  },
};

export const tabs = {
  paddingLeft: 0,
  marginBottom: 24,
  paddingBottom: 0,
};
