export const colorStatus =
  ({ type }) =>
  (theme) => {
    const colorStatusTypeMap = {
      info: {
        color: theme.info,
        backgroundColor: theme.infoLight,
      },
      error: {
        color: theme.error,
        backgroundColor: theme.errorLight,
      },
      success: {
        color: theme.success,
        backgroundColor: theme.successLight,
      },
      warning: {
        color: theme.warning,
        backgroundColor: theme.warningLight,
      },
      orange: {
        color: theme.orange,
        backgroundColor: theme.orangeLight,
      },
    };

    return [
      colorStatusTypeMap[type],
      {
        fontWeight: 500,
        padding: '4px 8px',
        borderRadius: 4,
      },
    ];
  };
