export const optionsProfileSearchBy = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'label',
    label: 'Label',
  },
  {
    value: 'catalogueItemId',
    label: 'Package ID',
  },
];

export const optionsStatus = [
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];
