import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash-es';
import { Row, Col, Dropdown, showApiError } from '../../UI';
import SearchInput from '../../Shared/SearchInput';
import { getAllCategories } from '../../../services/categories';
import { optionsProfileSearchBy, optionsStatus } from './config';

const noResultsMessages = {
  searchInput: 'Course',
  status: 'Status',
  categoryId: 'Category',
};

const TableFilterBarCourses = ({ getData, tableOptions, setEmptyMessage }) => {
  const [categories, setCategories] = useState([]);
  const params = useRef({
    searchCriteria: optionsProfileSearchBy[0].value,
    searchInput: null,
    status: null,
    categoryId: null,
  });

  useEffect(() => {
    getData();
    fetchCategories();
  }, []);

  const fetchResults = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };

    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  const fetchCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return showApiError(err);

    setCategories(res?.data ?? []);
  };

  return (
    <Row horizontalGap={16}>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          noClear
          value={optionsProfileSearchBy[0]}
          options={optionsProfileSearchBy}
          onChange={({ value: searchCriteria }) => {
            params.current = {
              ...params.current,
              searchCriteria,
            };

            params.current?.searchInput?.length && fetchResults();
          }}
        />
      </Col>
      <Col xl={6} lg={3} sm={12} xs={12}>
        <SearchInput
          placeholder="Search by name, label or package ID..."
          onChange={(searchInput) => fetchResults({ searchInput })}
        />
      </Col>

      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          placeholder="Status"
          options={optionsStatus}
          onChange={(val) => fetchResults({ status: val?.value })}
        />
      </Col>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          placeholder="Category"
          options={categories}
          displayKey="name"
          uniqueKey="id"
          onChange={(val) => fetchResults({ categoryId: val?.id })}
        />
      </Col>
    </Row>
  );
};

TableFilterBarCourses.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
};

export default TableFilterBarCourses;
