import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../Shared/SearchInput';
import { searchContainer } from './styles';
import { Col, Dropdown, Row, showApiError } from '../../UI';
import { getSubfoldersList } from '../../../services/videos';

const VideosFilterBar = (props) => {
  const { disabled, onChange } = props;
  const [subfolders, setSubfolders] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchAllSubfolders();
  }, []);

  const fetchAllSubfolders = async () => {
    const [res, err] = await getSubfoldersList();
    if (err) return showApiError(err);

    setSubfolders(res?.data);
  };

  const handleSearchChange = (searchValue) => {
    setFilters((prev) => ({ ...prev, query: searchValue }));
    return onChange({ ...filters, query: searchValue });
  };

  const handleFolderChange = (selectedFolder) => {
    setFilters((prev) => ({ ...prev, folderId: selectedFolder?.id }));

    return onChange({ ...filters, folderId: selectedFolder?.id });
  };

  return (
    <div className={searchContainer}>
      <Row horizontalGap={16}>
        <Col xl={8} lg={8} sm={12} xs={12}>
          <SearchInput disabled={disabled} placeholder="Search by video name" onChange={handleSearchChange} />
        </Col>
        <Col xl={4} lg={4} sm={12} xs={12}>
          <Dropdown
            placeholder="Select folder..."
            displayKey="name"
            uniqueKey="id"
            disabled={disabled}
            options={subfolders}
            onChange={handleFolderChange}
          />
        </Col>
      </Row>
    </div>
  );
};

VideosFilterBar.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default VideosFilterBar;
