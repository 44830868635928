import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Col, Button } from '../../UI';
import { wrap, videoWrap } from './styles';

export const VideoCard = (props) => {
  const { video, onSelect } = props;

  const handleSelect = (link) => {
    isFunction(onSelect) && onSelect(link);
  };

  return (
    <Col xl={4} lg={4} sm={6} xs={12} key={video?.uri.split('/').pop()}>
      <div className={wrap}>
        {isFunction(onSelect) && (
          <div className="selectButtonWrap">
            <Button onClick={() => handleSelect(video?.link)}>Select</Button>
          </div>
        )}
        <h3>{video?.name}</h3>
        <div className={videoWrap}>
          <iframe
            src={`https://player.vimeo.com/video/${video?.uri.split('/').pop()}?badge=0&amp;autopause=0`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={video?.name}
          />
        </div>
      </div>
    </Col>
  );
};
VideoCard.propTypes = {
  video: PropTypes.object,
  onSelect: PropTypes.func,
};
