import { useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Tabs, Tab, showApiError, showSuccess } from '../../UI';
import LessonAddEditForm from './LessonAddEditForm';
import LessonsList from './LessonsList';
import LessonsTextAddEditForm from './LessonsTextAddEditForm';
import { wrap, body, tabs } from './styles';
import { postNewLesson, postNewTextLesson } from '../../../services/lessons';

const AddLessons = () => {
  const { courseId } = useParams();
  const lessonListRef = useRef();

  const handleLessonSubmit = async (formData) => {
    const [, error] = await postNewLesson({ ...formData, courseId });
    if (error) return showApiError(error);
    showSuccess({
      title: 'Add lesson',
      message: 'Lesson added successfully.',
    });
    lessonListRef.current.refreshLessonsList();
  };

  const handleTextLessonSubmit = async (formData) => {
    const [, error] = await postNewTextLesson({ ...formData, courseId });
    if (error) {
      showApiError(error);
      return error;
    }
    showSuccess({
      title: 'Add lesson',
      message: 'Lesson added successfully.',
    });
    lessonListRef.current.refreshLessonsList();
  };

  const VideoLessons = useCallback(() => <LessonAddEditForm onSubmit={handleLessonSubmit} />, []);

  const PdfLesson = useCallback(() => <LessonsTextAddEditForm onSubmit={handleTextLessonSubmit} />, []);

  return (
    <Paper header="Course lessons" css={wrap}>
      <LessonsList ref={lessonListRef} />
      <div css={body}>
        <h3>Add lesson</h3>
        <div>
          <Tabs tabListStyle={tabs}>
            <Tab label="Video" component={VideoLessons} />
            <Tab label="PDF" component={PdfLesson} />
          </Tabs>
        </div>
      </div>
    </Paper>
  );
};

export default AddLessons;
