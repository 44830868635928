import { headerContainer } from '../../../../UI';

export const quizPaperHeader = (theme) => [
  headerContainer(theme),
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
];

export const quizCreateForm = {
  display: 'flex',
  flexDirection: 'row',
  height: 100,
  '& > div[role=presentation]': {
    width: '100%',
  },
};

export const formBtnsCreateQuizWrap = {
  display: 'flex',
  alignItems: 'center',
};

export const formBtnsCreateQuizCancel = {
  marginLeft: 8,
};

export const quizInfoWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

export const quizInfoInner = {
  display: 'flex',
  alignItems: 'center',
  '& > h3': {
    marginRight: 16,
    marginLeft: 8,
  },
};

export const questionCollapseWrap = (active) => (theme) => ({
  border: `2px solid ${active ? theme.secondary : theme.borderLight}`,
  marginBottom: 24,
  borderRadius: 8,
});

export const questionCollapseHeader = (theme) => ({
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const questionCollapseArrow = (active) => (theme) => ({
  backgroundColor: active ? theme.grayLighter : theme.grayLightest,
  height: 30,
  width: 30,
  marginLeft: 10,
  marginRight: 10,
  borderRadius: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: active ? theme.grayLightest : theme.grayLighter,
  },
});

export const questionCollapseBody = {
  padding: 10,
};

export const addQuestionButton = {
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};

export const answersListWrap = {
  width: 200,
  marginBottom: 24,
};

export const answersListItem = (active) => (theme) => ({
  padding: 5,
  display: 'flex',
  alignItems: 'center',
  border: `2px solid ${active ? theme.secondary : theme.borderLight}`,
  marginBottom: 8,
  borderRadius: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.grayLightest,
  },
  '& > p': {
    marginLeft: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const uploadQuizButton = {
  marginTop: 32,
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};
