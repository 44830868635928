import moment from 'moment';
import { isFunction, startCase } from 'lodash-es';
import { type, currencyType } from '../enums/wallets';
import { validDate } from './moment';
import { getNestedProperty } from './nestedValuesModifications';

const {
  ONE,
  // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
  //  COINSAFE,
  CASH,
  TRADING,
  TOKEN,
} = type;
const { EURO, EURO_LETTERS, TKN, ONE: COINCURRENCY } = currencyType;

export const generateName = (data = {}) => {
  if (!data) return null;
  const { firstName, middleName, lastName } = data;
  return (firstName || '').concat(middleName ? ` ${middleName}` : '').concat(lastName ? ` ${lastName}` : '') || 'N/A';
};

export const currencyByType = (value, currency, letter) => {
  const amount = value || 0;

  switch (true) {
    case currency === ONE:
      // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
      // || currency === COINSAFE
      return `${amount} ${COINCURRENCY}`;
    case currency === CASH || currency === TRADING:
      return letter ? `${amount} ${EURO_LETTERS}` : `${EURO} ${amount}`;
    case currency === TOKEN:
      return `${amount} ${TKN}`;
    default:
      return amount ?? 'N/A';
  }
};

export const displayProperty = (config, data) => {
  const value = getNestedProperty(data, config.value?.split('.'));
  const checkFlag = (flag) => config[flag] && value !== null;

  switch (true) {
    case checkFlag('bool'):
      return value ? 'Yes' : 'No';
    case checkFlag('date'):
      return validDate(value) ? moment(value).format('DD.MM.YYYY') : 'N/A';
    case checkFlag('validDate'):
      return validDate(value) ?? 'N/A';
    case checkFlag('dateTime'):
      return validDate(value) ? moment(value).format('DD.MM.YYYY, HH:mm:ss') : 'N/A';
    case checkFlag('time'):
      return validDate(value) ? moment(value).format('HH:mm:ss') : 'N/A';
    case checkFlag('currency'):
      return currencyByType(value, config.currency);
    case checkFlag('startCase'):
      return startCase(value) || 'N/A';
    case isFunction(config.render):
      return config.render(data);
    default:
      return value ?? 'N/A';
  }
};
