import { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Paper, Modal } from '../../../../UI';
import { container, header, modalWrap, bodyWrap } from './styles';
import AnswersAddMore from '../AnswersAddMore';

const AnswersAddModal = forwardRef((props, ref) => {
  const { answersData, questionData, onAnswersDataUpdate, onQuestionDataUpdate } = props;
  const [show, setshow] = useState(false);

  useImperativeHandle(ref, () => ({
    setIsShow: () => setshow(!show),
  }));

  const handleClose = () => {
    setshow(!show);
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>Add answers</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <AnswersAddMore
            answersData={answersData}
            questionData={questionData}
            onQuestionDataUpdate={onQuestionDataUpdate}
            onAnswersDataUpdate={onAnswersDataUpdate}
            onModalClose={handleClose}
          />
        </div>
      </Paper>
    </Modal>
  );
});

AnswersAddModal.propTypes = {
  answersData: PropTypes.array,
  questionData: PropTypes.object,
  onAnswersDataUpdate: PropTypes.func,
  onQuestionDataUpdate: PropTypes.func,
};

export default AnswersAddModal;
