import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Paper, Form, FormInput, Breadcrumbs, showSuccess, showApiError, FormParagraph } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import LanguageSelect from '../../../components/Shared/LanguageSelect';
import { postNewExpertTranslation, getExpertIdTranslation, updateExpertTranslations } from '../../../services/experts';
import { pagesTranslation } from './config';
import { wrapper, backContainer, paperContain, inputDescriptionContainer } from './styles';

const AddEditExpertsTranslations = () => {
  const history = useHistory();
  const { expertId, languageCode } = useParams();
  const [expertData, setExpertData] = useState({});

  useEffect(() => {
    expertId && fetchExpertData();
  }, [expertId, languageCode]);

  const fetchExpertData = async () => {
    const [res, err] = await getExpertIdTranslation(expertId, languageCode);
    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setExpertData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(expertData)
      ? updateExpertTranslations(expertId, languageCode, {
          ...expertData,
          ...formData,
        })
      : postNewExpertTranslation(expertId, { ...formData, languageCode }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(expertData) ? 'Edited' : 'Create'} expert translation`,
      message: `Successfully ${!isEmpty(expertData) ? 'edited' : 'create'} expert translation.`,
    });

    setExpertData((prev) => ({ ...prev, ...formData }));
  };

  const handleLanguageChange = (code) => {
    history.push(`/experts/${expertId}/${code}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pagesTranslation(expertId, !isEmpty(expertData))} />
      <BackButton
        linkTo="/experts"
        css={backContainer}
        title={!isEmpty(expertData) ? 'Edit Expert Translations' : 'Add Expert Translations'}
      />
      <LanguageSelect onChange={handleLanguageChange} />
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: !isEmpty(expertData) ? 'Edit Expert Translations' : 'Add Expert Translations',
          }}
          values={expertData}>
          <FormInput id="firstName" label="First name" required />
          <FormInput id="lastName" label="Last name" required />
          <FormInput id="position" label="Position" required />
          <FormParagraph css={inputDescriptionContainer}>
            <FormInput
              id="description"
              type="textarea"
              label="Description"
              placeholder="Description"
            />
          </FormParagraph>
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditExpertsTranslations;
