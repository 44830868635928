import { getReq, postReq, putReq } from './axios/makeRequest';
import { getAllLanguagesParams, addLanguageBody, editLanguageBody } from './models/languages';

const baseUrl = `${apiUrls.oneAcademy}//languages`;

export const postNewLanguage = (values) => postReq(`${baseUrl}/new`, addLanguageBody(values));

export const getLanguageById = async (id) => {
  const [response, error] = await getReq(`${baseUrl}/${id}`);
  return [response, error];
};

export const updateLanguage = (id, data) => putReq(`${baseUrl}/${id}/update`, editLanguageBody(data));

export const getAllLanguages = (options = {}) => {
  const params = getAllLanguagesParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
