export const containerClass = {
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  marginLeft: 0,
  padding: 0,
};

export const text = (theme) => ({
  fontSize: 14,
  fontWeight: 600,
  color: theme.grayLight,
});

export const linkItem = (isActive) => (theme) => ({
  fontSize: 14,
  fontWeight: 600,
  color: isActive ? theme.info : theme.grayLight,
});

export const separator = (theme) => ({
  margin: '0px 8px',
  fontSize: 14,
  fontWeight: 600,
  color: theme.grayLight,
});
