import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Paper,
  Form,
  FormDropdown,
  FormParagraph,
  FormInput,
  Breadcrumbs,
  showSuccess,
  showApiError,
} from '../../../../components/UI';
import { rowComp, colComp } from '../../../../components/UI/Grid';
import BackButton from '../../../../components/Shared/BackButton';
import {
  postNewCondition,
  getConditionById,
  updateCondition,
  getConditionConfig,
} from '../../../../services/conditions';
import { pages, booleanSelectValues } from './config';
import { wrapper, backContainer, paperContain, formParagraphColInputs } from '../styles';

const positiveIntPattern = (val) => /^[1-9][0-9]{0,}$/.test(val);

const AddEditCondition = () => {
  const history = useHistory();
  const { conditionId } = useParams();
  const [conditionData, setConditionData] = useState();
  const [valueType, setValueType] = useState();
  const [config, setConfig] = useState();

  useEffect(() => {
    fetchConditionsConfig();
    conditionId && fetchConditionData();
  }, [conditionId]);

  const fetchConditionData = async () => {
    const [res, err] = await getConditionById(conditionId);

    if (err) return showApiError(err);

    setConditionData(res);
    setValueType(res?.assertionValueType);
  };

  const fetchConditionsConfig = async () => {
    const [res, err] = await getConditionConfig();

    if (err) return showApiError(err);

    setConfig(res);
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (conditionId
      ? updateCondition(conditionId, { ...conditionData, ...formData })
      : postNewCondition(formData));
    if (error) return showApiError(error);
    showSuccess({
      title: `${conditionId ? 'Edited' : 'Create'} condition`,
      message: `Successfully ${conditionId ? 'edited' : 'create'} condition.`,
    });

    history.push('/badges/all-conditions');
  };

  const handleFormChange = (id, val) => {
    if (id === 'assertionValueType') setValueType(val);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages(conditionId)} />
      <BackButton css={backContainer} title={conditionId ? 'Edit Condition' : 'Add Condition'} />
      <Paper css={paperContain}>
        {((conditionData && config && conditionId) || !conditionId) && (
          <Form
            onSubmit={handleSubmit}
            onChange={handleFormChange}
            submitButton={{
              children: conditionId ? 'Edit Condition' : 'Add Condition',
            }}
            values={conditionData}>
            <FormInput id="name" label="Name" required />
            {!conditionId && (
              <FormDropdown
                id="queryAlias"
                label="Query Aliases"
                placeholder="Select query aliases..."
                options={config?.queryAliases}
                mapValue={(val) => val?.value}
                required
              />
            )}
            <FormParagraph css={rowComp({ margin: '0 -16px' })}>
              {!conditionId && (
                <FormParagraph
                  css={colComp({
                    sm: '6',
                    xs: '6',
                    horizontalGap: 16,
                  })}>
                  <FormDropdown
                    id="assertionValueType"
                    label="Value Types"
                    placeholder="Select value type..."
                    options={config?.assertionValueTypes}
                    mapValue={(val) => val?.value}
                    required
                  />
                </FormParagraph>
              )}
              <FormParagraph
                css={colComp({
                  sm: conditionId ? '12' : '6',
                  xs: conditionId ? '12' : '6',
                  horizontalGap: 16,
                  dFlex: true,
                })}>
                {valueType && (
                  <FormParagraph css={formParagraphColInputs}>
                    {valueType === 'number' ? (
                      <FormInput id="assertionValue" label="Value" required pattern={positiveIntPattern} />
                    ) : (
                      <FormDropdown
                        id="assertionValue"
                        label="Value"
                        placeholder="Select value..."
                        options={booleanSelectValues}
                        mapValue={(val) => val?.value}
                        required
                      />
                    )}
                  </FormParagraph>
                )}
              </FormParagraph>
            </FormParagraph>
            {!conditionId && (
              <FormDropdown
                id="assertionOperator"
                label="Operator"
                placeholder="Select operator..."
                options={config?.assertionOperators}
                mapValue={(val) => val?.value}
                required
              />
            )}
            <FormDropdown
              id="assertionPeriod"
              label="Period"
              placeholder="Select period..."
              options={config?.assertionPeriods}
              mapValue={(val) => val?.value}
              required
            />
          </Form>
        )}
      </Paper>
    </div>
  );
};

export default AddEditCondition;
