import { Route } from 'react-router-dom';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useAuth, Notifications, Menu, SideNav } from '../components/UI';
import { PrivateRoutes } from './Private/PrivateRoutes';
import { PublicRoutes } from './Public/PublicRoutes';
import Unauthorized from './Public/Unauthorized';
import Dashboard from './Private/Dashboard';
import NotFound from './Private/NotFound';
import Categories from './Private/Categories';
import Experts from './Private/Experts';
import Courses from './Private/Courses';
import Videos from './Private/Videos';
import BadgesPage from './Private/Badges';
import AcademyAccounts from './Private/AcademyAccounts';
import Languages from './Private/Languages';

const MAIN_PUBLIC_ROUTE = '/login';

const App = () => {
  const { isAuthenticated, isAuthorized } = useAuth();
  const isAdmin = hasRole(USER_ROLES.ADMINISTRATOR);

  const checkHomeRoute = () => {
    switch (true) {
      case isAdmin:
        return '/dashboard';
      default:
        return '/';
    }
  };

  return (
    <>
      <Notifications />
      {isAuthenticated ? (
        isAuthorized ? (
          <SideNav homeRoute={checkHomeRoute()} routes={PrivateRoutes({ isAdmin })} notFoundComponent={NotFound}>
            <Menu withPadding url="/dashboard" label="Dashboard" icon="home" component={Dashboard} />
            <Menu
              url="/categories"
              label="Categories"
              icon="fas fa-border-all"
              iconType={{ material: false }}
              component={Categories}
            />
            <Menu
              url="/experts"
              label="Experts"
              icon="fas fa-address-card"
              iconType={{ material: false }}
              component={Experts}
            />
            <Menu
              url="/courses"
              label="Courses"
              icon="fas fa-chalkboard-teacher"
              iconType={{ material: false }}
              component={Courses}
            />
            <Menu
              url="/videos"
              label="Videos"
              icon="fas fa-video"
              iconType={{ material: false }}
              component={Videos}
              noPadding
            />
            <Menu url="/badges" label="Badges" icon="military_tech" component={BadgesPage} />
            <Menu url="/academy-accounts" label="Academy Accounts" icon="school" component={AcademyAccounts} />
            <Menu url="/languages" label="Languages" icon="language" component={Languages} />
          </SideNav>
        ) : (
          <Route path="/" component={Unauthorized} />
        )
      ) : (
        PublicRoutes(MAIN_PUBLIC_ROUTE)
      )}
    </>
  );
};

export default App;
