export const optionsStatus = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];

export const optionsVisibility = [
  {
    label: 'Visible',
    value: true,
  },
  {
    label: 'Invisible',
    value: false,
  },
];
