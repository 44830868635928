import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isString } from 'lodash-es';
import { Button } from '../../UI';
import { ReactComponent as BackIcon } from '../../../assets/images/icn-go-back.svg';
import { headerContain, buttonBack } from './styles';

const BackButton = (props) => {
  const { title, className, linkTo } = props;
  const history = useHistory();

  const handleBack = () => {
    linkTo ? history.push(linkTo) : history.goBack();
  };

  return (
    <div css={headerContain} {...(isString(className) && { className })}>
      <Button outlineInverse onClick={handleBack} css={buttonBack}>
        <BackIcon />
        <span>Go back</span>
      </Button>
      {isValidElement(title) ? title : <h1>{title}</h1>}
    </div>
  );
};

BackButton.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  linkTo: PropTypes.string,
};

export default BackButton;
