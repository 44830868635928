import { softLogout } from '@oneecosystem/authenticate';
import { Button } from '../Button';
import { useAuth } from '../Authenticate';
import { rightSideContainer } from './styles';

const NavLinks = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div css={rightSideContainer}>
      {isAuthenticated && (
        <Button outline small onClick={softLogout}>
          Logout
        </Button>
      )}
    </div>
  );
};

export default NavLinks;
