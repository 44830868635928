import { Paper, Breadcrumbs } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import UploadVideoForm from '../../../components/Videos/UploadVideoForm';
import { pages } from './config';
import { wrapper, backContainer, paperContain } from './styles';

const UploadVideo = () => (
  <div css={wrapper}>
    <Breadcrumbs items={pages} />
    <BackButton css={backContainer} title="Upload Video" />
    <Paper css={paperContain}>
      <UploadVideoForm />
    </Paper>
  </div>
);

export default UploadVideo;
