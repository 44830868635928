import moment from 'moment';
import { add } from 'exact-math';
import { getAllTransactions } from '../../services/transactions';
import { type as walletType } from '../../enums/wallets';

export const getDateRangeTransactions = async (dateRange) => {
  const [res, err] = await getAllTransactions({
    ...dateRange,
    pageIndex: 0,
    pageSize: 50,
    excludeMaster: true,
  });

  if (err) return [null, err];

  const coin = { total: 0, transactions: {} };
  const cash = { total: 0, transactions: {} };
  const latestTransactions = [];

  res?.transactions.forEach((el) => {
    if (el.walletType === walletType.ONE) {
      const date = moment(el.createdOn).format('DD/MM/YYYY');
      coin.transactions[date] = (coin.transactions[date] ?? 0) + 1;
      coin.total = add(coin.total, el.amount);
      latestTransactions.push(el);
    }

    if (el.walletType === walletType.CASH || el.walletType === walletType.TRADING) {
      const date = moment(el.createdOn).format('DD/MM/YYYY');
      cash.transactions[date] = (cash.transactions[date] ?? 0) + 1;
      cash.total = add(cash.total, el.amount);
    }
  });

  cash.transactions = Object.values(cash.transactions);
  coin.transactions = Object.values(coin.transactions);

  cash.transactions.length === 1 && cash.transactions.push(cash.transactions[0]);
  coin.transactions.length === 1 && coin.transactions.push(coin.transactions[0]);

  return [{ coin, cash, latestTransactions }, null];
};
