import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Row, Col, Button, Image } from '../../../UI';
import CourseAddEditForm from '../CourseAddEditForm';
import CourseTranslationModal from '../CourseTranslationModal';
import LanguageSelect from '../../../Shared/LanguageSelect';
import uuid from '../../../../utils/uuid';
import { wrap, container, pictureContainer, wordWrap } from './styles';
import badgeDefault from '../../../../assets/images/default-badge.jpeg';

const CourseInfoBox = forwardRef((props, ref) => {
  const { data, onCourseInfoChange } = props;
  const [isEdit, setIsEdit] = useState(false);

  const translationsModalRef = useRef(null);
  const languageSelectRef = useRef(null);

  useImperativeHandle(ref, () => ({
    setIsEdit: () => setIsEdit(!isEdit),
  }));

  const handleLanguageChange = (newData) => {
    if (!newData) return;
    translationsModalRef.current.setIsShow();
    translationsModalRef.current.setLanguageData({ ...newData, uuid: uuid() });
  };

  return (
    <>
      <Paper
        css={wrap}
        header={
          <div css={container}>
            <h2>Course Info</h2>
            {!isEdit && <Button onClick={() => setIsEdit(true)}>Edit</Button>}
          </div>
        }>
        {data && (
          <>
            {isEdit ? (
              <>
                <LanguageSelect ref={languageSelectRef} fullData onChange={handleLanguageChange} />
                <CourseAddEditForm onSubmit={onCourseInfoChange} data={data} />
              </>
            ) : (
              <>
                <Image
                  src={data?.picUrl ?? badgeDefault}
                  width={200}
                  height={120}
                  size="cover"
                  css={pictureContainer}
                />
                <Row md={6} margin="0 0 16px">
                  <Col>
                    <h4>Name</h4>
                  </Col>
                  <Col>
                    <span>{data?.name ?? 'N/A'}</span>
                  </Col>
                </Row>
                <Row md={6} margin="0 0 16px">
                  <Col>
                    <h4>Label</h4>
                  </Col>
                  <Col>
                    <span>{data?.label ?? 'N/A'}</span>
                  </Col>
                </Row>
                <Row md={6} margin="0 0 16px">
                  <Col>
                    <h4>Category</h4>
                  </Col>
                  <Col>
                    <span>{data?.category?.name ?? 'N/A'}</span>
                  </Col>
                </Row>
                <Row md={6} margin="0 0 16px">
                  <Col>
                    <h4>Expert</h4>
                  </Col>
                  <Col>
                    <span>{data?.expert?.name ?? 'N/A'}</span>
                  </Col>
                </Row>
                <Row md={6} margin="0 0 16px">
                  <Col>
                    <h4>Description</h4>
                  </Col>
                  <Col>
                    <span css={wordWrap}>{data?.description ?? 'N/A'}</span>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Paper>

      <CourseTranslationModal ref={translationsModalRef} onSubmit={() => languageSelectRef.current.resetSelect()} />
    </>
  );
});

CourseInfoBox.propTypes = {
  data: PropTypes.object,
  onCourseInfoChange: PropTypes.func,
};

export default CourseInfoBox;
