import { useRef } from 'react';

export const useConstant = value => {
  const valueRef = useRef(null);

  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  if (valueRef.current === null) valueRef.current = value;

  return [
    valueRef.current,
    newValue => {
      valueRef.current = newValue;
    },
    valueRef,
  ];
};
