import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Paper,
  Form,
  FormInput,
  FormParagraph,
  CheckBoxForm,
  Breadcrumbs,
  showSuccess,
  showApiError,
} from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import LanguageSelect from '../../../components/Shared/LanguageSelect';
import { postNewCategory, getCategoryById, updateCategory } from '../../../services/categories';
import { pages } from './config';
import { wrapper, backContainer, paperContain } from './styles';

const AddEditCategory = () => {
  const history = useHistory();
  const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    categoryId && fetchCategoryData();
  }, [categoryId]);

  const fetchCategoryData = async () => {
    const [res, err] = await getCategoryById(categoryId);
    err ? showApiError(err) : setCategoryData(res);
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (categoryId
      ? updateCategory(categoryId, { ...categoryData, ...formData })
      : postNewCategory(formData));
    if (error) return showApiError(error);
    showSuccess({
      title: `${categoryId ? 'Edited' : 'Create'} category`,
      message: `Successfully ${categoryId ? 'edited' : 'create'} category.`,
    });

    history.push('/categories');
  };

  // const SubmitBar = ({ submitButton: Submit, options }) => {
  //   const { values } = options;
  //   return (
  //     <Submit {...(categoryId && { disabled: isEmpty(values) })}>
  //       {categoryId ? 'Edit Category' : 'Add Category'}
  //     </Submit>
  //   );
  // };

  const handleLanguageChange = (code) => {
    history.push(`/categories/${categoryId}/${code}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages(categoryId)} />
      <BackButton css={backContainer} title={categoryId ? 'Edit Category' : 'Add Category'} />
      {categoryId && <LanguageSelect onChange={handleLanguageChange} />}

      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: categoryId ? 'Edit Category' : 'Add Category',
          }}
          // renderSubmit={SubmitBar}
          values={categoryData}>
          <FormInput id="name" label="Category name" required />
          <FormParagraph>
            <CheckBoxForm id="visible" label="Visible" />
          </FormParagraph>
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditCategory;
