import { getReq } from './axios/makeRequest';
import { getAllPackagesParams, getPackageModel } from './models/packages';

const baseUrl = `${apiUrls.oneLife}/packages`;

export const getAllPackages = async (options = {}) => {
  const params = getAllPackagesParams(options);

  const [data, err] = await getReq(`${baseUrl}/Packages/list?${params}`);

  return [
    {
      catalogueItems: data?.catalogueItems?.map(getPackageModel),
      total: data?.total,
    },
    err,
  ];
};
