import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useWysiwygInput } from './useWysiwygInput';
import errorIcon from '../../../assets/images/icn-alert-warning-input.svg';
import successIcon from '../../../assets/images/icn-sm-checkmark-tick.svg';
import { wysiwygContainer, inputLabel, inputHelpContainer, inputHelpIcon } from './styles';

const WysiwygInput = forwardRef((props, inputRef) => {
  const { id, required, label, horizontal, successLabel = 'Field', className } = props;

  // We had a custom hook which handles the logic behind the input
  // onChange, onBlur are methods that are implemented into the hook
  const { isTouched, error, value, ...inputAttr } = useWysiwygInput({
    ...props,
    inputRef,
  });

  const isCorrect = isTouched && !error;
  const hesError = isTouched && error;

  return (
    <div css={wysiwygContainer} {...(isString(className) && { className })}>
      {label && (
        <label htmlFor={id} css={inputLabel(horizontal)}>
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}
      <CKEditor editor={ClassicEditor} data={value} {...inputAttr} />
      {(hesError || isCorrect) && (
        <div css={inputHelpContainer(!!error)}>
          <div css={inputHelpIcon(!!error)}>
            <img src={error ? errorIcon : successIcon} alt="Logo" />
          </div>
          <span>{error ? error.msg : `${label || successLabel} is correct`}</span>
        </div>
      )}
    </div>
  );
});

WysiwygInput.propTypes = {
  inputRef: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  data: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  horizontal: PropTypes.bool,
  successLabel: PropTypes.string,
  className: PropTypes.string,
};

export default WysiwygInput;
