export const wrap = (theme) => ({
  position: 'relative',
  width: '100%',
  padding: 10,
  borderLeft: `1px solid ${theme.borderLight}`,
});

export const questionInfo = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const questionInfoTitle = {
  display: 'flex',
  alignItems: 'center',
  '& > h3': {
    marginRight: 16,
    marginLeft: 8,
  },
};

export const inputDescriptionContainer = {
  minHeight: 100,
  marginBottom: 48,
  ' & > textarea': {
    minHeight: 100,
  },
};

export const formBtns = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const removeBtn = (theme) => ({
  position: 'absolute',
  top: -17,
  right: -15,
  backgroundColor: theme.white,
  border: `2px solid ${theme.gray}`,
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 30,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.borderLight,
  },
});
