import { getReq, putReq } from './axios/makeRequest';
import { updateCatalogueItemsCoursesModel, getAllCatalogueItemsParams } from './models/catalogue-items';

const baseUrl = `${apiUrls.oneAcademy}/catalogue-items`;

export const getAllCatalogueItems = async (options = {}) => {
  const params = getAllCatalogueItemsParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};

export const getCourseByCatalogueItemId = async (id) => getReq(`${baseUrl}/${id}`);

export const updateCatalogueItemCourses = (id, data) =>
  putReq(`${baseUrl}/${id}/update`, updateCatalogueItemsCoursesModel(data));
