import { Link } from 'react-router-dom';
import { Table } from '../../../components/UI';
import { getAllExperts } from '../../../services/experts';
import { columns } from './config';
import { wrapper, container, navLink } from './styles';

const Experts = () => {
  const fetchAllExperts = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllExperts(options);
    return res;
  };

  return (
    <div css={wrapper}>
      <div css={container}>
        <h1>Experts List</h1>
        <Link to="/experts/add-expert" css={navLink}>
          Create Expert
        </Link>
      </div>

      <Table
        getDataKey="data"
        getDataMethod={fetchAllExperts()}
        emptyMessage="There are no experts."
        columns={columns}
        pageSize={10}
      />
    </div>
  );
};

export default Experts;
