export const modalWrap = (theme) => ({
  '& .inner': {
    width: '100%',
    backgroundColor: theme.white,
    padding: '24px 32px',
    borderRadius: 4,
    '& > h3': {
      marginBottom: 8,
    },
    '& > p': {
      fontSize: 14,
      marginBottom: 16,
    },
    '& > .btn-box': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});
