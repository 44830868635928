import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash-es';
import Dropdown from '../Dropdown';
import { outputValue } from '../Dropdown/utils';
import { validate as inputValidation } from '../../../utils';
import errorIcon from '../../../assets/images/icn-alert-warning-input.svg';
import successIcon from '../../../assets/images/icn-sm-checkmark-tick.svg';
import {
  inputContainer,
  inputLabel,
  dropdownInput,
  horizontalContainer,
  inputHelpContainer,
  inputHelpIcon,
} from './styles';

const DropdownForm = forwardRef(
  (
    {
      id,
      required,
      onChange,
      onError,
      label,
      validate,
      horizontal,
      value,
      mapValue,
      noSimplify,
      isTouched: isTouchedInit,
      className,
      uniqueKey = 'value',
      displayKey = 'label',
      successLabel,
      noValidation = false,
      ...dropDownProps
    },
    dropdownRef,
  ) => {
    const { disabled, options, multiSelect } = dropDownProps;
    const [error, setError] = useState(false);
    const internalDropDownRef = useRef(null);
    const [isTouched, setIsTouched] = useState(isTouchedInit);
    const hasError = isTouched && error;
    const isCorrect = isTouched && !error;
    const isDisabled = disabled || !options?.length;

    useImperativeHandle(dropdownRef, () => ({
      changeValue: (newValue) => {
        handleSelect(newValue);
        internalDropDownRef.current?.changeValue(newValue);
      },
      value: () => internalDropDownRef.current?.value,
      displayValue: () => internalDropDownRef.current?.displayValue,
      changeIsTouched: setIsTouched,
    }));

    useEffect(() => {
      setIsTouched(isTouchedInit);
    }, [isTouchedInit]);

    useEffect(() => {
      isTouched && checkForError(internalDropDownRef.current.value);
    }, [isTouched]);

    const handleSelect = (input) => {
      const newValue = input ?? (multiSelect ? [] : null);
      checkForError(newValue);
      onChange(id, outputValue(newValue, displayKey, mapValue, !noSimplify));
    };

    const handleBlur = ({ currentTarget }) =>
      !isDisabled &&
      setTimeout(() => {
        if (currentTarget.contains(document.activeElement)) return;
        isTouched ? checkForError(internalDropDownRef.current.value) : setIsTouched(true);
      });

    const checkForError = (newValue) => {
      let newError = null;

      // If validate function is provided check for error
      if (isFunction(validate)) newError = validate(newValue, id);
      else if (required)
        newError = multiSelect ? isEmpty(newValue) && { msg: 'Required field' } : inputValidation(newValue, 'required');

      onError(id, newError);
      isTouched && setError(newError);
    };

    const dropdownComponent = (
      <Dropdown
        ref={internalDropDownRef}
        value={value}
        noSimplify
        noPrefilled={!required}
        css={dropdownInput(hasError)}
        onChange={handleSelect}
        {...dropDownProps}
        placeholder={dropDownProps.placeholder || `Select ${label}...`}
        displayKey={displayKey}
        uniqueKey={uniqueKey}
      />
    );

    return (
      <div role="menuitem" tabIndex="0" onBlur={handleBlur} css={inputContainer(horizontal, className)}>
        <label htmlFor={id} css={inputLabel(horizontal, isDisabled)}>
          {`${label}${required ? ' *' : ''}`}
        </label>
        {horizontal ? <div css={horizontalContainer}>{dropdownComponent}</div> : dropdownComponent}
        {(hasError || isCorrect) && !noValidation && (
          <div css={inputHelpContainer(!!error)}>
            <div css={inputHelpIcon(!!error)}>
              <img src={error ? errorIcon : successIcon} alt="Logo" />
            </div>
            <span>{error ? error.msg : `${label || successLabel} is correct`}</span>
          </div>
        )}
      </div>
    );
  },
);

DropdownForm.propTypes = {
  dropdownRef: PropTypes.object,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  mapValue: PropTypes.func,
  horizontal: PropTypes.bool,
  validate: PropTypes.func,
  noSimplify: PropTypes.bool,
  noValidation: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  successLabel: PropTypes.string,
  uniqueKey: PropTypes.string,
  displayKey: PropTypes.string,
  className: PropTypes.string,
};

export default DropdownForm;
