import { pick, isNil } from 'lodash-es';
import queryString from 'query-string';

export const addLanguageBody = (body) => ({
  name: body?.name,
  code: body?.code,
  active: body?.active ?? false,
  isDefault: body?.isDefault ?? false,
});

export const editLanguageBody = (body) => ({
  name: body?.name,
  active: body?.active,
  isDefault: body?.isDefault,
});

export const getAllLanguagesParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (!isNil(options.active)) {
    filterFields.push('active');
    filterValues.push(options.active);
  }

  if (!isNil(options.isDefault)) {
    filterFields.push('isDefault');
    filterValues.push(options.isDefault);
  }

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
