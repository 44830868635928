import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  showApiError,
  showSuccess,
  Button,
  Form,
  FormInput,
  CheckBoxForm,
  Tooltip,
  Icon,
  Row,
  Col,
  FormParagraph,
} from '../../../../UI';
import { AnswersList } from '../../Answers';
import QuestionsTranslationModal from '../QuestionsTranslationModal';
import LanguageSelect from '../../../../Shared/LanguageSelect';
import uuid from '../../../../../utils/uuid';
import { getAllQuestions, updateQuestion } from '../../../../../services/quizzes';
import {
  wrap,
  questionInfoTitle,
  formBtns,
  inputDescriptionContainer,
  actionsButtonsWrap,
  iconButton,
  loadMoreButton,
} from './styles';

const QuestionsList = (props) => {
  const { quizId } = props;
  const [questions, setQuestions] = useState({});
  const [selected, setSelected] = useState(null);
  const [showAnswers, setShowAnswers] = useState(null);

  const pageIndex = useRef(0);
  const translationsModalRef = useRef(null);
  const languageSelectRef = useRef(null);

  useEffect(() => {
    fetchAllQuestions();
  }, [quizId]);

  const fetchAllQuestions = async () => {
    const [res, err] = await getAllQuestions({
      pageSize: 25,
      pageIndex: pageIndex.current,
      searchCriteria: 'quizId',
      searchInput: String(quizId),
    });

    if (err) return showApiError(err);

    setQuestions((prev) => ({
      data: prev?.data ? [...(prev?.data ?? []), ...(res?.data ?? [])] : res?.data,
      total: res?.total,
    }));
    pageIndex.current += 1;
  };

  const handleSubmit = async (formData, questionData) => {
    const data = {
      ...questionData,
      ...formData,
    };

    const [, error] = await updateQuestion(questionData?.id, data);
    if (error) return showApiError(error);

    const updatedQuestions = questions?.data?.map((el) => (el?.id === questionData?.id ? { ...el, ...data } : el));

    showSuccess({
      title: 'Edited question',
      message: 'Successfully edited question.',
    });

    setQuestions((prev) => ({ data: updatedQuestions, total: prev?.total }));
    setSelected(null);
  };

  const handleQuestionUpdate = (data) => {
    const updatedQuestions = questions?.data?.map((el) => (el.id === data.id ? { ...el, ...data } : el));
    setQuestions(updatedQuestions);
  };

  const SubmitBar = ({ onSubmit }) => (
    <div css={formBtns}>
      <Button onClick={onSubmit}>Save</Button>
      <Button secondary onClick={() => setSelected(null)}>
        Cancel
      </Button>
    </div>
  );

  const handleLanguageChange = (data) => {
    if (!data) return;
    translationsModalRef.current.setIsShow();
    translationsModalRef.current.setLanguageData({ ...data, uuid: uuid() });
  };

  return (
    <>
      <div css={wrap}>
        {questions?.data?.map((el) => (
          <div key={el.id}>
            <div css={wrap}>
              {selected === el.id ? (
                <>
                  <LanguageSelect ref={languageSelectRef} fullData onChange={handleLanguageChange} />
                  <Form
                    renderSubmit={SubmitBar}
                    onSubmit={(formData) => handleSubmit(formData, el)}
                    submitButton={{ children: 'Save' }}
                    values={el}
                  >
                    <FormParagraph css={inputDescriptionContainer}>
                      <FormInput
                        id="title"
                        type="textarea"
                        label="Title"
                        placeholder="Title"
                        required
                      />
                    </FormParagraph>
                    <CheckBoxForm id="visible" label="Visible" />
                  </Form>
                </>
              ) : (
                <Row horizontalGap={8}>
                  <Col flex="1 0 0px " dFlex>
                    <div css={questionInfoTitle}>
                      <Tooltip content={el?.visible ? 'visible' : 'not-visible'}>
                        <Icon
                          material
                          color={el?.visible ? 'info' : 'gray'}
                          iconName={el?.visible ? 'visibility' : 'visibility_off'}
                        />
                      </Tooltip>
                      <h4>{el?.title}</h4>
                    </div>
                  </Col>
                  <Col flex="0 1 auto" dFlex>
                    <div css={actionsButtonsWrap}>
                      <Tooltip content="Edit Questions" position="top">
                        <Button
                          leftIcon={{
                            material: true,
                            color: 'white',
                            iconName: 'edit',
                          }}
                          css={iconButton}
                          onClick={() => setSelected(el.id)}
                        />
                      </Tooltip>
                      <Tooltip content="Show Answers" position="top">
                        <Button
                          outline
                          leftIcon={{
                            material: true,
                            color: 'black',
                            iconName: selected === el.id ? 'expand_less' : 'expand_more',
                          }}
                          css={iconButton}
                          onClick={() => setShowAnswers(showAnswers === el.id ? null : el.id)}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {showAnswers === el.id && (
              <AnswersList
                questionData={questions?.data?.find((item) => item.id === showAnswers)}
                onQuestionDataUpdate={(data) => handleQuestionUpdate(data)}
                onAnswersDataUpdate={() => setShowAnswers(null)}
              />
            )}
          </div>
        ))}
        {questions?.data?.length < questions?.total && (
          <div css={loadMoreButton}>
            <Button info onClick={fetchAllQuestions}>
              Load more
            </Button>
          </div>
        )}
      </div>
      <QuestionsTranslationModal
        questionId={selected}
        ref={translationsModalRef}
        onSubmit={() => languageSelectRef.current.resetSelect()}
      />
    </>
  );
};

QuestionsList.propTypes = {
  quizId: PropTypes.number,
};

export default QuestionsList;
