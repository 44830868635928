import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isUndefined, omit, isFunction, uniqueId, isArray, isString } from 'lodash-es';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TablePagination from './TablePagination';
import { Skeleton } from '../Loader';
import { NoResults } from './NoResults';
import { showApiError } from '../Notifications';
import { useUrlParams } from '../hooks';
import { tableContainer, containerBody, filterBarContainer } from './styles';

const tableSystemProps = ['totalPages', 'totalResults'];

const Table = forwardRef((props, reference) => {
  const {
    getDataMethod,
    getDataKey,
    columns,
    pageSize,
    emptyMessage: emptyMessageProp,
    filterBar,
    footerBar,
    className,
    updateOn,
    onSelect,
    hasPagination = true,
    hasSelection: hasSelectionProp,
    pageSizes,
    fillUpUrlParams = true,
    renderRow,
    onRowClick,
    rowLink,
  } = props;

  const [data, setData] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(emptyMessageProp);
  const hasSelection = isFunction(onSelect) || hasSelectionProp;
  const { urlQueryParams, setUrlQueryParams } = useUrlParams();
  const isInitialMount = data === null;

  const tableOptions = useRef({
    pageSize: parseInt(urlQueryParams.pageSize ?? pageSize ?? 20, 10),
    pageIndex: parseInt(urlQueryParams.pageIndex ?? 0, 10),
    totalPages: 1,
    totalResults: 0,
    sort: urlQueryParams.sort,
  });

  useImperativeHandle(reference, () => ({
    tableOptions,
    getData: (params) => getData(params),
    selectRow: (row) => selectRow(row),
    changeEmptyMessage: setEmptyMessage,
  }));

  useEffect(
    () => {
      !filterBar && getData();
    },
    isArray(updateOn) ? updateOn : [updateOn],
  );

  const getData = async (options = {}) => {
    tableOptions.current = {
      ...tableOptions.current,
      ...options,
      ...(isUndefined(options.pageIndex) &&
        !isInitialMount && {
          pageIndex: 0,
          totalPages: 1,
        }),
    };
    const requestOptions = omit(tableOptions.current, tableSystemProps);

    const [res, err] = await getDataMethod(requestOptions);

    if (err) return showApiError(err);

    tableOptions.current.totalPages = Math.ceil(res?.total / tableOptions.current.pageSize) || 1;
    tableOptions.current.totalResults = res?.total;
    setData(
      res
        ? res[getDataKey].map((el) => ({
            ...el,
            _table: { isSelected: false, uuid: uniqueId() },
          }))
        : [],
    );

    // By default all request options will be save into the ulr, so all filters can be kept
    fillUpUrlParams && setUrlQueryParams(requestOptions);
  };

  const clearTableData = () => {
    tableOptions.current = {
      ...tableOptions.current,
      totalPages: 1,
      totalResults: 0,
      pageIndex: 0,
    };
    setData([]);
  };

  const selectRow = (value, row) => {
    // If row is not passed mark all rows from the table
    const compare = (el) => !row || el._table.uuid === row._table.uuid;

    setData((prev) => {
      const newRows = prev.map((el) => (compare(el) ? { ...el, _table: { ...el._table, isSelected: value } } : el));

      isFunction(onSelect) && onSelect(newRows.filter((el) => el._table.isSelected));

      return newRows;
    });
  };

  const tableDataProps = {
    tableOptions,
    getData,
    data,
    setData,
    selectRow,
    setEmptyMessage,
    clearTableData,
  };

  return (
    <>
      {filterBar && <div css={filterBarContainer}>{filterBar(tableDataProps)}</div>}
      <div css={tableContainer} {...(isString(className) && { className })}>
        <TableHead
          hasSelection={hasSelection}
          columns={columns}
          allSelected={data?.every((el) => el?._table?.isSelected)}
          {...tableDataProps}
        />
        {data?.length === 0 ? (
          <NoResults emptyMessage={emptyMessage} />
        ) : !data ? (
          <Skeleton count={tableOptions.current.pageSize} height={30} marginBottom={12} />
        ) : (
          <div css={containerBody}>
            <TableBody
              hasSelection={hasSelection}
              data={data}
              columns={columns}
              renderRow={renderRow}
              onRowClick={onRowClick}
              rowLink={rowLink}
              {...tableDataProps}
            />
          </div>
        )}
        {hasPagination && <TablePagination {...tableDataProps} pageSizes={pageSizes} />}
        {footerBar && footerBar(tableDataProps)}
      </div>
    </>
  );
});

Table.propTypes = {
  getDataMethod: PropTypes.func,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  emptyMessage: PropTypes.string,
  filterBar: PropTypes.func,
  footerBar: PropTypes.func,
  updateOn: PropTypes.any,
  className: PropTypes.string,
  getDataKey: PropTypes.string,
  onSelect: PropTypes.func,
  hasPagination: PropTypes.bool,
  hasSelection: PropTypes.bool,
  pageSizes: PropTypes.array,
  fillUpUrlParams: PropTypes.bool,
  renderRow: PropTypes.func,
  onRowClick: PropTypes.func,
  rowLink: PropTypes.any,
};

export default Table;
