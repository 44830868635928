import PropTypes from 'prop-types';
import { Col, headerContainer, Image, Paper, Row } from '../../../../components/UI';
import { badgeTitle, badgeWrap, noBadgesWrap, pictureContainer } from '../styles';
import badgeDefault from '../../../../assets/images/default-badge.jpeg';
import ColorBatch from '../../../../components/Shared/ColorBatch';

const AccountAchievements = ({ accomplishment }) => {
	return (
		<Col xl={12} md={12}>
			<Paper
				header={
					<>
						<div css={headerContainer}>
							<Row gap={8} align="center" justify="space-between">
								<Col flex="0 1 auto">
									<h3>Accomplishments</h3>
								</Col>
								<Col flex="0 1 auto">
									<h3>Points: {accomplishment?.points ?? 0}</h3>
								</Col>
							</Row>
						</div>
					</>
				}>
				<h3 css={badgeTitle}>Badges</h3>
				{accomplishment?.badges?.length ? (
					accomplishment?.badges?.map(el => (
						<div css={badgeWrap} key={el.id}>
							<Row key={el.id} horizontalGap={16} padding="16px 0" align="center">
								<Col basis="auto" grow={0} shrink={1} dFlex>
									<Image
										src={el?.pictureUrl ?? badgeDefault}
										width={70}
										height={70}
										size="contain"
										css={pictureContainer}
									/>
								</Col>
								<Col basis="auto" grow={1} shrink={0}>
									<Row md={6} verticalGap={8}>
										<Col>
											<h4>Name</h4>
										</Col>
										<Col>{el.name}</Col>
										<Col>
											<h4>Active</h4>
										</Col>
										<Col>
											<ColorBatch type={el.active ? 'success' : 'error'}>
												{el.active ? 'Active' : 'Inactive'}
											</ColorBatch>
										</Col>
										<Col>
											<h4>Visible</h4>
										</Col>
										<Col>
											<ColorBatch type={el.visible ? 'success' : 'error'}>
												{el.visible ? 'Visible' : 'Invisible'}
											</ColorBatch>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					))
				) : (
					<div css={noBadgesWrap}>
						<p>No Badges found!</p>
					</div>
				)}
			</Paper>
		</Col>
	);
};

AccountAchievements.propTypes = {
	accomplishment: PropTypes.object
};

export default AccountAchievements;
