import PropTypes from 'prop-types';
import { FormParagraph, Form, FormInput, CheckBoxForm, Button } from '../../../UI';
import VideoInput from '../../../Shared/Videos';
import { rowComp, colComp } from '../../../UI/Grid';
import { formBtnsWrap } from './styles';

const positiveIntPattern = (val) => /^[1-9][0-9]{0,}$/.test(val);

const LessonAddEditForm = (props) => {
  const { data, onSubmit, onCancel } = props;

  const SubmitBar = ({ onSubmit: onSubmitClick }) => (
    <div css={formBtnsWrap}>
      <Button secondary onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onSubmitClick}>Save</Button>
    </div>
  );

  return (
    <Form
      onSubmit={onSubmit}
      {...(data ? { renderSubmit: SubmitBar } : { submitButton: { children: 'Add Lesson' } })}
      values={data ?? {}}>
      <FormInput id="title" label="Title" required />
      <VideoInput id="sourceUrl" label="Video URL" required />
      <FormInput id="duration" label="Duration" required pattern={positiveIntPattern} />
      <FormParagraph css={rowComp({ margin: '0 -16px' })}>
        <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
          <CheckBoxForm id="visible" label="Visible" />
        </FormParagraph>
        <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
          <CheckBoxForm id="isFree" label="Is Free" />
        </FormParagraph>
      </FormParagraph>
    </Form>
  );
};

LessonAddEditForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default LessonAddEditForm;
