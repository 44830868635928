export const tableContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
  border: `1px solid ${theme.whiteGrayDark}`,
  padding: 16,
});

export const containerBody = {
  marginBottom: 15,
};

export const filterBarContainer = (theme) => ({
  position: 'relative',
  backgroundColor: theme.white,
  padding: 16,
  border: `1px solid ${theme.whiteGrayDark}`,
  marginBottom: 32,
});

export const tableHead = {
  display: 'flex',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
};

export const tableRow = (hasOnClick) => (theme) =>
  [
    {
      display: 'flex',
      alignItems: 'center',
      color: theme.grayDark,
      transition: 'background-color .3s ease-in-out',

      '&:hover': {
        backgroundColor: theme.highlights,

        '& i': {
          color: theme.textLightPrimary,
        },
      },

      '& i': {
        color: theme.textLightDisabled,
      },

      '&:nth-of-type(2n)': {
        backgroundColor: theme.whiteGrayLight,
        '&:hover': {
          backgroundColor: theme.highlights,

          '& i': {
            color: theme.textLightPrimary,
          },
        },
      },
    },
    hasOnClick && {
      cursor: 'pointer',
    },
  ];

export const tableCell = ({ width, flex, sm, md, lg, xl }) => [
  {
    fontSize: '1rem',
    lineHeight: 1.46,
    letterSpacing: '0.35px',
    flex: flex ?? (width ? `0 1 ${width}px` : '1 0 60px'),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 6px',
    outline: 'none',
    wordBreak: 'break-word',
  },
  sm && {
    '@media (max-width: 575px)': {
      display: 'none',
    },
  },
  md && {
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  lg && {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  xl && {
    '@media (max-width: 1199px)': {
      display: 'none',
    },
  },
];

export const tableHeadCell = (options) => (theme) =>
  [
    tableCell(options),
    {
      fontSize: 12,
      padding: 6,
      fontStyle: 'italic',
      color: theme.grayLighter,
      fontWeight: 500,
    },
    options?.sortable && {
      cursor: 'default',
      '& b': {
        cursor: 'pointer',
        '&:hover + i': {
          opacity: 1,
        },
      },
    },
  ];

export const tableHeadIcon = (isAsc, isDesc) => ({
  fontSize: 18,
  opacity: isAsc || isDesc ? 1 : 0,
  transform: `rotate(${isDesc ? 0 : 180}deg)`,
  transition: 'transform 300ms',
});

export const tableCellContent = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& span': {
    display: 'inline',
  },
};

export const tableCellEmpty = (theme) => ({
  width: 32,
  height: 4,
  backgroundColor: theme.whiteGray,
  borderRadius: 12,
});

export const tableCellCheckButton = {
  '& i': {
    margin: 0,
  },
};

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
};

export const shownResultsIndicator = (theme) => ({
  color: theme.textLightSecondary,
  width: 80,
  textAlign: 'center',
  fontSize: 11,
  marginRight: 8,
});

export const paginationButton = (disabled, isActive) => (theme) =>
  [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      width: 32,
      height: 32,
      margin: '0 2px',
      borderRadius: '50%',

      '&:hover': {
        backgroundColor: theme.whiteGray,
      },
    },
    isActive && {
      backgroundColor: theme.whiteGray,
    },
    disabled && {
      cursor: 'not-allowed',
      color: theme.textLightDisabled,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  ];

export const noResultsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '80px 20px',
  textAlign: 'center',
  flexDirection: 'column',
};

export const noResultsIcon = (theme) => ({
  width: 80,
  height: 80,
  backgroundColor: theme.primaryLight,
  marginBottom: 16,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const noResultsTitle = {
  fontWeight: 500,
  marginBottom: 12,
};

export const noResultsSubtitle = (theme) => ({
  color: theme.textLightSecondary,
});

export const pageSizeDropdown = {
  width: 80,
};

export const tableTitleHeadline = {
  marginBottom: 48,
  fontWeight: 600,
};
