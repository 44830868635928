import { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Icon, Paper, Button, Modal, Form, FormInput, showApiError, showError, showSuccess } from '../../../UI';
import {
  getMaterialByIdTranslation,
  postNewMaterialTranslation,
  updateMaterialTranslations,
} from '../../../../services/materials';
import {
  container,
  header,
  modalWrap,
  bodyWrap,
  uploadWrap,
  uploadBtnWrap,
  uploadInput,
  fileSelectedWrap,
  noFilesWrap,
} from './styles';

const MaterialsTranslationModal = forwardRef((props, ref) => {
  const { onSubmit } = props;
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const [language, setLanguage] = useState();
  const [materialData, setMaterialData] = useState({});

  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setLanguageData: (data) => setLanguage(data),
  }));

  useEffect(() => {
    language && fetchMaterialData();
  }, [hash({ language })]);

  const fetchMaterialData = async () => {
    const [res, err] = await getMaterialByIdTranslation(language?.materialId, language?.code);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    if (res?.sourceUrl && res?.name) setFile({ name: res?.name });

    setMaterialData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    if (!file) return showError('Please select file, before submit!');
    const [, error] = await (!isEmpty(materialData)
      ? updateMaterialTranslations(language?.materialId, language?.code, {
          ...materialData,
          ...formData,
          material: file instanceof File ? file : null,
        })
      : postNewMaterialTranslation(language?.materialId, {
          ...formData,
          material: file,
          languageCode: language?.code,
        }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(materialData) ? 'Edited' : 'Create'} material translation`,
      message: `Successfully ${!isEmpty(materialData) ? 'edited' : 'create'} material translation.`,
    });
    handleClose();
  };

  const handleClose = () => {
    setFile();
    setShow(false);
    onSubmit();
  };

  const handleChange = (event) => {
    const newFile = event.target.files[0];

    if (newFile > 10485760) return showError('File is too big, max size is 10mb!');

    setFile(newFile);
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>{`${!isEmpty(materialData) ? 'Edit' : 'Add'} material for translation ${language?.name} language`}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <div css={uploadWrap}>
            <h3>Upload material</h3>
            <div css={uploadBtnWrap}>
              <Button secondary onClick={() => inputRef.current.click()} disabled={false}>
                Choose File
              </Button>
            </div>
            <input
              ref={inputRef}
              css={uploadInput}
              type="file"
              accept="image/*, .doc, .docx, .pdf"
              multiple
              onChange={handleChange}
            />
            {file ? (
              <div css={fileSelectedWrap}>
                <p>
                  <b>File selected: </b>
                  {materialData?.sourceUrl && !(file instanceof File) ? (
                    <a href={materialData?.sourceUrl} target="blank">
                      {file.name}
                    </a>
                  ) : (
                    file.name
                  )}
                </p>
              </div>
            ) : (
              <div css={noFilesWrap}>
                <p>No File selected!</p>
              </div>
            )}
          </div>
          <Form
            onSubmit={handleSubmit}
            submitButton={{
              children: !isEmpty(materialData) ? 'Edit Material Translation' : 'Add Material Translation',
            }}
            values={materialData}>
            <FormInput id="name" label="Name" required />
          </Form>
        </div>
      </Paper>
    </Modal>
  );
});

MaterialsTranslationModal.propTypes = {
  onSubmit: PropTypes.func,
};

export default MaterialsTranslationModal;
