export const headerContain = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const buttonBack = (theme) => ({
  color: theme.gray,
  padding: 0,
  marginRight: 32,

  '& > svg': {
    marginRight: 12,
  },

  '& > span': {
    fontSize: '1rem',
    fontWeight: 600,

    '&:hover': {
      color: theme.primary,
    },
  },

  '&:focus, &:active': {
    color: theme.gray,
  },
});
