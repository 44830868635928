export const inputContainer = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
};

export const buttonsContainer = (theme) => ({
  color: theme.gray,
  fontWeight: 600,
  fontSize: 12,
  display: 'flex',
  alignItems: 'center',
});

export const addButton = (theme) => ({
  padding: '10px 0px',
  display: 'flex',
  alignItems: 'center',
  marginRight: 32,
  cursor: 'pointer',

  '&:hover > div': {
    backgroundColor: theme.primary,
  },

  '& .pictures-input': {
    display: 'none',
  },

  '&:last-child': {
    marginRight: 0,
  },
});

export const removeButton = (theme) => [
  addButton(theme),
  {
    '&:hover > div': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
];

export const iconAdd = (theme) => ({
  borderRadius: '50%',
  backgroundColor: theme.primaryLight,
  marginRight: 12,
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: theme.primary,
  },
});

export const iconRemove = (theme) => [
  iconAdd(theme),
  {
    backgroundColor: 'transparent',
    opacity: 0.3,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
];

export const pictureContainer = {
  marginRight: 18,
  flexBasis: 120,
  flexShrink: 0,
};
