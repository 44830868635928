import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Ripple from '../Ripple';
import Icon from '../Icon';
import { menuItem, menuItemText, menuItemContent } from './styles';

const Menu = (props) => {
  const { label, icon, url, iconType = { material: true } } = props;

  return (
    <NavLink to={url} css={menuItem}>
      <Ripple backColor="secondaryPressed" css={menuItemContent}>
        {icon && <Icon size={27} iconName={icon} {...iconType} />}
        <span css={menuItemText}>{label}</span>
      </Ripple>
    </NavLink>
  );
};

Menu.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconType: PropTypes.object,
};

export default Menu;
