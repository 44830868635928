import { Link } from 'react-router-dom';
import { Table } from '../../../../components/UI';
import TableSearchBarBadges from '../../../../components/Badges/TableSearchBarBadges';
import { getAllBadges } from '../../../../services/badges';
import { columns } from './config';
import { navLink, container } from '../styles';

const BadgesList = () => {
  const fetchAllBadges = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllBadges(options);
    return res;
  };

  return (
    <>
      <div css={container}>
        <h1>Badges List</h1>
        <Link to="/badges/badge/add-badge" css={navLink}>
          Create Badge
        </Link>
      </div>
      <Table
        getDataKey="data"
        getDataMethod={fetchAllBadges()}
        columns={columns}
        emptyMessage="There are no Badges"
        filterBar={TableSearchBarBadges}
        pageSize={10}
      />
    </>
  );
};

export default BadgesList;
