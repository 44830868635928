import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Button, Form, FormInput, CheckBoxForm, showApiError, showError, showSuccess } from '../../../UI';
import LanguageSelect from '../../../Shared/LanguageSelect';
import MaterialsTranslationModal from '../MaterialsTranslationModal';
import uuid from '../../../../utils/uuid';
import { getAllMaterials, updateMaterial } from '../../../../services/materials';
import {
  wrap,
  noFilesWrap,
  materialWrap,
  materialFormWrap,
  formBtnsWrap,
  fileSelectedWrap,
  uploadWrap,
  uploadBtnWrap,
  uploadInput,
  loadMoreButton,
} from './styles';

const MaterialsList = forwardRef((props, ref) => {
  const { courseId } = useParams();
  const [materials, setMaterials] = useState({});
  const [selected, setSelected] = useState(null);
  const [file, setFile] = useState();

  const pageIndex = useRef(0);
  const translationsModalRef = useRef(null);
  const languageSelectRef = useRef(null);
  const inputRef = useRef();

  useEffect(() => {
    fetchAllMaterials();
  }, []);

  useImperativeHandle(ref, () => ({
    refreshMaterialsList: () => {
      pageIndex.current = 0;
      fetchAllMaterials(0, true);
    },
  }));

  const fetchAllMaterials = async (pageIndexValue = 0, refresh) => {
    const [res, err] = await getAllMaterials({
      pageSize: 10,
      pageIndex: pageIndexValue,
      searchCriteria: 'courseId',
      searchInput: courseId,
    });

    if (err) return showApiError(err);

    setMaterials((prev) => ({
      data: prev?.data && !refresh ? [...(prev?.data ?? []), ...(res?.data ?? [])] : res?.data,
      total: res?.total,
    }));
    pageIndex.current += 1;
  };

  const handleSubmit = async (formData, materialData) => {
    const newMaterialData = {
      ...materialData,
      ...formData,
      ...(file instanceof File && { material: file }),
    };

    const [, error] = await updateMaterial(materialData?.id, {
      ...newMaterialData,
      courseId,
    });
    if (error) return showApiError(error);
    showSuccess({
      title: 'Edited material',
      message: 'Successfully edited material.',
    });

    const updatedMaterial = materials?.data?.map((el) =>
      el?.id === materialData?.id ? { ...el, ...newMaterialData } : el,
    );

    setMaterials((prev) => ({ data: updatedMaterial, total: prev?.total }));
    setSelected(null);
  };

  const SubmitBar = ({ onSubmit }) => (
    <div css={formBtnsWrap}>
      <Button secondary onClick={() => setSelected(null)}>
        Cancel
      </Button>
      <Button onClick={onSubmit}>Save</Button>
    </div>
  );

  const handleLanguageChange = (data) => {
    if (!data?.id) return;
    translationsModalRef.current.setIsShow();
    translationsModalRef.current.setLanguageData(data);
  };

  const handleChange = (event) => {
    const newFile = event.target.files[0];

    if (newFile > 10485760) return showError('File is too big, max size is 10mb!');

    setFile(newFile);
  };

  return (
    <>
      <div css={wrap}>
        <h3>Uploaded materials</h3>
        {materials?.data?.length ? (
          materials?.data?.map((el) => (
            <div key={el.id}>
              {selected?.id === el.id ? (
                <div css={materialFormWrap}>
                  <LanguageSelect
                    ref={languageSelectRef}
                    fullData
                    onChange={(data) =>
                      handleLanguageChange({
                        ...data,
                        materialId: el.id,
                        uuid: uuid(),
                      })
                    }
                  />
                  <div css={uploadWrap}>
                    <h3>Upload material</h3>
                    <div css={uploadBtnWrap}>
                      <Button secondary onClick={() => inputRef.current.click()} disabled={false}>
                        Choose File
                      </Button>
                    </div>
                    <input
                      ref={inputRef}
                      css={uploadInput}
                      type="file"
                      accept="image/*, .pdf, .doc, .docx, .ppt, .pptx"
                      multiple
                      onChange={handleChange}
                    />
                    {file || el?.sourceUrl ? (
                      <div css={fileSelectedWrap}>
                        <p>
                          <b>File selected: </b>
                          <a
                            href={el?.sourceUrl && !(file instanceof File) ? el?.sourceUrl : URL.createObjectURL(file)}
                            target="blank">
                            {file?.name ?? el?.name ?? ''}
                          </a>
                        </p>
                      </div>
                    ) : (
                      <div css={noFilesWrap}>
                        <p>No File selected!</p>
                      </div>
                    )}
                  </div>
                  <Form onSubmit={(data) => handleSubmit(data, el)} renderSubmit={SubmitBar} values={el}>
                    <FormInput id="name" label="Name" required />
                    <CheckBoxForm id="visible" label="Visible" />
                  </Form>
                </div>
              ) : (
                <div css={materialWrap}>
                  <Icon
                    material
                    color={el.visible ? 'info' : 'gray'}
                    iconName={el.visible ? 'visibility' : 'visibility_off'}
                  />
                  <p>{el.name}</p>
                  <Icon material color="primary" iconName="edit" onClick={() => setSelected(el)} />
                </div>
              )}
            </div>
          ))
        ) : (
          <div css={noFilesWrap}>
            <p>No Files uploaded!</p>
          </div>
        )}
        {materials?.data?.length < materials?.total && (
          <div css={loadMoreButton}>
            <Button info onClick={() => fetchAllMaterials(pageIndex.current)}>
              Load more
            </Button>
          </div>
        )}
      </div>
      <MaterialsTranslationModal ref={translationsModalRef} onSubmit={() => languageSelectRef.current.resetSelect()} />
    </>
  );
});

export default MaterialsList;
