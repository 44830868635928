export const optionsLanguagesSearchBy = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'code',
    label: 'Code',
  },
];

export const optionsStatus = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];

export const optionsVisibility = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
