export const wrap = {
  marginBottom: 32,
};

export const body = {
  '& > h3': {
    marginBottom: 16,
  },
};

export const uploadBtnWrap = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
  '& > p': {
    marginLeft: 8,
    fontWeight: 600,
  },
};

export const uploadInput = {
  display: 'none',
};

export const noFilesWrap = (theme) => ({
  backgroundColor: theme.grayLightest,
  marginBottom: 24,
  padding: 24,
  textAlign: 'center',
  '& > p': {
    fontWeight: 600,
  },
});

export const itemsWrap = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
  '& > p': {
    marginRight: 8,
  },
  '&  i': {
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
};
