import { Route } from 'react-router-dom';
import AddEditCategory from './Categories/AddEditCategory';
import AddEditCategoryTranslations from './Categories/AddEditCategoryTranslations';
import AddEditExperts from './Experts/AddEditExperts';
import AddEditExpertsTranslations from './Experts/AddEditExpertsTranslations';
import AddCourse from './Courses/AddCourse';
import OrderCourses from './Courses/OrderCourses';
import CourseEditDetails from './Courses/CourseEditDetails';
import OrderSteps from './Courses/OrderSteps';
import PackageDetails from './Packages/PackageDetails';
import UploadVideo from './Videos/UploadVideo';
import AddEditBadge from './Badges/Badges/AddEditBadge';
import AddEditBadgeTranslations from './Badges/Badges/AddEditBadgeTranslations';
import AddEditCondition from './Badges/Conditions/AddEditCondition';
import AcademyAccountDetails from './AcademyAccounts/AcademyAccountDetails';
import AddEditLanguage from './Languages/AddEditLanguage';

export const PrivateRoutes = ({ isAdmin }) =>
  [
    isAdmin && {
      path: '/categories/add-category',
      component: AddEditCategory,
    },
    isAdmin && {
      path: '/categories/:categoryId/:languageCode',
      component: AddEditCategoryTranslations,
    },
    isAdmin && {
      path: '/categories/:categoryId',
      component: AddEditCategory,
    },
    isAdmin && {
      path: '/experts/add-expert',
      component: AddEditExperts,
    },
    isAdmin && {
      path: '/experts/:expertId/:languageCode',
      component: AddEditExpertsTranslations,
    },
    isAdmin && {
      path: '/experts/:expertId',
      component: AddEditExperts,
    },
    isAdmin && {
      path: '/courses/add-course',
      component: AddCourse,
    },
    isAdmin && {
      path: '/courses/order-courses',
      component: OrderCourses,
    },
    isAdmin && {
      path: '/courses/:courseId/order-steps',
      component: OrderSteps,
    },
    isAdmin && {
      path: '/courses/:courseId/package',
      component: PackageDetails,
    },
    isAdmin && {
      path: '/courses/:courseId',
      component: CourseEditDetails,
    },
    isAdmin && {
      path: '/videos/upload',
      component: UploadVideo,
    },
    isAdmin && {
      path: '/badges/badge/add-badge',
      component: AddEditBadge,
    },
    isAdmin && {
      path: '/badges/badge/:badgeId/:languageCode',
      component: AddEditBadgeTranslations,
    },
    isAdmin && {
      path: '/badges/badge/:badgeId',
      component: AddEditBadge,
    },
    isAdmin && {
      path: '/badges/condition/add-condition',
      component: AddEditCondition,
    },
    isAdmin && {
      path: '/badges/condition/:conditionId',
      component: AddEditCondition,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId/achievements',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId/courses',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId/quiz-takes',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/academy-accounts/:accountId',
      component: AcademyAccountDetails,
    },
    isAdmin && {
      path: '/languages/add-language',
      component: AddEditLanguage,
    },
    isAdmin && {
      path: '/languages/:languageId',
      component: AddEditLanguage,
    },
    isAdmin && {
      path: '/packages/:packageId',
      component: PackageDetails,
    },
  ]
    .filter(Boolean)
    .map((route) => <Route key={route.path} {...route} />);
