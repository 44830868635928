import { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Paper } from '../../../components/UI';
import QuickStatsChart from '../../../components/Dashboard/QuickStatsChart';
import OrderStatisticsChart from '../../../components/Dashboard/OrderStatisticsChart';
import SalesBarChart from '../../../components/Dashboard/SalesBarChart';
import { getDateRangeTransactions } from '../../../components/Dashboard/dateRangeStatistics';
import DatePicker from '../../../components/Shared/DatePicker';
import { dataQuickStatsChart } from './config';

const defaultDates = {
  startDate: moment().subtract(7, 'days'),
  endDate: moment(),
};

const Dashboard = () => {
  const [transactions, setTransactions] = useState({ coin: {}, cash: {} });

  useEffect(() => {
    fetchDateRangeRelatedStatistics(defaultDates);
  }, []);

  const fetchDateRangeRelatedStatistics = async (dates) => {
    if (!dates.startDate || !dates.endDate) return;

    const [res] = await getDateRangeTransactions(dates);
    res && setTransactions(res);
  };

  return (
    <>
      <Row gap={10} justify="space-between">
        <Col xs={12} dFlex justify="flex-end">
          <DatePicker
            showClear={false}
            {...defaultDates}
            onChange={fetchDateRangeRelatedStatistics}
            minDate={moment().subtract(1, 'year')}
          />
        </Col>
        <Col xl={6} dFlex>
          <Row gap={10}>
            {dataQuickStatsChart(transactions).map((el) => (
              <Col key={el.id} lg={6} dFlex>
                <Paper>
                  <QuickStatsChart {...el} />
                </Paper>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xl={6}>
          <OrderStatisticsChart />
        </Col>
        <Col>
          <Row>
            <Col xl={6}>
              <Paper>
                <SalesBarChart title="Daily Sales" desc="Check out each column for more details" />
              </Paper>
            </Col>
            <Col xl={6}>
              <Paper>
                <SalesBarChart title="Revenue Change" desc="Revenue change breakdown by cities" />
              </Paper>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
