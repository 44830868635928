import { postReq, getReq, putReq } from './axios/makeRequest';
import {
  getAllParams,
  editQuizBody,
  editQuestionBody,
  editAnswersBody,
  editAnswersTranslationBody,
  addAnswersBulkBody,
  addQuestionsBulkBody,
} from './models/quizzes';

const baseUrl = `${apiUrls.oneAcademy}/quizzes`;
const baseUrlQuestions = `${apiUrls.oneAcademy}/quizQuestions`;
const baseUrlAnswers = `${apiUrls.oneAcademy}/quizAnswers`;

// Quizzes

export const postNewQuiz = (values) => postReq(`${baseUrl}/new-with-questions`, values);

export const postNewQuizTranslation = (id, data) => postReq(`${baseUrl}/${id}/translations/new`, data);

export const getQuizByIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrl}/${id}/translations/${languageCode}`);

export const getAllQuizzes = (options = {}) => {
  const params = getAllParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};

export const updateQuiz = (id, data) => putReq(`${baseUrl}/${id}/update`, editQuizBody(data));

export const updateQuizTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update`, data);

// Questions

export const postNewQuestions = (id, data) => postReq(`${baseUrlQuestions}/new-bulk`, addQuestionsBulkBody(id, data));

export const postNewQuestionTranslation = (id, data) => postReq(`${baseUrlQuestions}/${id}/translations/new`, data);

export const getQuestionByIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrlQuestions}/${id}/translations/${languageCode}`);

export const getAllQuestions = (options = {}) => {
  const params = getAllParams(options);
  return getReq(`${baseUrlQuestions}/list?${params}`);
};

export const updateQuestion = (id, data) => putReq(`${baseUrlQuestions}/${id}/update`, editQuestionBody(data));

export const updateQuestionTranslations = (id, languageCode, data) =>
  putReq(`${baseUrlQuestions}/${id}/translations/${languageCode}/update`, data);

// Answers

export const postNewAnswers = (id, data) => postReq(`${baseUrlAnswers}/new-bulk`, addAnswersBulkBody(id, data));

export const postNewAnswerTranslation = (id, data) => postReq(`${baseUrlAnswers}/${id}/translations/new`, data);

export const getAnswerByIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrlAnswers}/${id}/translations/${languageCode}`);

export const getAllAnswers = (options = {}) => {
  const params = getAllParams(options);
  return getReq(`${baseUrlAnswers}/list?${params}`);
};

export const updateAnswer = (id, data) => putReq(`${baseUrlAnswers}/${id}/update`, editAnswersBody(data));

export const updateAnswerTranslations = (id, languageCode, data) =>
  putReq(`${baseUrlAnswers}/${id}/translations/${languageCode}/update`, editAnswersTranslationBody(data));
