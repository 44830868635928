import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Dropdown, showApiError } from '../../UI';
import { getAllLanguages } from '../../../services/languages';
import { wrapper } from './styles';

const LanguageSelect = forwardRef((props, ref) => {
  const { fullData, onChange } = props;
  const [languages, setLanguages] = useState([]);
  const { languageCode } = useParams();

  const dropdownRef = useRef(null);

  useImperativeHandle(ref, () => ({
    resetSelect: () => dropdownRef.current.clear(),
  }));

  useEffect(() => {
    fetchAllLanguages();
  }, []);

  const fetchAllLanguages = async () => {
    const [res, err] = await getAllLanguages({
      active: true,
      sort: { sortField: 'name', sortOrder: 'asc' },
    });

    if (err) return showApiError(err);

    const newLanguages = res?.data?.filter((el) => !el?.isDefault) ?? [];

    setLanguages(newLanguages);
  };

  return (
    <div css={wrapper}>
      <Dropdown
        ref={dropdownRef}
        placeholder="Translations"
        options={languages}
        displayKey="name"
        uniqueKey="id"
        noClear
        onChange={(val) => onChange(fullData ? val : val.code)}
        value={languages.find((el) => el.code === languageCode)}
      />
    </div>
  );
});

LanguageSelect.propTypes = {
  fullData: PropTypes.bool,
  onChange: PropTypes.func,
};

export default LanguageSelect;
