import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import Input from '../Input';
import { useFormInput } from './useFormInput';
import errorIcon from '../../../assets/images/icn-alert-warning-input.svg';
import successIcon from '../../../assets/images/icn-sm-checkmark-tick.svg';
import { inputContainer, inputField, inputLabel, inputHelpContainer, inputHelpIcon } from './styles';

const FormInput = forwardRef((props, inputRef) => {
  const {
    id,
    type = 'text',
    required,
    label,
    horizontal,
    placeholder,
    successLabel = 'Field',
    dateTimeProps,
    className,
  } = props;

  // We had a custom hook which handles the logic behind the input
  // onChange, onBlur are methods that are implemented into the hook
  const { isTouched, error, ...inputAttr } = useFormInput({
    ...props,
    inputRef,
  });

  const isCorrect = isTouched && !error;
  const hesError = isTouched && error;

  return (
    <div css={inputContainer(horizontal, className)} {...(isString(className) && { className })}>
      {label && (
        <label htmlFor={id} css={inputLabel(horizontal)}>
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}

      <Input
        type={type}
        css={inputField(!!hesError, isCorrect)}
        placeholder={isString(placeholder) ? placeholder : `${label}...`}
        dateTimeProps={dateTimeProps}
        {...inputAttr}
      />
      {(hesError || isCorrect) && (
        <div css={inputHelpContainer(!!error)}>
          <div css={inputHelpIcon(!!error)}>
            <img src={error ? errorIcon : successIcon} alt="Logo" />
          </div>
          <span>{error ? error.msg : `${label || successLabel} is correct`}</span>
        </div>
      )}
    </div>
  );
});

FormInput.propTypes = {
  inputRef: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  pattern: PropTypes.func,
  horizontal: PropTypes.bool,
  successLabel: PropTypes.string,
  dateTimeProps: PropTypes.any,
  className: PropTypes.string,
};

export default FormInput;
