import { Children } from 'react';
import PropTypes from 'prop-types';
import MenuList from './MenuList';
import Content from './Content';
import { mainContainer } from './styles';

const SideNav = ({ children: initChildren = [], routes, notFoundComponent, homeRoute }) => {
  const children = Children.toArray(initChildren).filter(Boolean);

  return (
    <main css={mainContainer}>
      <MenuList sideNavChildrens={children} />
      <Content
        sideNavChildrens={children}
        routes={routes}
        notFoundComponent={notFoundComponent}
        homeRoute={homeRoute}
      />
    </main>
  );
};

SideNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  routes: PropTypes.array,
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string,
};

export default SideNav;
