import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, showApiError, showSuccess } from '../../UI';
import { QuizAdd, QuizzesList } from './Quiz';
import { postNewQuiz } from '../../../services/quizzes';
import { wrap, body } from './styles';

const Quizzes = () => {
  const { courseId } = useParams();
  const quizzesListRef = useRef();

  const handleSubmit = async (formData) => {
    const [, error] = await postNewQuiz({ ...formData, courseId });
    if (error) return showApiError(error);
    showSuccess({
      title: 'Add Quiz',
      message: 'Quiz added successfully.',
    });
    quizzesListRef.current.refreshQuizzesList();
    return 'success';
  };

  return (
    <Paper header="Course quizzes" css={wrap}>
      <QuizzesList ref={quizzesListRef} />
      <div css={body}>
        <h3>Add Quiz</h3>
        <QuizAdd onSubmit={handleSubmit} />
      </div>
    </Paper>
  );
};

export default Quizzes;
