import PropTypes from 'prop-types';
import { Route, Switch, Redirect, matchPath, useLocation } from 'react-router-dom';
import { isNil } from 'lodash-es';
import NavBar from '../NavBar';
import { MENU_SEPARATOR_NAME } from './MenuSeparator';
import { contentContainer, content } from './styles';

const Content = ({ sideNavChildrens, routes, notFoundComponent, homeRoute }) => {
  const location = useLocation();
  const RenderContent = sideNavChildrens
    .filter((el) => el.type.displayName !== MENU_SEPARATOR_NAME)
    .map((menu, i) => {
      return (
        <Route
          key={`sideNavMenu-${i}`}
          exact={menu.props.exact}
          path={menu.props.url}
          component={menu.props.component}
        />
      );
    });

  const currentRoute = sideNavChildrens.find((el) => matchPath(location.pathname, el.props.url))?.props;

  return (
    <section css={contentContainer}>
      <NavBar label={currentRoute?.label ?? 'OneLife'} logoLink={homeRoute} />
      <article css={content(currentRoute?.withPadding)}>
        <Switch>
          {homeRoute && <Redirect exact from="/" to={homeRoute} />}
          {!isNil(routes) && routes}
          {RenderContent}
          {notFoundComponent && <Route component={notFoundComponent} />}
        </Switch>
      </article>
    </section>
  );
};

Content.propTypes = {
  sideNavChildrens: PropTypes.array,
  routes: PropTypes.array,
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string,
};

export default Content;
