export const wrapper = {
  padding: '48px 40px',
};

export const backContainer = {
  marginBottom: 32,
};

export const paperContain = {
  marginBottom: 50,
  maxWidth: 850,
};

export const coursesWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 6,
  backgroundColor: theme.whiteGrayLight,
  border: `1px solid ${theme.borderLight}`,
  borderRadius: 4,
  padding: 6,
  paddingLeft: 12,
});

export const rightCol = {
  display: 'flex',
  alignItems: 'center',
};

export const inputWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  maxWidth: 124,
  '& > input': {
    paddingRight: 40,
  },
  '& .percent': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: 14,
    paddingRight: 12,
    paddingLeft: 12,
    borderLeft: `1px solid ${theme.borderLight}`,
  },
});

export const saveBtnWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 16,
};

export const totalWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingTop: 16,
  '& > .inner': {
    textAlign: 'right',
  },
};

export const itemLink = (theme) => ({
  '& p': {
    color: theme.highlightsBlue,
    fontWeight: 600,
    marginRight: 16,
    fontSize: 14,
  },
  '&  i': {
    fontSize: 12,
  },
});
