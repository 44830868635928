import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash-es';
import SearchInput from '../../Shared/SearchInput';
import { Row, Col, Dropdown } from '../../UI';

const noResultsMessages = {
  name: 'Conditions',
  queryAlias: 'QueryAlias',
};

const TableSearchBarConditions = ({ getData, tableOptions, setEmptyMessage, queryAliasesConfig }) => {
  const params = useRef({
    name: null,
    queryAlias: null,
  });

  useEffect(() => {
    getData();
  }, []);

  const fetchData = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };
    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  return (
    <Row horizontalGap={16}>
      <Col xl={8} lg={8} sm={6} xs={12}>
        <SearchInput placeholder="Search by name..." onChange={(name) => fetchData({ name })} />
      </Col>
      <Col xl={4} lg={4} sm={6} xs={12}>
        <Dropdown
          withSearch
          placeholder="QueryAlias"
          options={queryAliasesConfig}
          onChange={(val) => fetchData({ queryAlias: val?.value })}
        />
      </Col>
    </Row>
  );
};

TableSearchBarConditions.propTypes = {
  getData: PropTypes.object,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
  queryAliasesConfig: PropTypes.any,
};

export default TableSearchBarConditions;
