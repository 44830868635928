import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { showError, Button, Form, FormInput, FormParagraph, CheckBoxForm } from '../../../UI';
import { rowComp, colComp } from '../../../UI/Grid';
import { wrap, formBtnsWrap, uploadBtnWrap, uploadInput, fileSelectedWrap, noFilesWrap } from './styles';

const LessonsTextAddEditForm = (props) => {
  const { data, onSubmit, onCancel } = props;
  const [file, setFile] = useState(data?.title ? { name: data.title, noFile: true } : null);
  const ref = useRef();

  const handleChange = (event) => {
    const newFile = event.target.files[0];

    if (newFile?.size > 10485760) return showError(`File "${newFile?.name}" is too big, max size is 10mb!`);

    setFile(newFile);
  };

  const handleSubmit = async (formData) => {
    const hasError = await onSubmit({
      ...formData,
      ...(file instanceof File && { lessonMaterial: file }),
    });
    if (hasError) return hasError;
    setFile();
  };

  const SubmitBar = ({ onSubmit: onSubmitClick }) =>
    data ? (
      <div css={formBtnsWrap}>
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onSubmitClick}>Save</Button>
      </div>
    ) : (
      <Button onClick={onSubmitClick} large disabled={!file}>
        Add Lesson
      </Button>
    );

  return (
    <div css={wrap}>
      <div css={uploadBtnWrap}>
        <Button secondary onClick={() => ref.current.click()} disabled={false}>
          Choose File
        </Button>
      </div>
      <input ref={ref} css={uploadInput} type="file" accept=".pdf" multiple onChange={handleChange} />
      {file ? (
        <div css={fileSelectedWrap}>
          <p>
            <b>File selected: </b>
            <a
              href={data?.sourceUrl && !(file instanceof File) ? data?.sourceUrl : URL.createObjectURL(file)}
              target="blank">
              {file.name}
            </a>
          </p>
        </div>
      ) : (
        <div css={noFilesWrap}>
          <p>No File selected!</p>
        </div>
      )}

      <Form onSubmit={handleSubmit} renderSubmit={SubmitBar} values={data ?? {}}>
        <FormInput id="title" label="Title" required />
        <FormParagraph css={rowComp({ margin: '0 -16px' })}>
          <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
            <CheckBoxForm id="visible" label="Visible" />
          </FormParagraph>
          <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
            <CheckBoxForm id="isFree" label="Is Free" />
          </FormParagraph>
        </FormParagraph>
      </Form>
    </div>
  );
};

LessonsTextAddEditForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default LessonsTextAddEditForm;
