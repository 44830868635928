import { useEffect } from 'react';
import { softLogout } from '@oneecosystem/authenticate';

const Logout = () => {
  useEffect(() => {
    softLogout();
  }, []);

  return null;
};

export default Logout;
