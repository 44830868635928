import { isString, isFunction } from 'lodash-es';
import uuid from '../../../utils/uuid';
import { parseError } from '../../../utils/parseRequestError';

export const Types = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

const prepare = (notif) => (isString(notif) ? { message: notif, id: uuid() } : { ...notif, id: uuid() });

const store = {
  show: () => {},
  hide: () => {},
};

export const showSuccess = (notif) => store.show({ ...prepare(notif), type: Types.SUCCESS });

export const showError = (notif) => store.show({ ...prepare(notif), type: Types.ERROR });

export const showApiError = (error) =>
  parseError(error).map((notif) => store.show({ ...prepare(notif), type: Types.ERROR }));

export const showIdentityError = (error) =>
  parseError(error).map((notif) => store.show({ ...prepare(notif), type: Types.ERROR, duration: 10000 }));

export const showInfo = (notif) => store.show({ ...prepare(notif), type: Types.INFO });

export const showWarning = (notif) => store.show({ ...prepare(notif), type: Types.WARNING });

export const hide = (notif) => store.hide(notif?.id);

export const notificationsHandler = ({ addNotification, removeNotification }) => {
  if (isFunction(addNotification)) store.show = addNotification;
  if (isFunction(removeNotification)) store.hide = removeNotification;
};
