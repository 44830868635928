import { headerContainer } from '../../../components/UI';

export const wrapper = {
  padding: '48px 40px',
};

export const container = (theme) => [
  headerContainer(theme),
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
];

export const backContainer = {
  marginBottom: 32,
};

export const paperContain = {
  marginBottom: 50,
  maxWidth: 850,
};

export const navLink = (theme) => ({
  borderRadius: 2,
  padding: '10px 20px',
  color: theme.white,
  border: `1px solid ${theme.primaryLight}`,
  textAlign: 'center',
  backgroundColor: theme.primaryLight,
  fontSize: 14,

  '&:hover': {
    backgroundColor: theme.primary,
  },

  '&:focus, &:active': {
    backgroundColor: theme.primaryLight,
    color: theme.textPrimaryFocused,
  },
});
