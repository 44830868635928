export const container = {
  marginTop: 100,
};

export const header = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px 36px',
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const modalWrap = {
  'section[role=presentation]': {
    maxWidth: 'calc(100% - 56px)',
  },
};

export const bodyWrap = {
  maxHeight: '90vh',
  overflow: 'scroll',
};
