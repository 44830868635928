import { DetailsLink } from '../../../components/Shared/TableComponents';

export const columns = [
  {
    name: 'Account id',
    value: 'id',
    width: 100,
  },
  {
    name: 'External Account Id',
    value: 'externalAccountId',
    width: 100,
  },
  {
    name: 'Username',
    value: 'userName',
  },
  {
    name: 'View details',
    render: row => <DetailsLink row={row} url={`/academy-accounts/${row.id}/achievements`} />,
    width: 100,
  },
];

export const pages = [
  { url: '/academy-accounts', label: 'Academy accounts' },
  {
    label: 'Academy account information',
  },
];
