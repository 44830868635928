import { useState, useEffect, useImperativeHandle } from 'react';
import { isFunction } from 'lodash-es';
import validateInput from '../../../utils/inputValidation';

const validateIsRequired = (value) => validateInput(value, 'required');

export const useWysiwygInput = ({
  id,
  value: initValue,
  onChange: changeValueInForm,
  onError: changeErrorInForm,
  validate,
  disabled,
  readOnly,
  required,
  readOnlyEditable,
  isTouched: isTouchedInit,
  inputRef,
}) => {
  const strVal = initValue?.toString ? initValue.toString() : initValue ?? '';
  const [value, setValue] = useState(strVal);
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit);

  useEffect(() => {
    setValue(strVal);
  }, [strVal]);

  useEffect(() => {
    checkForError(value);
  }, [isTouched]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    if (disabled || readOnly) return;
    // Form component holds all values and errors into state so we need to pass the new value and error to the form component
    checkForError(value);
    isFunction(changeValueInForm) && changeValueInForm(id, value);
  }, [value]);

  useImperativeHandle(inputRef, () => ({
    changeValue: (newValue) => onChange({ target: { value: newValue } }),
    checkForError,
    setError: handleError,
    value,
    changeIsTouched: setIsTouched,
  }));

  // Handle change of the input,
  // All form inputs are controlled and have onChange
  const onChange = (_, editor) => {
    const newValue = editor.getData();
    setValue(newValue);
  };

  // On blur is used only to change the flag for touched
  const onBlur = () => {
    !isTouched && setIsTouched(true);
    checkForError(value);
  };

  const checkForError = (newValue) => {
    let newError = null;

    // If validate function is provided check for error
    if (isFunction(validate)) newError = validate(newValue, id);
    else if (required) newError = validateIsRequired(newValue);

    handleError(newError);
  };

  const handleError = (newError) => {
    // The first time when you edit the field it will not generate error for the field
    // but it will pass the error to the form so you can not submit it until there is error
    isTouched && setError(newError);
    // The form component holds all errors into ref so we need to pass the new error into the form component
    isFunction(changeErrorInForm) && changeErrorInForm(id, newError);
  };

  return {
    // Input props
    value,
    onChange,
    onBlur,
    disabled: readOnly || readOnlyEditable || disabled,
    // Form related props
    error,
    isTouched,
  };
};
