import { getReq, postReq, putReq } from './axios/makeRequest';
import { getAllConditionsParams, editConditionBody } from './models/conditions';

const baseUrl = `${apiUrls.oneAcademy}/badge-conditions`;

export const postNewCondition = (values) => postReq(`${baseUrl}/new`, values);

export const getConditionById = async (id) => getReq(`${baseUrl}/${id}`);

export const getConditionConfig = async () => getReq(`${baseUrl}/configurations`);

export const updateCondition = (id, data) => putReq(`${baseUrl}/${id}/update`, editConditionBody(data));

export const getAllConditions = (options = {}) => {
  const params = getAllConditionsParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
