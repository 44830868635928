import { pick, isNil } from 'lodash-es';
import queryString from 'query-string';

export const editConditionBody = (body) => ({
  name: body?.name,
  assertionValue: body?.assertionValue,
  assertionPeriod: body?.assertionPeriod,
});

export const getAllConditionsParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.name?.length) {
    filterFields.push('name');
    filterValues.push(options.name);
  }

  if (!isNil(options.queryAlias)) {
    filterFields.push('queryAlias');
    filterValues.push(options.queryAlias);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
