import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Breadcrumbs, showSuccess, showApiError } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import CourseAddEditForm from '../../../components/Courses/Course/CourseAddEditForm';
import { postNewCourse, updateCoursePackages } from '../../../services/courses';
import { getAllPackages } from '../../../services/packages';
import { pages } from './config';
import { wrapper, backContainer, paperContain } from './styles';

const AddCourse = () => {
  const history = useHistory();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    fetchAllPackages();
  }, []);

  const fetchAllPackages = async () => {
    const [res, err] = await getAllPackages();

    if (err) return showApiError(err);

    setPackages(res?.catalogueItems ?? []);
  };

  const handleSubmit = async (formData) => {
    const picEncodedData = formData?.picUrl?.picture ?? '';
    const thumbPicEncodedData = formData?.picUrl?.picThumbnail ?? '';

    const { catalogueItems, ...courseData } = formData;

    const [res, error] = await postNewCourse({
      ...courseData,
      picEncodedData,
      thumbPicEncodedData,
    });

    if (error) return showApiError(error);

    if (res?.id) {
      const [, error2] = await updateCoursePackages(res?.id, catalogueItems);
      if (error2) return showApiError(error2);
    }
    showSuccess({
      title: 'Create course',
      message: 'Successfully create course.',
    });

    history.push(`/courses/${res?.id}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages()} />
      <BackButton css={backContainer} title="Add Course" />
      <Paper css={paperContain}>
        <CourseAddEditForm onSubmit={handleSubmit} packages={packages} />
      </Paper>
    </div>
  );
};

export default AddCourse;
