import { Link } from 'react-router-dom';
import { Paper } from '../../../components/UI';
import { wrapper, container, navLink } from './styles';
import VideosList from '../../../components/Videos/VideosList';

const Videos = () => {
  return (
    <div css={wrapper}>
      <Paper
        header={
          <div css={container}>
            <h2>Videos List</h2>
            <Link to="/videos/upload" css={navLink}>
              Upload Video
            </Link>
          </div>
        }>
        <VideosList />
      </Paper>
    </div>
  );
};

export default Videos;
