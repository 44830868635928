import { pick, isNumber } from 'lodash-es';
import queryString from 'query-string';

export const editQuizBody = (body) => ({
  title: body?.title,
  visible: body?.visible ?? false,
});

export const editQuestionBody = (body) => ({
  title: body?.title,
  visible: body?.visible ?? false,
  answersType: body?.answersType,
  ...((body?.afterQuestionId || isNumber(body?.afterQuestionId)) && {
    afterQuestionId: body?.afterQuestionId,
  }),
});

export const editAnswersBody = (body) => ({
  text: body?.text,
  // correctText: body?.correctText,
  visible: body?.visible ?? false,
  rankingPoints: body?.rankingPoints,
  isCorrect: body?.isCorrect ?? false,
  ...((body?.afterAnswerId || isNumber(body?.afterAnswerId)) && {
    afterAnswerId: body?.afterAnswerId,
  }),
});

export const editAnswersTranslationBody = (body) => ({
  text: body?.text,
  // correctText: body?.correctText,
});

export const addAnswersBulkBody = (questionId, answers) => ({
  questionId,
  answers,
});

export const addQuestionsBulkBody = (quizId, questions) => ({
  quizId,
  questions,
});

export const getAllParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
