import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, showApiError } from '../../../../components/UI';
import TableSearchBarConditions from '../../../../components/Badges/TableSearchBarConditions';
import { getAllConditions, getConditionConfig } from '../../../../services/conditions';
import { conditionsColumns } from './config';
import { navLink, container } from '../styles';

const ConditionsList = () => {
  const [config, setConfig] = useState();

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchAllConditions = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllConditions(options);
    return res;
  };

  const fetchConfig = async () => {
    const [res, err] = await getConditionConfig();
    if (err) return showApiError(err);
    setConfig(res);
  };

  return (
    <>
      <div css={container}>
        <h1>Conditions List</h1>
        <Link to="/badges/condition/add-condition" css={navLink}>
          Create Condition
        </Link>
      </div>
      {config && (
        <Table
          getDataKey="data"
          getDataMethod={fetchAllConditions()}
          columns={conditionsColumns(config)}
          emptyMessage="There are no Conditions"
          filterBar={(options) =>
            TableSearchBarConditions({
              ...options,
              queryAliasesConfig: config?.queryAliases,
            })
          }
          pageSize={10}
        />
      )}
    </>
  );
};

export default ConditionsList;
