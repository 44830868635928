export const optionsAccountSearchBy = [
  {
    value: 'username',
    label: 'Username',
  },
  {
    value: 'externalAccountId',
    label: 'External Account ID',
  },
];

export const optionsCourseProgress = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'finished',
    label: 'Finished',
  },
];
