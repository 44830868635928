export const searchContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
};

export const inputSearch = (theme) => ({
  fontSize: '13px',
  fontWeight: '600',
  color: theme.gray,
  padding: '10px 36px',

  '::-webkit-input-placeholder': {
    color: theme.grayLighter,
  },
});

export const iconSearch = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 8,
};

export const iconClear = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 12,
};
