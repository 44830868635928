import queryString from 'query-string';

export const getAllVideosParams = options => {
  const query = options?.query;
  const page = options?.page;
  const folderId = options?.folderId;

  return queryString.stringify(
    {
      ...(query && { query }),
      ...(page && { page }),
      ...(folderId && { folderId }),
    },
    { arrayFormat: 'index' }
  );
};
