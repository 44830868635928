export const columns = [
  {
    name: 'Quiz ID',
    value: 'quizId',
    width: 100,
  },
  {
    name: 'Quiz Name',
    value: 'quizName',
  },
  {
    name: 'Course',
    value: 'courseName',
  },
  {
    name: 'Quiz Take',
    value: 'quizTake',
    width: 100,
  },
  {
    name: 'Score Percentage',
    value: 'scorePercentage',
    width: 150,
  },
  {
    name: 'Taken on',
    value: 'createdOn',
    width: 150,
  },
];
