import { mainContainer, content } from './styles';

const NotFound = () => (
  <div css={mainContainer}>
    <div css={content}>
      <h1>404</h1>
      <p>OOPS! Something went wrong here</p>
    </div>
  </div>
);

export default NotFound;
