import PropTypes from 'prop-types';
import { Icon, Paper, Modal } from '../../UI';
import VideosList from '../VideosList';
import { selectVideoContainer, selectVideoHeader, modalWrap, bodyWrap } from './styles';

const VideosSelectModal = (props) => {
  const { show, onClose, onSelect } = props;

  return (
    <Modal css={modalWrap} show={!!show} onClose={onClose} withoutClose>
      <Paper
        header={
          <header css={selectVideoHeader}>
            <h3>Select Videos</h3>
            <Icon onClick={onClose} material iconName="close" />
          </header>
        }
        css={selectVideoContainer}>
        <div css={bodyWrap}>
          <VideosList onSelect={onSelect} />
        </div>
      </Paper>
    </Modal>
  );
};

VideosSelectModal.propTypes = {
  show: PropTypes.any,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

export default VideosSelectModal;
