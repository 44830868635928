import PropTypes from 'prop-types';
import { menuSeparator } from './styles';

export const MENU_SEPARATOR_NAME = 'MenuSeparator';

const MenuSeparator = ({ children, isOpen }) => {
  return <h2 css={menuSeparator}>{isOpen ? children : `${children}`.charAt(0)}</h2>;
};

MenuSeparator.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
};

MenuSeparator.displayName = MENU_SEPARATOR_NAME;

export default MenuSeparator;
