import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Icon, Paper, Modal, Form, FormInput, showApiError, showSuccess } from '../../../UI';
import {
  postNewCourseTranslation,
  getCourseByIdTranslation,
  updateCourseTranslations,
} from '../../../../services/courses';
import { container, header, modalWrap, bodyWrap, inputDescriptionContainer } from './styles';

const CourseTranslationModal = forwardRef((props, ref) => {
  const { onSubmit } = props;
  const { courseId } = useParams();
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState();
  const [courseData, setCourseData] = useState({});

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setLanguageData: (data) => setLanguage(data),
  }));

  useEffect(() => {
    language && fetchCourseData();
  }, [hash({ language })]);

  const fetchCourseData = async () => {
    const [res, err] = await getCourseByIdTranslation(courseId, language?.code);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setCourseData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(courseData)
      ? updateCourseTranslations(courseId, language?.code, {
          ...courseData,
          ...formData,
        })
      : postNewCourseTranslation(courseId, {
          ...formData,
          languageCode: language?.code,
        }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(courseData) ? 'Edited' : 'Create'} course translation`,
      message: `Successfully ${!isEmpty(courseData) ? 'edited' : 'create'} course translation.`,
    });
    handleClose();
  };

  const handleClose = () => {
    setShow(!show);
    onSubmit();
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>{`${!isEmpty(courseData) ? 'Edit' : 'Add'} course translation for ${language?.name} language`}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <Form
            onSubmit={handleSubmit}
            submitButton={{
              children: !isEmpty(courseData) ? 'Edit Course Translation' : 'Add Course Translation',
            }}
            values={courseData}>
            <FormInput id="name" label="Name" required />
            <FormInput id="label" label="Label" />
            <FormInput
              id="description"
              type="textarea"
              label="Description"
              placeholder="Description"
              css={inputDescriptionContainer}
            />
          </Form>
        </div>
      </Paper>
    </Modal>
  );
});

CourseTranslationModal.propTypes = {
  onSubmit: PropTypes.func,
};

export default CourseTranslationModal;
