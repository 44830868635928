import { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Icon, Paper, Button, Modal, Form, FormInput, showApiError, showError, showSuccess } from '../../../UI';
import {
  postNewLessonTextTranslation,
  getLessonByIdTranslation,
  updateLessonTextTranslations,
} from '../../../../services/lessons';
import {
  container,
  header,
  modalWrap,
  bodyWrap,
  uploadBtnWrap,
  uploadInput,
  fileSelectedWrap,
  noFilesWrap,
} from './styles';

const LessonsTextTranslationModal = forwardRef((props, ref) => {
  const { lessonId, onSubmit } = props;
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState();
  const [lessonData, setLessonData] = useState({});
  const [file, setFile] = useState();

  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setLanguageData: (data) => setLanguage(data),
  }));

  useEffect(() => {
    language && fetchLessonData();
  }, [hash({ language })]);

  const fetchLessonData = async () => {
    const [res, err] = await getLessonByIdTranslation(lessonId, language?.code);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setLessonData(res ?? {});
    setFile(res?.title ? { name: res.title, noFile: true } : null);
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(lessonData)
      ? updateLessonTextTranslations(lessonId, language?.code, {
          ...lessonData,
          ...formData,
          ...(file instanceof File && { lessonTranslationMaterial: file }),
        })
      : postNewLessonTextTranslation(lessonId, {
          ...formData,
          languageCode: language?.code,
          ...(file instanceof File && { lessonTranslationMaterial: file }),
        }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(lessonData) ? 'Edited' : 'Create'} lesson translation`,
      message: `Successfully ${!isEmpty(lessonData) ? 'edited' : 'create'} lesson translation.`,
    });
    handleClose();
  };

  const handleClose = () => {
    setShow(!show);
    onSubmit();
  };

  const handleChange = (event) => {
    const newFile = event.target.files[0];

    if (newFile?.size > 10485760) return showError(`File "${newFile?.name}" is too big, max size is 10mb!`);

    setFile(newFile);
  };

  const SubmitBar = ({ onSubmit: onSubmitClick }) => (
    <Button onClick={onSubmitClick} large disabled={!file}>
      {!isEmpty(lessonData) ? 'Edit Lesson Translation' : 'Add Lesson Translation'}
    </Button>
  );

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>{`${!isEmpty(lessonData) ? 'Edit' : 'Add'} lesson translation for ${language?.name} language`}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <div css={uploadBtnWrap}>
            <Button secondary onClick={() => inputRef.current.click()} disabled={false}>
              Choose File
            </Button>
          </div>
          <input ref={inputRef} css={uploadInput} type="file" accept=".pdf" multiple onChange={handleChange} />
          {file ? (
            <div css={fileSelectedWrap}>
              <p>
                <b>File selected: </b>
                {lessonData?.sourceUrl && !(file instanceof File) ? (
                  <a href={lessonData?.sourceUrl} target="blank">
                    {file.name}
                  </a>
                ) : (
                  file.name
                )}
              </p>
            </div>
          ) : (
            <div css={noFilesWrap}>
              <p>No File selected!</p>
            </div>
          )}
          <Form onSubmit={handleSubmit} renderSubmit={SubmitBar} values={lessonData}>
            <FormInput id="title" label="Title" required />
          </Form>
        </div>
      </Paper>
    </Modal>
  );
});

LessonsTextTranslationModal.propTypes = {
  onSubmit: PropTypes.func,
  lessonId: PropTypes.number,
};

export default LessonsTextTranslationModal;
