export const tabs = {
  width: '100%',
};

export const tabsListContainerWrapper = (showArrows) => [
  {
    position: 'relative',
    overflow: 'hidden',
  },
  showArrows && { paddingRight: 90 },
];

export const tabsListContainer = (tabListStyle) => (theme) => ({
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  marginBottom: -50,
  paddingBottom: 50,
  display: 'flex',
  width: '100%',
  backgroundColor: theme.white,
  paddingLeft: 25,
  ...tabListStyle,

  '& > *': {
    display: 'inline-block',
  },
});

const activeTab = (theme) => ({
  fontWeight: 600,
  color: theme.textLightPrimary,
  borderBottom: `2px solid ${theme.primary}`,
});

export const tabContainer = (active) => (theme) =>
  [
    {
      cursor: 'pointer',
      padding: '24px 24px',
      fontSize: 14,
      fontWeight: 400,
      color: theme.textLightSecondary,
      backgroundColor: theme.white,
    },
    active && activeTab(theme),
  ];

export const tabLinkContainer = (theme) => ({
  '&.active > *': activeTab(theme),
});

const arrowStyle = (isDisabled) => (theme) => ({
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 26,
  color: isDisabled ? theme.textLightDisabled : theme.textLightPrimary,
  borderRadius: '50%',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
});

export const scrollLeftIcon = (isDisabled) => (theme) => ({
  position: 'absolute',
  top: 0,
  right: 44,
  ...arrowStyle(theme, isDisabled),
});

export const scrollRightIcon = (isDisabled) => (theme) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  ...arrowStyle(theme, isDisabled),
});
