import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { orderBy } from 'lodash-es';
import { Paper, Breadcrumbs, showSuccess, showApiError } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { pagesOrderSteps, courseStepTypes } from './config';
import { getCourseById, updateCourseSteps } from '../../../services/courses';
import { wrapper, backContainer, courseOrderWrap } from './styles';

const OrderSteps = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState();

  useEffect(() => {
    fetchCourseData();
  }, []);

  const fetchCourseData = async () => {
    const [res, err] = await getCourseById(courseId);

    err
      ? showApiError(err)
      : setCourse({
          ...res,
          steps: orderBy(res?.steps, ['stepOrder'], ['asc']),
        });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const coursePrev = { ...course };

    setCourse((prev) => ({
      ...prev,
      steps: reorder([...prev.steps], result.source.index, result.destination.index),
    }));

    const [, error] = await updateCourseSteps(result.draggableId, {
      title: course.steps[result.source.index].title,
      visible: course.steps[result.source.index].visible,
      afterStepId: result.destination.index !== 0 ? course.steps[result.destination.index - 1].id : 0,
    });

    if (error) {
      setCourse(coursePrev);
      return showApiError(error);
    }

    showSuccess({
      title: 'Reorder course',
      message: 'Successfully reorder courses.',
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pagesOrderSteps(courseId)} />
      <BackButton css={backContainer} title="Order Course Steps" />
      <Paper header={`Order steps for: ${course?.name}`}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {course?.steps?.map((el, index) => (
                  <Draggable key={String(el.id)} draggableId={String(el.id)} index={index}>
                    {(draggableProvided) => (
                      <div
                        key={el.id}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        css={courseOrderWrap}>
                        <div>
                          <h4>{el.title}</h4>
                        </div>
                        <div>
                          <ColorBatch type={courseStepTypes[el.type]?.color}>
                            {courseStepTypes[el.type]?.text}
                          </ColorBatch>
                          &nbsp;
                          <ColorBatch type={el.visible ? 'success' : 'error'}>
                            {el.visible ? 'Visible' : 'Invisible'}
                          </ColorBatch>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
    </div>
  );
};

export default OrderSteps;
