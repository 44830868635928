import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Icon, Paper, Modal, Form, FormInput, showApiError, showSuccess } from '../../../../UI';
import {
  postNewAnswerTranslation,
  getAnswerByIdTranslation,
  updateAnswerTranslations,
} from '../../../../../services/quizzes';
import { container, header, modalWrap, bodyWrap } from './styles';

const AnswersTranslationModal = forwardRef((props, ref) => {
  const { answerId, onSubmit } = props;
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState();
  const [answerData, setAnswerData] = useState({});

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setLanguageData: (data) => setLanguage(data),
  }));

  useEffect(() => {
    language && fetchAnswerData();
  }, [hash({ language })]);

  const fetchAnswerData = async () => {
    const [res, err] = await getAnswerByIdTranslation(answerId, language?.code);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setAnswerData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(answerData)
      ? updateAnswerTranslations(answerId, language?.code, {
          ...answerData,
          ...formData,
        })
      : postNewAnswerTranslation(answerId, {
          ...formData,
          languageCode: language?.code,
        }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(answerData) ? 'Edited' : 'Create'} answer translation`,
      message: `Successfully ${!isEmpty(answerData) ? 'edited' : 'create'} answer translation.`,
    });
    handleClose();
  };

  const handleClose = () => {
    setShow(!show);
    onSubmit();
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>{`${!isEmpty(answerData) ? 'Edit' : 'Add'} answer for translation ${language?.name} language`}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <Form
            onSubmit={handleSubmit}
            submitButton={{
              children: !isEmpty(answerData) ? 'Edit Answer Translation' : 'Add Answer Translation',
            }}
            values={answerData}>
            <FormInput id="text" label="Text" required />
          </Form>
        </div>
      </Paper>
    </Modal>
  );
});

AnswersTranslationModal.propTypes = {
  onSubmit: PropTypes.func,
  answerId: PropTypes.number,
};

export default AnswersTranslationModal;
