import { useEffect, useState } from 'react';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import { isString, isEmpty } from 'lodash-es';
import { Paper, Breadcrumbs, Icon, Input, Button, showSuccess, showApiError } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import { pages, pagesFromCourse } from './config';
import { getCourseByCatalogueItemId, updateCatalogueItemCourses } from '../../../services/catalogue-items';
import {
  wrapper,
  backContainer,
  paperContain,
  coursesWrap,
  inputWrap,
  saveBtnWrap,
  totalWrap,
  itemLink,
  rightCol,
} from './styles';

const PackageDetails = () => {
  const history = useHistory();
  const { courseId } = useParams();
  const { state } = useLocation();
  const [courses, setCourses] = useState(null);
  const sum = courses?.reduce(
    (accumulator, object) =>
      parseFloat(accumulator) +
      parseFloat(
        isString(object?.tokensPercentage) && isEmpty(object?.tokensPercentage) ? 0 : object?.tokensPercentage,
      ),
    0,
  );

  useEffect(() => {
    if (state?.packageData) fetchCatalogueItemCourses();
    else history?.push(`/courses/${courseId}`);
  }, []);

  const fetchCatalogueItemCourses = async () => {
    const [res, err] = await getCourseByCatalogueItemId(state?.packageData?.catalogueItemId);
    err ? showApiError(err) : setCourses(res?.courses ?? []);
  };

  const handleInputChange = (value, id) => {
    const doubleRegEx = /(^$|^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/.test(value);
    const dotsLength = ((value.length && value.match(/\./g)) || []).length;
    const valueValidation = doubleRegEx || value === '100' || (value[value.length - 1] === '.' && dotsLength < 2);

    valueValidation &&
      setCourses((prev) =>
        prev?.map((el) =>
          el?.courseId !== id
            ? el
            : {
                ...el,
                tokensPercentage: value,
              },
        ),
      );
  };

  const handleSave = async () => {
    const [, error] = await updateCatalogueItemCourses(state?.packageData?.catalogueItemId, courses);
    if (error) return showApiError(error);
    showSuccess({
      title: 'Save package',
      message: 'Successfully save package distribution.',
    });
    fetchCatalogueItemCourses();
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={courseId ? pagesFromCourse(courseId) : pages} />
      <BackButton css={backContainer} title={state?.packageData?.name ?? ''} linkTo={`/courses/${courseId}`} />
      {state?.packageData && (
        <Paper css={paperContain} header="Distribute Tokens">
          {courses?.map((el) => (
            <div key={el?.courseId} css={coursesWrap}>
              <Link to={`/courses/${el?.courseId}`} css={itemLink}>
                <p>
                  {`#${el?.courseId} ${el.courseName}`} <Icon iconName="fa fa-arrow-circle-right" />
                </p>
              </Link>
              <div css={rightCol}>
                <div css={inputWrap}>
                  <Input
                    value={String(el?.tokensPercentage ?? 0)}
                    onChange={({ target: { value } }) => handleInputChange(value, el?.courseId)}
                    onBlur={({ target: { value } }) =>
                      handleInputChange(
                        value[value?.length - 1] === '.'
                          ? parseFloat(value.slice(0, -1))
                          : value?.length
                          ? parseFloat(value)
                          : 0,
                        el?.courseId,
                      )
                    }
                  />
                  <div className="percent">
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div css={totalWrap}>
            <div className="inner">
              <h2>Total: {sum?.toFixed(2)}%</h2>
              <small>Max: 100.00%</small>
            </div>
          </div>
          <div css={saveBtnWrap}>
            <Button large secondary linkTo={`/courses/${courseId}`}>
              Back
            </Button>
            <Button large onClick={handleSave} disabled={sum > 100}>
              Save
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default PackageDetails;
