import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction, isString } from 'lodash-es';
import Icon from '../Icon';
import { Spinner } from '../Loader';
import {
  baseButton,
  secondaryButton,
  successButton,
  warningButton,
  outlineButton,
  outlineInverseButton,
  clearButton,
  smallButton,
  largeButton,
  infoButton,
} from './styles';

const Button = (props) => {
  const {
    children,
    onClick,
    disabled,
    leftIcon,
    secondary,
    success,
    warning,
    info,
    outline,
    outlineInverse,
    clear,
    small,
    large,
    className,
    preventDefault,
    linkTo,
  } = props;

  const [loading, setLoading] = useState(false);

  const classes = [
    baseButton(props, loading),
    (secondary || clear) && secondaryButton(props),
    success && successButton(props),
    warning && warningButton(props),
    info && infoButton(props),
    outline && outlineButton(props),
    outlineInverse && outlineInverseButton(props),
    clear && clearButton(props),
    small && smallButton,
    large && largeButton,
  ];

  const handleClick = async (e) => {
    if (disabled) return e.preventDefault();
    preventDefault && e.preventDefault();
    setLoading(true);

    await onClick();
    await new Promise(res => setTimeout(res, 100));

    setLoading(false);
  };

  return linkTo ? (
    <Link to={linkTo} css={classes} {...(isString(className) && { className })}>
      {children}
    </Link>
  ) : (
    <div
      {...(isFunction(onClick) ? { tabIndex: '0', role: 'button', onClick: handleClick } : {})}
      css={classes}
      {...(isString(className) && { className })}>
      {leftIcon && <Icon {...leftIcon} />}
      {children}
      <Spinner loading={loading} size={30} />
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  info: PropTypes.bool,
  outline: PropTypes.bool,
  outlineInverse: PropTypes.bool,
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  color: PropTypes.string,
  backColor: PropTypes.string,
  clear: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
  leftIcon: PropTypes.object,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Button;
