import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNil, omit } from 'lodash-es';
import { Skeleton } from '../../UI';
import { detailsContainer, titleStyles } from './styles';

const DetailsLink = (props) => {
  const { row, url, title } = props;

  return isNil(row) ? (
    <Skeleton width={90} height={20} />
  ) : (
    <Link
      to={{
        pathname: url,
        state: omit(row, '_table'),
      }}
      css={detailsContainer}>
      <span css={titleStyles}>{title ?? 'View details'}</span>
    </Link>
  );
};

DetailsLink.propTypes = {
  row: PropTypes.object,
  url: PropTypes.string,
  title: PropTypes.string,
};

export default DetailsLink;
