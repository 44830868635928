import { useState, useRef, useEffect } from 'react';
import { isEqual, isEmpty, isNil, isFunction } from 'lodash-es';
import hash from 'object-hash';
import { outputValue, removeNodes, prepareValue } from './utils';

export const useDropDown = ({
  value: initValue,
  options,
  onChange,
  onBlur,
  open,
  alwaysFocused,
  multiSelect,
  disabled,
  mapValue,
  displayKey = 'label',
  uniqueKey = 'value',
  placeholder = '',
  noSimplify,
  renderSelected,
}) => {
  const selectedValue = prepareValue({
    value: initValue,
    options,
    multiSelect,
    uniqueKey,
  });
  const [selected, setSelected] = useState(selectedValue);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef({
    parentElement: null,
    offsetWidth: 0,
  });
  const isDisabled = !options?.length || disabled;

  useEffect(() => {
    open && setIsOpen(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      alwaysFocused && isOpen && selectRef.current?.parentElement?.focus();
    });
  });

  useEffect(() => {
    setSelected(selectedValue);
  }, [hash({ selectedValue: removeNodes(selectedValue, displayKey, uniqueKey) })]);

  const openClose = () => !isDisabled && setIsOpen((prev) => !prev);

  const areEquals = (source, value) => {
    // Check if the values are defined because isEqual with 2 undefined returns true
    const areDef = !isNil(source[uniqueKey]) && !isNil(value[uniqueKey]);
    const areSame = isEqual(source[uniqueKey], value[uniqueKey]);
    return areDef && areSame;
  };

  const handleBlur = ({ currentTarget }) =>
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement) && isOpen) {
        setIsOpen(false);
        onBlur && onBlur();
      }
    });

  const handleSelect = (newValue) => {
    if (multiSelect) {
      if (isNil(newValue)) return changeValue([]);
      const selectedUnique = selected?.filter((el) => !isNil(el));
      const valueIndex = selectedUnique.findIndex((e) => areEquals(e, newValue));

      if (valueIndex === -1) return changeValue([...selectedUnique, newValue]);

      selectedUnique.splice(valueIndex, 1);
      return changeValue(selectedUnique);
    }
    setIsOpen(false);
    return changeValue(newValue);
  };

  const handleClear = (e) => {
    e?.stopPropagation();
    handleSelect(null);
  };

  const changeValue = (newValue) => {
    if (isEqual(newValue, selected)) return;
    setSelected(newValue);
    setTimeout(() => onChange(outputValue(newValue, displayKey, mapValue, !noSimplify)));

    // Focus the dropdown so it can trigger onBlur function which will check for error
    // This is for the case where the user open the dropdown and do not select nothing (if the field is required is should show error)
    isOpen && selectRef.current?.parentElement?.focus();
  };

  const getOptionValue = (val) => val && val[displayKey];

  const hasSelection = multiSelect ? selected?.length : !isEmpty(selected);

  const displayValue = isFunction(renderSelected)
    ? renderSelected(selected)
    : multiSelect
    ? selected?.map(getOptionValue).join(', ')
    : getOptionValue(selected);

  const calculateDisplayValue = () => (hasSelection ? displayValue : placeholder);

  return {
    openClose,
    handleBlur,
    handleSelect,
    handleClear,
    isOpen,
    selected,
    displayValue,
    selectRef,
    calculateDisplayValue,
  };
};
