import { pick } from 'lodash-es';
import queryString from 'query-string';

export const getAllAcademyAccountsParams = (options) => {
  const filterFields = [];
  const filterValues = [];

  if (options.courseProgress) {
    filterFields.push('courseProgress');
    filterValues.push(options.courseProgress);
  }

  if (options.courseId) {
    filterFields.push('courseId');
    filterValues.push(options.courseId);
  }

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize', 'withAccomplishment']),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};

export const getAcademyAccountCoursesParams = (options) => {
  const filterFields = [];
  const filterValues = [];

  if (options.courseId) {
    filterFields.push('courseId');
    filterValues.push(options.courseId);
  }

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};

export const getAcademyAccountQuizTakesParams = (options) => {
  const filterFields = [];
  const filterValues = [];

  if (options.courseId) {
    filterFields.push('courseId');
    filterValues.push(options.courseId);
  }

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize', 'onlyLastTake']),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
