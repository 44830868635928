import { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import PropTypes from 'prop-types';
import { quickStatsConfig } from '../config';
import { container, content, number, description, chart } from './styles';

const QuickStatsChart = (props) => {
  const canvasRef = useRef();
  const { value, desc, data, color, border, id } = props;

  useEffect(() => {
    const createdChart = new Chart(canvasRef.current, quickStatsConfig(props));

    return () => {
      createdChart.destroy();
    };
  }, [data, color, border]);

  return (
    <div css={container}>
      <div css={content}>
        <span css={number}>{value}</span>
        <span css={description}>{desc}</span>
      </div>

      <div css={chart}>
        <canvas ref={canvasRef} id={id} />
      </div>
    </div>
  );
};

QuickStatsChart.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  desc: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  border: PropTypes.number,
};

export default QuickStatsChart;
