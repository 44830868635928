import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { showError, Icon, Button, Paper, showApiError, showSuccess } from '../../UI';
import MaterialsList from './MaterialsList';
import { wrap, body, uploadBtnWrap, uploadInput, itemsWrap, noFilesWrap } from './styles';
import { uploadCourseMaterials } from '../../../services/materials';

const Materials = () => {
  const { courseId } = useParams();
  const [files, setFiles] = useState([]);
  const ref = useRef();
  const materialLidtRef = useRef();

  const handleChange = (event) => {
    const mergedFiles = [...files, ...event.target.files];

    if (mergedFiles.length > 5) return showError('Max 5 files are allowed!');

    const filteredFiles = mergedFiles.filter((el) => {
      if (el.size > 10485760) {
        showError(`File "${el.name}" is too big, max size is 10mb!`);
        return false;
      }
      return true;
    });

    setFiles(filteredFiles);
  };

  const handleFileRemove = (lastModified, index) => {
    const filteredItems = files.filter((el, i) => {
      if (el.lastModified === lastModified && i === index) return false;
      return true;
    });

    setFiles(filteredItems);
  };

  const handleUpload = async () => {
    const [, error] = await uploadCourseMaterials(courseId, files);
    if (error) return showApiError(error);
    showSuccess({
      title: 'Upload materials',
      message: 'Successfully upload material.',
    });
    setFiles([]);
    materialLidtRef.current.refreshMaterialsList();
  };

  return (
    <Paper header="Course Material" css={wrap}>
      <MaterialsList ref={materialLidtRef} />
      <div css={body}>
        <h3>Upload materials</h3>
        <div css={uploadBtnWrap}>
          <Button secondary onClick={() => ref.current.click()} disabled={false}>
            Choose Files
          </Button>
          <p>{`${files.length} of 5 files`}</p>
        </div>
        <input
          ref={ref}
          css={uploadInput}
          type="file"
          accept="image/*, .doc, .docx, .pdf"
          multiple
          onChange={handleChange}
        />
        {files.length ? (
          files.map((el, i) => (
            <div key={`${el.lastModified}-${i}`} css={itemsWrap}>
              <p>{el.name}</p>
              <Icon material color="primary" iconName="close" onClick={() => handleFileRemove(el.lastModified, i)} />
            </div>
          ))
        ) : (
          <div css={noFilesWrap}>
            <p>No Files selected!</p>
          </div>
        )}

        <Button onClick={handleUpload} disabled={!files.length} large>
          Upload
        </Button>
      </div>
    </Paper>
  );
};

export default Materials;
