export const inputField = (theme) => ({
  border: `1px solid ${theme.whiteGray}`,
  borderRadius: '3px',
  padding: 12,
  fontSize: '14px',
  lineHeight: 1.71,
  width: '100%',
  maxWidth: '100%',
  '&:focus, &:active': {
    outline: 'none',
  },
});
