import { isArray } from 'lodash-es';

const MAX_IMAGE_SIZE_BASE64 = 3 * 1024 * 1024;

export const optimizeImage = (base64, maxWidth, maxHeight) => {
  const maxAspect = maxWidth / maxHeight;

  return new Promise((resolve) => {
    const originalImage = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    originalImage.onload = () => {
      const { width, height } = originalImage;

      const aspectRatio = width / height;
      let thumbnailScale = aspectRatio > maxAspect ? maxWidth / width : maxHeight / height;
      thumbnailScale = thumbnailScale > 1 ? 1 : thumbnailScale;
      const thumbnailWidth = width * thumbnailScale;
      const thumbnailHeight = height * thumbnailScale;

      canvas.width = thumbnailWidth;
      canvas.height = thumbnailHeight;

      ctx.drawImage(originalImage, 0, 0, thumbnailWidth, thumbnailHeight);

      resolve(canvas.toDataURL('image/png', 70));
    };

    originalImage.src = base64;
  });
};

export const generateImageAndThumb = (file, thumbMaxWidth, thumbMaxHeight) =>
  new Promise((resolve) => {
    if (file.size > MAX_IMAGE_SIZE_BASE64) resolve({ error: 'Image max size allowed 3MB' });
    else {
      const reader = new FileReader();

      reader.onloadend = async ({ target }) => {
        const thumb = await optimizeImage(target.result, thumbMaxWidth, thumbMaxHeight);

        resolve({
          lastModified: file.lastModified,
          picture: target.result,
          picThumbnail: thumb,
        });
      };

      reader.readAsDataURL(file);
    }
  });

export const processImages = async (files, thumbMaxWidth, thumbMaxHeight) => {
  const isList = isArray(files);
  const images = isList ? files : Array.from(files);

  const results = await Promise.all(images.map((file) => generateImageAndThumb(file, thumbMaxWidth, thumbMaxHeight)));

  return isList ? results : results[0];
};
