import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { Row, Col, Paper, Button, Icon, Tooltip, showError, showApiError, showSuccess } from '../../../../UI';
import AnswersAddForm from '../AnswersAddForm';
import { answersListWrap, answersListItem, uploadQuizButton } from './styles';
import { updateQuestion, postNewAnswers } from '../../../../../services/quizzes';

const AnswersAddMore = (props) => {
  const { answersData, questionData, onAnswersDataUpdate, onQuestionDataUpdate, onModalClose } = props;
  const [answers, setAnswers] = useState([{}]);
  const [selectedAnswer, setSelectedAnswer] = useState(0);

  useEffect(() => {
    setSelectedAnswer(answers.length - 1);
  }, [answers]);

  const handleAddAnswer = () => {
    setAnswers((prev) => [...prev, {}]);
  };

  const handleAnswerSubmit = (formData) => {
    const data = answers.map((el, index) => (index === selectedAnswer ? { ...el, ...formData } : el));
    setAnswers(data);
  };

  const handleAnswerRemove = () => {
    const data = answers.filter((el, index) => index !== selectedAnswer);
    setAnswers(data);
  };

  const handleAnswersUpload = async () => {
    const data = answers.filter((el) => !isEmpty(el));

    if (!data.length) return showError('Please add some answers data!');

    const mergedAnswers = [...answersData, ...data];

    const correctAnswers = mergedAnswers.filter((el) => el.isCorrect && el.visible);

    if (questionData.answersType === 'single' && correctAnswers.length > 1) {
      const updateQuestionError = await updateQuestionType('multi');
      if (updateQuestionError) return showApiError(updateQuestionError);

      const updateAnswersError = await addAnswers(data);
      if (updateAnswersError) return showApiError(updateAnswersError);
    } else {
      const updateAnswersError = await addAnswers(data);
      if (updateAnswersError) return showApiError(updateAnswersError);
    }

    showSuccess({
      title: 'Add answers',
      message: 'Successfully added answers.',
    });

    onModalClose();
  };

  const addAnswers = async (data) => {
    const [, error] = await postNewAnswers(questionData.id, data);
    if (error) return error;
    onAnswersDataUpdate();
  };

  const updateQuestionType = async (type) => {
    const data = {
      ...questionData,
      answersType: type,
    };

    const [, error] = await updateQuestion(questionData.id, data);
    if (error) return error;

    onQuestionDataUpdate(data);
  };

  return (
    <Paper header={`Add answers to ${questionData?.title}`}>
      <>
        <Row horizontalGap={5}>
          <Col flex="0 1 auto" dFlex>
            <div css={answersListWrap}>
              {answers?.map((answer, answerIndex) => (
                <div
                  key={answerIndex}
                  css={answersListItem(answerIndex === selectedAnswer)}
                  role="presentation"
                  onClick={() => setSelectedAnswer(answerIndex)}>
                  <Tooltip content={answer?.visible ? 'visible' : 'not-visible'}>
                    <Icon
                      material
                      color={answer?.visible ? 'info' : 'gray'}
                      iconName={answer?.visible ? 'visibility' : 'visibility_off'}
                    />
                  </Tooltip>
                  <Tooltip content={answer?.isCorrect ? 'correct' : 'not-correct'}>
                    <Icon
                      material
                      color={answer?.isCorrect ? 'green' : 'red'}
                      iconName={answer?.isCorrect ? 'task_alt' : 'highlight_off'}
                    />
                  </Tooltip>
                  <p>{answer.text}</p>
                </div>
              ))}
              <Button
                onClick={handleAddAnswer}
                disabled={answers && answers.length > 0 && isEmpty(answers[answers?.length - 1])}>
                + Add Answer
              </Button>
            </div>
          </Col>
          <Col flex="1 0 0px " dFlex>
            {answers && !isEmpty(answers) && (
              <AnswersAddForm
                onRemove={handleAnswerRemove}
                data={answers[selectedAnswer] ?? {}}
                onSubmit={(formData) => handleAnswerSubmit(formData)}
              />
            )}
          </Col>
        </Row>
      </>
      <div css={uploadQuizButton}>
        <Button secondary onClick={handleAnswersUpload} large>
          Save
        </Button>
      </div>
    </Paper>
  );
};

AnswersAddMore.propTypes = {
  answersData: PropTypes.array,
  questionData: PropTypes.object,
  onQuestionDataUpdate: PropTypes.func,
  onAnswersDataUpdate: PropTypes.func,
  onModalClose: PropTypes.func,
};

export default AnswersAddMore;
