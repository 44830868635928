import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Icon, Paper, Modal, Form, FormInput, showApiError, showSuccess } from '../../../UI';
import VideoInput from '../../../Shared/Videos';
import {
  postNewLessonTranslation,
  getLessonByIdTranslation,
  updateLessonTranslations,
} from '../../../../services/lessons';
import { container, header, modalWrap, bodyWrap } from './styles';

const positiveIntPattern = (val) => /^[1-9][0-9]{0,}$/.test(val);

const LessonsTranslationModal = forwardRef((props, ref) => {
  const { lessonId, onSubmit } = props;
  const [show, setshow] = useState(false);
  const [language, setLanguage] = useState();
  const [lessonData, setLessonData] = useState({});

  useImperativeHandle(ref, () => ({
    setIsShow: () => setshow(!show),
    setLanguageData: (data) => setLanguage(data),
  }));

  useEffect(() => {
    language && fetchLessonData();
  }, [hash({ language })]);

  const fetchLessonData = async () => {
    const [res, err] = await getLessonByIdTranslation(lessonId, language?.code);

    if (err) showApiError(err);

    setLessonData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(lessonData)
      ? updateLessonTranslations(lessonId, language?.code, {
          ...lessonData,
          ...formData,
        })
      : postNewLessonTranslation(lessonId, {
          ...formData,
          languageCode: language?.code,
        }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(lessonData) ? 'Edited' : 'Create'} lesson translation`,
      message: `Successfully ${!isEmpty(lessonData) ? 'edited' : 'create'} lesson translation.`,
    });
    handleClose();
  };

  const handleClose = () => {
    setshow(!show);
    onSubmit();
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>{`${!isEmpty(lessonData) ? 'Edit' : 'Add'} lesson translation for ${language?.name} language`}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <Form
            onSubmit={handleSubmit}
            submitButton={{
              children: !isEmpty(lessonData) ? 'Edit Lesson Translation' : 'Add Lesson Translation',
            }}
            values={lessonData}>
            <FormInput id="title" label="Title" required />
            <VideoInput id="sourceUrl" label="Video URL" required />
            <FormInput id="duration" label="Duration" required pattern={positiveIntPattern} />
          </Form>
        </div>
      </Paper>
    </Modal>
  );
});

LessonsTranslationModal.propTypes = {
  onSubmit: PropTypes.func,
  lessonId: PropTypes.number,
};

export default LessonsTranslationModal;
