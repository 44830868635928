import { DetailsLink } from '../../../components/Shared/TableComponents';
import { formatShortText } from '../../../utils';

export const columns = [
  {
    name: 'Expert id',
    value: 'id',
    width: 90,
    sortable: true,
  },
  {
    name: 'First Name',
    value: 'firstName',
    width: 200,
  },
  {
    name: 'Last Name',
    value: 'lastName',
    width: 200,
  },
  {
    name: 'Position',
    value: 'position',
    width: 200,
  },
  {
    name: 'Description',
    value: 'description',
    render: (row) => (row?.description ? formatShortText(row.description, 100) : null),
  },
  {
    name: 'View details',
    render: (row) => <DetailsLink row={row} url={`/experts/${row.id}`} />,
    width: 100,
  },
];

export const pages = (expertId) => [
  { url: '/experts', label: 'Experts' },
  {
    label: expertId ? 'Edit Expert' : 'Add Expert',
  },
];

export const pagesTranslation = (expertId, hasTranslation) => [
  { url: '/experts', label: 'Experts' },
  { url: `/experts/${expertId}`, label: 'Expert Information' },
  {
    label: hasTranslation ? 'Edit Expert Translation' : 'Add Expert Translation',
  },
];
