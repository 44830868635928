export const wrap = {
  '& > h3': {
    marginBottom: 16,
  },
};

export const formBtnsWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 24,
  borderTop: `1px solid ${theme.border}`,
});

export const uploadBtnWrap = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
  '& > p': {
    marginLeft: 8,
    fontWeight: 600,
  },
};

export const uploadInput = {
  display: 'none',
};

export const noFilesWrap = (theme) => ({
  backgroundColor: theme.grayLightest,
  marginBottom: 24,
  padding: 24,
  textAlign: 'center',
  '& > p': {
    fontWeight: 600,
  },
});

export const fileSelectedWrap = {
  marginBottom: 16,
  '& > p > a': {
    textDecoration: 'underline',
  },
};
