import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import Icon from '../Icon';
import { Timer } from '../../../utils';
import { useConstant } from '../hooks';
import { ReactComponent as infoIcon } from '../../../assets/images/icn-alert-notice-2.svg';
import { ReactComponent as successIcon } from '../../../assets/images/icn-alert-success.svg';
import { ReactComponent as warningIcon } from '../../../assets/images/icn-alert-warning.svg';
import { ReactComponent as errorIcon } from '../../../assets/images/icn-alert-error.svg';
import { notification, notifContainer } from './styles';

const getIcon = {
  success: successIcon,
  error: errorIcon,
  warning: warningIcon,
  info: infoIcon,
};

const Notification = (props) => {
  const {
    id,
    onRemoval,
    title,
    message,
    content,
    duration = 7000,
    toggleRemoval,
    onClick,
    type,
    iconName,
    alwaysShow = false,
    pauseOnHover = true,
  } = props;
  const [, setTimer, timer] = useConstant();
  const [callback, setCallback] = useConstant(onRemoval);
  const [hide, setHide] = useState(false);

  const onTransitionStart = () => {
    if (!duration || alwaysShow) return;
    const notifTimer = new Timer(() => setHide(true), duration);
    setTimer(notifTimer);
    notifTimer.start();
  };

  useEffect(() => {
    pauseOnHover && onTransitionStart();

    return () => {
      pauseOnHover && timer.current?.clear();
    };
  }, []);

  const onTransitionEnd = () => {
    toggleRemoval(id);
    isFunction(callback) && callback(props);
  };

  const onMouseEnter = () => timer.current?.pause();

  const onMouseLeave = () => timer.current?.start();

  const handleClick = () => {
    setCallback(isFunction(onClick) ? onClick : onRemoval);
    setHide(true);
  };

  const NotificationIcon = getIcon[type];

  return (
    <div
      role="presentation"
      css={notification(type, hide)}
      onMouseEnter={pauseOnHover ? onMouseEnter : null}
      onMouseLeave={pauseOnHover ? onMouseLeave : null}
      onClick={handleClick}
      onTransitionEnd={hide ? onTransitionEnd : onTransitionStart}>
      {content ?? (
        <div css={notifContainer(type)}>
          {iconName ? <Icon material iconName={iconName} /> : <NotificationIcon />}
          <span>
            {title && <h4>{title}</h4>}
            {message && <span>{message}</span>}
          </span>
        </div>
      )}
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  onRemoval: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.node,
  duration: PropTypes.number,
  type: PropTypes.string,
  toggleRemoval: PropTypes.func,
  onClick: PropTypes.func,
  alwaysShow: PropTypes.bool,
  iconName: PropTypes.string,
  pauseOnHover: PropTypes.bool,
};

export default Notification;
