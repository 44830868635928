import { useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { isBoolean, isArray, pick, isEmpty } from 'lodash-es';
import qs from 'query-string';
import { Route, NavLink, Redirect, useHistory, Switch } from 'react-router-dom';
import { tabs, tabsListContainer, tabsListContainerWrapper, tabLinkContainer } from './styles';

const Tabs = ({
  children: initChildren,
  startingRoute,
  routeMatch,
  tabListStyle = {},
  sharedQueryParams,
  tabsContentClass,
}) => {
  const history = useHistory();
  const { location } = history;
  const children = Children.toArray(initChildren).filter(Boolean);
  const hasRoutes = children.every((el) => el.props?.url);

  const [activeTab, setActiveTab] = useState(!hasRoutes && children.length && children[0].props.label);

  const parsedQueryParams = qs.parse(location.search);
  const tabQueryParams = isBoolean(sharedQueryParams)
    ? parsedQueryParams
    : isArray(sharedQueryParams)
    ? pick(parsedQueryParams, sharedQueryParams)
    : {};
  const stringifiedQueryParams = isEmpty(tabQueryParams) ? '' : `?${qs.stringify(tabQueryParams)}`;
  const defaultUrl = `${startingRoute}${children[0]?.props?.url}${stringifiedQueryParams}`;

  const renderRouteChilds = children.map((child) => {
    const { label, url, component } = child.props;
    return <Route exact key={label} path={`${routeMatch ?? startingRoute}${url}`} render={component} />;
  });

  if (!children.length) return null;

  const ActiveComponent = children.find((el) => el.props.label === activeTab)?.props.component;

  return (
    <div css={tabs}>
      <div css={tabsListContainerWrapper()}>
        <div css={tabsListContainer(tabListStyle)}>
          {children.map((child) => {
            const { label, url, state } = child.props;

            return hasRoutes ? (
              <NavLink
                key={label}
                css={tabLinkContainer}
                to={{
                  pathname: `${startingRoute}${url}${stringifiedQueryParams}`,
                  state,
                }}>
                {child}
              </NavLink>
            ) : (
              cloneElement(child, {
                key: label,
                active: activeTab === label,
                onClick: setActiveTab,
              })
            );
          })}
        </div>
      </div>
      <div css={tabsContentClass}>
        {hasRoutes ? (
          <Switch>
            {renderRouteChilds}
            <Redirect from={startingRoute} to={defaultUrl} />
          </Switch>
        ) : (
          <ActiveComponent />
        )}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  startingRoute: PropTypes.string,
  routeMatch: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tabsContentClass: PropTypes.any,
  tabListStyle: PropTypes.any,
  sharedQueryParams: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default Tabs;
