import { getReq, putReq } from './axios/makeRequest';
import { getTransactionsModel, getAllTransactionParams } from './models/transactions';

const baseUrl = `${apiUrls.oneLife}/wallets`;

export const getAllTransactions = async (options) => {
  const params = getAllTransactionParams(options);

  const [res, err] = await getReq(`${baseUrl}/transactions/listAll?${params}`);

  if (res?.transactions) res.transactions = res.transactions.map((el) => getTransactionsModel(el));
  return [res, err];
};

export const updateStatus = (id, values) => putReq(`${baseUrl}/transactions/${id}/update`, values);

export const getAllReasons = () => getReq(`${baseUrl}/transactions/reasons`);

export const getTransactionById = (id) => getReq(`${baseUrl}/transactions/${id}`);
