const itemHeight = 51;

export const dropDownContainer = (disabled) => ({
  position: 'relative',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.3 : 1,
  outline: 'none',
  fontSize: '14px',
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const dropDownSelect = (isOpened, isSelected, small) => (theme) =>
  [
    {
      border: `1px solid ${isOpened ? theme.primary : theme.whiteGray}`,
      borderRadius: isOpened ? '4px 4px 0 0' : '4px',
      boxShadow: isOpened ? '0 1px 4px 0 rgba(162, 163, 166, 0.4)' : 'none',
      backgroundColor: theme.whiteGrayLight,
      padding: 12,
      color: isSelected ? theme.gray : theme.grayLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    small && {
      padding: '6px 12px',
    },
  ];

export const dropDownText = (isSelected) => ({
  userSelect: isSelected ? 'text' : 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const dropDownOptionsContainer = (isOpened, numberOfItems, maxNumber, onTop, onLeft) => (theme) =>
  [
    {
      position: 'absolute',
      zIndex: 40,
      color: theme.textLightSecondary,
      minWidth: '100%',
      height: 'auto',
      maxHeight: numberOfItems > maxNumber ? maxNumber * itemHeight - itemHeight / 2 : 'none',
      overflowY: 'auto',
      backgroundColor: theme.white,
      transform: `scaleY(${isOpened ? 1 : 0})`,
      transformOrigin: onTop ? 'bottom' : 'top',
      transition: 'transform 400ms ease-in-out',
      cursor: 'default',
    },
    onTop
      ? {
          bottom: '100%',
          boxShadow: '0 -2px 4px rgba(0,0,0,0.3)',
          borderBottom: isOpened ? '1px solid #DDDDDD' : 'none',
          borderRadius: '4px 4px 0 0',
        }
      : {
          top: '100%',
          boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
          borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
          borderRadius: '0 0 4px 4px',
        },
    onLeft && { right: 0 },
  ];

export const dropDownOptionItem = (isSelected, hasRender, small) => (theme) =>
  [
    {
      backgroundColor: isSelected ? theme.error : theme.white,
      color: isSelected ? theme.white : '',
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: isSelected ? theme.error : theme.whiteGrayLight,
      },
      '&:focus': {
        outline: 'none',
      },
      '& i': {
        marginRight: 8,
      },
    },
    !hasRender && {
      padding: '16px 32px',
    },
    small && {
      padding: '8px 16px',
    },
  ];

export const dropDownGroupLabel = (theme) => ({
  margin: 0,
  padding: 12,
  cursor: 'default',
  color: theme.textLightSecondary,
  fontSize: 14,
  fontWeight: 400,
});

export const rightIconsContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const dropDownRightIcon = (isOpened) => ({
  fontSize: 19,
  fontWeight: 600,
  transform: `rotate(${isOpened ? -180 : 0}deg)`,
  transition: 'transform 0.35s ease-out',
  cursor: 'pointer',
  display: 'inline-flex',
});

export const dropDownDeleteIcon = (theme) => ({
  display: 'inline-flex',
  fontSize: 17,
  height: 17,
  cursor: 'pointer',
  color: theme.textLightSecondary,

  '&:hover': {
    color: theme.textLightPrimary,
  },
});

export const dropdownSearch = (theme) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.white,
  borderBottom: `1px solid ${theme.grayLight}`,
});

export const noResultsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 8px',
};

export const noResultsIcon = (theme) => ({
  backgroundColor: theme.info,
  borderRadius: '50%',
  padding: 8,
});

export const infiniteScroll = {
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
};
