import queryString from 'query-string';
import { pick } from 'lodash-es';

export const getAllCatalogueItemsParams = (options) => {
  const filterFields = [];
  const filterValues = [];

  if (options.courseId) {
    filterFields.push('courseId');
    filterValues.push(options.courseId);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};

export const catalogueItemCoursesModel = (el) => ({
  courseId: el?.courseId,
  tokensPercentage: el?.tokensPercentage ?? 0,
});

export const updateCatalogueItemsCoursesModel = (body) => ({
  courses: body?.map(catalogueItemCoursesModel) ?? [],
});
