import { isPlainObject } from 'lodash-es';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../Shared/SearchInput';
import { CheckButton, Col, Row } from '../../UI';
import { optionsAccountQuizTakesSearchBy } from './config';

const noResultsMessages = {
  catalogueItemId: 'Package',
};

const TableFilterBarAccountCourses = ({ getData, tableOptions, setEmptyMessage }) => {
  const params = useRef({
    searchCriteria: optionsAccountQuizTakesSearchBy[0].value,
    searchInput: null,
    courseId: null,
    onlyLastTake: true,
  });

  useEffect(() => {
    getData(params.current);
  }, []);

  const fetchResults = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };

    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  const toggleWithLastTake = () => {
    params.current.onlyLastTake = !params.current.onlyLastTake;
    getData(params.current);
  };

  return (
    <Row horizontalGap={16}>
      <Col xl={4} lg={4} sm={12} xs={12}>
        <SearchInput placeholder="Search by package id..." onChange={(searchInput) => fetchResults({ searchInput })} />
      </Col>
      <Col xl={4} lg={6} sm={12} xs={12} align="center" dFlex>
        <CheckButton id="lastTakeCheckbox" checked={params.current.onlyLastTake} onChange={toggleWithLastTake} />
        <strong>Show only last take</strong>
      </Col>
    </Row>
  );
};

TableFilterBarAccountCourses.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
};

export default TableFilterBarAccountCourses;
