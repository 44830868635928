export const answersListWrap = {
  width: 200,
  marginBottom: 24,
};

export const answersListItem = (active) => (theme) => ({
  padding: 5,
  display: 'flex',
  alignItems: 'center',
  border: `2px solid ${active ? theme.secondary : theme.borderLight}`,
  marginBottom: 8,
  borderRadius: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.grayLightest,
  },
  '& > p': {
    marginLeft: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const uploadQuizButton = {
  marginTop: 32,
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};
