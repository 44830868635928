import { postReq, getReq, putReq } from './axios/makeRequest';
import { getBadgeModel, getAllBadgesParams, addEditBadgeBody } from './models/badges';

const baseUrl = `${apiUrls.oneAcademy}/badges`;

export const postNewBadge = (values) => postReq(`${baseUrl}/new`, addEditBadgeBody(values));

export const postNewBadgeTranslation = (id, data) => postReq(`${baseUrl}/${id}/translations/new`, data);

export const getBadgeById = async (id) => {
  const [response, error] = await getReq(`${baseUrl}/${id}`);
  return [getBadgeModel(response), error];
};

export const getBadgeByIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrl}/${id}/translations/${languageCode}`);

export const updateBadge = (id, data) => putReq(`${baseUrl}/${id}/update`, addEditBadgeBody(data));

export const updateBadgeTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update`, data);

export const getAllBadges = (options = {}) => {
  const params = getAllBadgesParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
