import { useState, useRef, useEffect } from 'react';
import { showError, showApiError, Icon, Button, Paper, showSuccess, Dropdown } from '../../UI';
import { wrap, uploadBtnWrap, folderSelector, uploadInput, itemsWrap, noFilesWrap } from './styles';
import { uploadToVimeo, setVideoWhitelist, getSubfoldersList, moveVideoToFolder } from '../../../services/videos';

const UploadVideoForm = () => {
  const [file, setFile] = useState();
  const [subfolders, setSubfolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const ref = useRef();

  useEffect(() => {
    fetchAllSubfolders();
  }, []);

  const fetchAllSubfolders = async () => {
    const [res, err] = await getSubfoldersList();
    if (err) return showApiError(err);

    setSubfolders(res?.data);
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setFile();
  };

  const handleUpload = async () => {
    const [res, error] = await uploadToVimeo(file);

    if (error) return showError('Video upload fail! Please try again.');

    const videoId = res?.uri.split('/').pop();

    await setVideoWhitelist(videoId);

    await moveVideoToFolder(videoId, selectedFolder?.id);

    showSuccess({
      title: 'Upload video',
      message: 'Successfully upload video.',
    });
    setFile();
  };

  return (
    <Paper header={null}>
      <div css={wrap}>
        <h3>Upload video</h3>
        <div className={uploadBtnWrap}>
          <Dropdown
            className={folderSelector}
            placeholder="Select folder..."
            displayKey="name"
            uniqueKey="id"
            options={subfolders}
            onChange={setSelectedFolder}
          />
          <Button secondary onClick={() => ref.current.click()} disabled={false}>
            Choose Files
          </Button>
        </div>
        <input ref={ref} className={uploadInput} type="file" accept="video/*" onChange={handleChange} />
        {file ? (
          <div className={itemsWrap}>
            <p>
              {selectedFolder?.name ? selectedFolder.name : 'Root folder'} / {file.name}
            </p>
            <Icon material color="primary" iconName="close" onClick={() => handleFileRemove()} />
          </div>
        ) : (
          <div css={noFilesWrap}>
            <p>No File selected!</p>
          </div>
        )}

        <Button onClick={handleUpload} disabled={!file} large>
          Upload
        </Button>
      </div>
    </Paper>
  );
};

export default UploadVideoForm;
