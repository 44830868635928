export const pages = [
  { url: '/packages', label: 'Packages' },
  {
    label: 'Package Details',
  },
];

export const pagesFromCourse = (courseId) => [
  { url: '/courses', label: 'Courses' },
  { url: `/courses/${courseId}`, label: 'Course Details' },
  {
    label: 'Package Details',
  },
];
