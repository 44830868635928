import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Paper,
  Form,
  FormInput,
  CheckBoxForm,
  FormParagraph,
  Breadcrumbs,
  showSuccess,
  showApiError,
} from '../../../components/UI';
import { rowComp, colComp } from '../../../components/UI/Grid';
import BackButton from '../../../components/Shared/BackButton';
import { postNewLanguage, updateLanguage, getLanguageById } from '../../../services/languages';
import { pages } from './config';
import { wrapper, backContainer, paperContain, textLink } from './styles';

const AddEditLanguage = () => {
  const history = useHistory();
  const { languageId } = useParams();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    languageId && fetchLanguageData();
  }, [languageId]);

  const fetchLanguageData = async () => {
    const [res, err] = await getLanguageById(languageId);
    err ? showApiError(err) : setLanguageData(res ?? []);
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (languageId
      ? updateLanguage(languageId, {
          ...languageData,
          ...formData,
        })
      : postNewLanguage(formData));
    if (error) return showApiError(error);
    showSuccess({
      title: `${languageId ? 'Edited' : 'Create'} language`,
      message: `Successfully ${languageId ? 'edited' : 'create'} language.`,
    });

    history.push('/languages');
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages(languageId)} />
      <BackButton css={backContainer} title={languageId ? 'Edit Language' : 'Add Language'} />
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: languageId ? 'Edit Language' : 'Add Language',
          }}
          values={languageData}>
          <FormInput id="name" label="Name" required />
          {!languageId && (
            <p>
              The code is a two or three letter word that serves to uniquely identify the language according to{' '}
              <a css={textLink} target="blank" href="http://www.mathguide.de/info/tools/languagecode.html">
                ISO639 standard
              </a>
              .
            </p>
          )}
          <FormParagraph css={rowComp({ margin: '0 -16px' })}>
            {!languageId && (
              <FormParagraph
                css={colComp({
                  sm: '12',
                  xs: '12',
                  horizontalGap: 16,
                })}>
                <FormInput id="code" label="Code" required />
              </FormParagraph>
            )}
          </FormParagraph>
          <FormParagraph css={rowComp({ margin: '0 -16px' })}>
            <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
              <CheckBoxForm id="active" label="Active" />
            </FormParagraph>
            <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
              <CheckBoxForm id="isDefault" label="isDefault" />
            </FormParagraph>
          </FormParagraph>
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditLanguage;
