export const transactionStatus = {
  Completed: 0,
  Pending: 1,
  Rejected: 2,
  Blocked: 3,
  Master: 4,
};

export const transactionType = {
  Outbound: 0,
  Inbound: 1,
};

export const splitRunType = {
  Regular: 'regular',
  Promo: 'promo',
};

export const splitRunStatus = {
  Pending: 'pending',
  Success: 'success',
  Failure: 'failure',
};

export const withdrawalStatus = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Cancelled: 'cancelled',
};
