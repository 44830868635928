import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import Checkbox from '../Button/CheckButton';
import { containerCheckBox } from './styles';

const CheckBoxForm = (props) => {
  const { id, label, value, onChange, className } = props;
  const handleChange = newValue => {
    if (!onChange || !isFunction(onChange))
      throw new Error('Mandatory "onChange handler is missing in props!');

    onChange(id, newValue);
  };

  return (
    <Checkbox
      checked={value}
      onChange={handleChange}
      css={containerCheckBox}
      {...(isString(className) && { className })}>
      {label}
    </Checkbox>
  );
};

CheckBoxForm.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CheckBoxForm;
