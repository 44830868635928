import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Paper,
  Form,
  FormDropdown,
  FormInput,
  CheckBoxForm,
  FormParagraph,
  Breadcrumbs,
  showSuccess,
  showApiError,
} from '../../../../components/UI';
import { rowComp, colComp } from '../../../../components/UI/Grid';
import BackButton from '../../../../components/Shared/BackButton';
import UploadImage from '../../../../components/Shared/UploadImage';
import LanguageSelect from '../../../../components/Shared/LanguageSelect';
import { postNewBadge, getBadgeById, updateBadge } from '../../../../services/badges';
import { getAllConditions } from '../../../../services/conditions';
import { pages } from './config';
import { wrapper, backContainer, paperContain } from '../styles';
import badge from '../../../../assets/images/default-badge.jpeg';

const AddEditBadge = () => {
  const history = useHistory();
  const { badgeId } = useParams();
  const [badgeData, setBadgeData] = useState({});
  const [conditions, setConditions] = useState([]);

  useEffect(() => {
    badgeId && fetchBadgeData();
    fetchConditions();
  }, [badgeId]);

  const fetchBadgeData = async () => {
    const [res, err] = await getBadgeById(badgeId);
    err ? showApiError(err) : setBadgeData(res ?? {});
  };

  const fetchConditions = async () => {
    const [res, err] = await getAllConditions();
    if (err) return showApiError(err);

    setConditions(res?.data ?? []);
  };

  const handleSubmit = async (formData) => {
    const picEncodedData = formData?.picUrl?.picThumbnail ?? '';

    const [, error] = await (badgeId
      ? updateBadge(badgeId, {
          ...badgeData,
          ...formData,
          picEncodedData,
        })
      : postNewBadge({ ...formData, picEncodedData }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${badgeId ? 'Edited' : 'Create'} badge`,
      message: `Successfully ${badgeId ? 'edited' : 'create'} badge.`,
    });

    history.push('/badges');
  };

  const handleLanguageChange = (code) => {
    history.push(`/badges/badge/${badgeId}/${code}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages(badgeId)} />
      <BackButton css={backContainer} title={badgeId ? 'Edit Badge' : 'Add Badge'} />
      {badgeId && <LanguageSelect onChange={handleLanguageChange} />}
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: badgeId ? 'Edit Badge' : 'Add Badge',
          }}
          values={badgeData}>
          <UploadImage id="picUrl" defaultValue={badge} />
          <FormInput id="name" label="Name" required />
          <FormDropdown
            id="badgeConditions"
            label="Conditions"
            placeholder="Select conditions..."
            displayKey="name"
            uniqueKey="id"
            options={conditions}
            required
            noClear
            withSearch
            multiSelect
          />
          <FormParagraph css={rowComp({ margin: '0 -16px' })}>
            <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
              <CheckBoxForm id="active" label="Active" />
            </FormParagraph>
            <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
              <CheckBoxForm id="visible" label="Visible" />
            </FormParagraph>
          </FormParagraph>
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditBadge;
