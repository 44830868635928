import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Icon, Paper, Modal, Form, FormInput, showApiError, showSuccess } from '../../../../UI';
import {
  postNewQuestionTranslation,
  getQuestionByIdTranslation,
  updateQuestionTranslations,
} from '../../../../../services/quizzes';
import { container, header, modalWrap, bodyWrap } from './styles';

const QuestionsTranslationModal = forwardRef((props, ref) => {
  const { questionId, onSubmit } = props;
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState();
  const [questionData, setQuestionData] = useState({});

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setLanguageData: (data) => setLanguage(data),
  }));

  useEffect(() => {
    language && fetchQuestionData();
  }, [hash({ language })]);

  const fetchQuestionData = async () => {
    const [res, err] = await getQuestionByIdTranslation(questionId, language?.code);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setQuestionData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(questionData)
      ? updateQuestionTranslations(questionId, language?.code, formData)
      : postNewQuestionTranslation(questionId, {
          ...formData,
          languageCode: language?.code,
        }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(questionData) ? 'Edited' : 'Create'} question translation`,
      message: `Successfully ${!isEmpty(questionData) ? 'edited' : 'create'} question translation.`,
    });
    handleClose();
  };

  const handleClose = () => {
    setShow(!show);
    onSubmit();
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>{`${!isEmpty(questionData) ? 'Edit' : 'Add'} question for translation ${language?.name} language`}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <Form
            onSubmit={handleSubmit}
            submitButton={{
              children: !isEmpty(questionData) ? 'Edit Question Translation' : 'Add Question Translation',
            }}
            values={questionData}>
            <FormInput id="title" label="Title" required />
          </Form>
        </div>
      </Paper>
    </Modal>
  );
});

QuestionsTranslationModal.propTypes = {
  onSubmit: PropTypes.func,
  questionId: PropTypes.number,
};

export default QuestionsTranslationModal;
