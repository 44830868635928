import ColorBatch from '../../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../../components/Shared/TableComponents';

export const columns = [
  {
    name: 'Badge id',
    value: 'id',
    width: 90,
    sortable: true,
  },
  {
    name: 'Name',
    value: 'name',
    width: 200,
  },
  {
    name: 'Status',
    value: 'active',
    render: (row) => (
      <ColorBatch type={row.active ? 'success' : 'error'}>{row.active ? 'Active' : 'Inactive'}</ColorBatch>
    ),
    sortable: true,
  },
  {
    name: 'Visibility',
    value: 'visible',
    render: (row) => (
      <ColorBatch type={row.visible ? 'success' : 'error'}>{row.visible ? 'Visible' : 'Invisible'}</ColorBatch>
    ),
    sortable: true,
  },
  {
    name: 'View details',
    render: (row) => <DetailsLink row={row} url={`/badges/badge/${row.id}`} />,
    width: 100,
  },
];

export const pages = (badgeId) => [
  { url: '/badges', label: 'Badges' },
  {
    label: badgeId ? 'Edit Badge' : 'Add Badge',
  },
];

export const pagesTranslation = (badgeId, hasTranslation) => [
  { url: '/badges', label: 'Badges' },
  { url: `/badges/badge/${badgeId}`, label: 'Badge Information' },
  {
    label: hasTranslation ? 'Edit Badge Translation' : 'Add Badge Translation',
  },
];
