import PropTypes from 'prop-types';
import { isString, kebabCase, isFunction } from 'lodash-es';
import { tabContainer } from './styles';

const Tab = (props) => {
  const { label, active, onClick, uuid } = props;

  const handleClick = () => onClick(label);

  return (
    <div
      role="presentation"
      css={tabContainer(active)}
      {...(isFunction(onClick) && { onClick: handleClick })}
      {...(isString(uuid) && { 'data-id': `tab-${kebabCase(uuid)}` })}>
      {label}
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  uuid: PropTypes.string,
};

export default Tab;
