import { postReq, getReq, putReq } from './axios/makeRequest';
import { getExpertModel, getAllExpertsParams, addEditExpertsBody, editExpertsTranslationBody } from './models/experts';

const baseUrl = `${apiUrls.oneAcademy}/experts`;

export const postNewExpert = (values) => postReq(`${baseUrl}/new`, addEditExpertsBody(values));

export const postNewExpertTranslation = (id, data) => postReq(`${baseUrl}/${id}/translations/new`, data);

export const getExpertById = async (id) => {
  const [response, error] = await getReq(`${baseUrl}/${id}`);
  return [getExpertModel(response), error];
};

export const getExpertIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrl}/${id}/translations/${languageCode}`);

export const updateExpert = (id, data) => putReq(`${baseUrl}/${id}/update`, addEditExpertsBody(data));

export const updateExpertTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update`, editExpertsTranslationBody(data));

export const getAllExperts = (options = {}) => {
  const params = getAllExpertsParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
