import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Icon,
  Tooltip,
  Row,
  Col,
  Form,
  showApiError,
  showSuccess,
  FormParagraph,
  FormInput,
  CheckBoxForm,
} from '../../../../UI';
import { rowComp, colComp } from '../../../../UI/Grid';
import { QuestionsAddModal, QuestionsList } from '../../Questions';
import QuizTranslationModal from '../QuizTranslationModal';
import LanguageSelect from '../../../../Shared/LanguageSelect';
import uuid from '../../../../../utils/uuid';
import { getAllQuizzes, updateQuiz } from '../../../../../services/quizzes';
import {
  wrap,
  noFilesWrap,
  quizWrap,
  quizTitle,
  quizEditForm,
  formBtnsEditQuizWrap,
  formBtnsEditQuizCancel,
  actionsButtonsWrap,
  iconButton,
  loadMoreButton,
} from './styles';

const QuizzesList = forwardRef((props, ref) => {
  const { courseId } = useParams();
  const [quizzes, setQuizzes] = useState({});
  const [selected, setSelected] = useState(null);
  const [editQuiz, setEditQuiz] = useState(null);

  const pageIndex = useRef(0);
  const questionsModalRef = useRef(null);
  // const reorderModalRef = useRef(null);
  const translationsModalRef = useRef(null);
  const languageSelectRef = useRef(null);

  useEffect(() => {
    fetchAllQuizzes();
  }, []);

  useImperativeHandle(ref, () => ({
    refreshQuizzesList: () => {
      pageIndex.current = 0;
      fetchAllQuizzes(0, true);
    },
  }));

  const fetchAllQuizzes = async (pageIndexValue = 0, refresh) => {
    const [res, err] = await getAllQuizzes({
      pageSize: 25,
      pageIndex: pageIndexValue,
      searchCriteria: 'courseId',
      searchInput: courseId,
    });

    if (err) return showApiError(err);

    const data = res?.data?.map((el) => ({
      ...el,
      title: el?.step?.title,
      visible: el?.step?.visible,
    }));

    setQuizzes((prev) => ({
      data: prev?.data && !refresh ? [...(prev?.data ?? []), ...data] : data,
      total: res?.total,
    }));
    pageIndex.current += 1;
  };

  const onSubmitQuiz = async (formData, quiz) => {
    const newQuizData = {
      ...quiz,
      ...formData,
    };

    const [, error] = await updateQuiz(quiz?.id, newQuizData);

    if (error) return showApiError(error);

    const updatedQuizzes = quizzes?.data?.map((el) => (el?.id === quiz?.id ? { ...el, ...newQuizData } : el));

    showSuccess({
      title: 'Edited quiz',
      message: 'Successfully edited quiz.',
    });

    setQuizzes((prev) => ({ data: updatedQuizzes, total: prev?.total }));
    setEditQuiz(null);
  };

  const openQuestionsModal = (data) => {
    questionsModalRef.current.setIsShow();
    questionsModalRef.current.setQuizData(data);
  };

  const SubmitBarCreateQuiz = ({ onSubmit }) => (
    <div css={formBtnsEditQuizWrap}>
      <Button onClick={onSubmit}>Save</Button>
      <Button css={formBtnsEditQuizCancel} secondary onClick={() => setEditQuiz(null)}>
        Cancel
      </Button>
    </div>
  );

  const handleLanguageChange = (data) => {
    if (!data) return;
    translationsModalRef.current.setIsShow();
    translationsModalRef.current.setLanguageData({ ...data, uuid: uuid() });
  };

  return (
    <>
      <div css={wrap}>
        <h3>Uploaded quizzes</h3>
        {quizzes?.data?.length ? (
          quizzes?.data?.map((el) => (
            <div key={el.id}>
              <>
                <div css={quizWrap(selected === el.id)}>
                  {el.id === editQuiz ? (
                    <>
                      <LanguageSelect ref={languageSelectRef} fullData onChange={handleLanguageChange} />
                      <Form
                        css={quizEditForm}
                        onSubmit={(data) => onSubmitQuiz(data, el)}
                        renderSubmit={SubmitBarCreateQuiz}
                        values={el}>
                        <FormParagraph css={rowComp({ margin: '0 -16px' })}>
                          <FormParagraph
                            css={colComp({
                              sm: 6,
                              horizontalGap: 16,
                            })}>
                            <FormInput id="title" label="Title" required />
                          </FormParagraph>
                          <FormParagraph
                            css={colComp({
                              sm: 6,
                              horizontalGap: 16,
                              verticalGap: 34,
                            })}>
                            <CheckBoxForm id="visible" label="Visible" />
                          </FormParagraph>
                        </FormParagraph>
                      </Form>
                    </>
                  ) : (
                    <Row horizontalGap={8} align="center">
                      <Col flex="1 0 0px " dFlex>
                        <Tooltip content={el?.visible ? 'visible' : 'not-visible'}>
                          <Icon
                            material
                            color={el?.visible ? 'info' : 'gray'}
                            iconName={el?.visible ? 'visibility' : 'visibility_off'}
                          />
                        </Tooltip>
                        <h3 css={quizTitle}>{el?.title}</h3>
                      </Col>
                      <Col flex="0 1 auto" dFlex>
                        <div css={actionsButtonsWrap}>
                          <Tooltip content="Edit Questions" position="top">
                            <Button
                              leftIcon={{
                                material: true,
                                color: 'white',
                                iconName: 'edit',
                              }}
                              css={iconButton}
                              onClick={() => setEditQuiz(el.id)}
                            />
                          </Tooltip>
                          <Tooltip content="Add Questions" position="top">
                            <Button
                              success
                              leftIcon={{
                                material: true,
                                color: 'white',
                                iconName: 'add_box',
                              }}
                              css={iconButton}
                              onClick={() => openQuestionsModal(el)}
                            />
                          </Tooltip>
                          <Tooltip content="Show Questions" position="top">
                            <Button
                              outline
                              leftIcon={{
                                material: true,
                                color: 'black',
                                iconName: selected === el.id ? 'expand_less' : 'expand_more',
                              }}
                              css={iconButton}
                              onClick={() => setSelected(selected === el.id ? null : el.id)}
                            />
                          </Tooltip>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
                {selected === el.id && <QuestionsList quizId={selected} />}
              </>
            </div>
          ))
        ) : (
          <div css={noFilesWrap}>
            <p>No Quizzes added!</p>
          </div>
        )}
        {quizzes?.data?.length < quizzes?.total && (
          <div css={loadMoreButton}>
            <Button info onClick={() => fetchAllQuizzes(pageIndex.current)}>
              Load more
            </Button>
          </div>
        )}
      </div>
      <QuestionsAddModal
        ref={questionsModalRef}
        quizData={quizzes?.data?.find((el) => el.id === selected)}
        onQuestionsDataUpdate={() => setSelected(null)}
      />
      <QuizTranslationModal
        quizId={editQuiz}
        ref={translationsModalRef}
        onSubmit={() => languageSelectRef.current.resetSelect()}
      />
    </>
  );
});

export default QuizzesList;
