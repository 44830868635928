import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash-es';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon, Paper, Modal, Row, Col, showApiError, showSuccess } from '../../../../UI';
import ColorBatch from '../../../../Shared/ColorBatch';
import { questionType } from './config';
import { container, header, modalWrap, bodyWrap, orderItemWrap } from './styles';
import { getAllQuestions, updateQuestion } from '../../../../../services/quizzes';

const QuestionsReorderModal = forwardRef((props, ref) => {
  const { onQuestionsDataUpdate } = props;
  const [show, setshow] = useState(false);
  const [quiz, setQuiz] = useState();
  const [questions, setQuestions] = useState([]);

  useImperativeHandle(ref, () => ({
    setIsShow: () => setshow(!show),
    setQuizData: (data) => setQuiz(data),
  }));

  useEffect(() => {
    quiz && fetchAllQuestions();
  }, [hash({ quiz })]);

  const handleClose = () => {
    setshow(!show);
  };

  const fetchAllQuestions = async () => {
    const [res, err] = await getAllQuestions({
      searchCriteria: 'quizId',
      searchInput: String(quiz?.id),
    });

    if (err) return showApiError(err);
    setQuestions(orderBy(res?.data, ['questionOrder'], ['asc']) ?? []);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const questionsPrev = [...questions];
    setQuestions(reorder([...questions], result.source.index, result.destination.index));
    const [, error] = await updateQuestion(result.draggableId, {
      ...questions[result.source.index],
      afterQuestionId: result.destination.index !== 0 ? questions[result.destination.index - 1].id : 0,
    });
    if (error) {
      setQuestions(questionsPrev);
      return showApiError(error);
    }
    showSuccess({
      title: 'Reorder questions',
      message: 'Successfully reorder questions.',
    });
    onQuestionsDataUpdate();
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>Reorder questions for {quiz?.title}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided) => (
                <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {questions?.map((el, index) => (
                    <Draggable key={String(el.id)} draggableId={String(el.id)} index={index}>
                      {(draggableProvided) => (
                        <div
                          key={el.id}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          css={orderItemWrap}>
                          <Row horizontalGap={6}>
                            <Col flex="1 0 0px " dFlex>
                              <h4>{el.title}</h4>
                            </Col>
                            <Col flex="0 1 auto" dFlex>
                              <ColorBatch type={questionType[el.answersType]?.color}>
                                {questionType[el.answersType]?.text}
                              </ColorBatch>
                              <ColorBatch type={el.visible ? 'success' : 'error'}>
                                {el.visible ? 'Visible' : 'Invisible'}
                              </ColorBatch>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Paper>
    </Modal>
  );
});

QuestionsReorderModal.propTypes = {
  onQuestionsDataUpdate: PropTypes.func,
};

export default QuestionsReorderModal;
