import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './Login';
import { contentContainer } from './styles';

const routesDefinition = [
  {
    exact: true,
    path: '/login',
    component: Login,
  },
].map((route) => <Route key={route.path} {...route} />);

export const PublicRoutes = (mainLink) => (
  <section css={contentContainer}>
    <Switch>
      {routesDefinition}
      <Redirect to={mainLink} />
    </Switch>
  </section>
);
