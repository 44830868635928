import { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import PropTypes from 'prop-types';
import { configSalesBarChart } from '../config';
import { container, header, titleContainer, description, chart } from './styles';

const SalesBarChart = ({ title, desc }) => {
  const ref = useRef();

  useEffect(() => {
    const newChart = new Chart(ref.current, configSalesBarChart);

    return () => {
      newChart.destroy();
    };
  }, []);

  return (
    <div css={container}>
      <div css={header}>
        <h3 css={titleContainer}>{title}</h3>
        <span css={description}>{desc}</span>
      </div>
      <div css={chart} style={{ height: '120px' }}>
        <canvas ref={ref} />
      </div>
    </div>
  );
};

SalesBarChart.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default SalesBarChart;
