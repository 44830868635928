import { DetailsLink } from '../../../../components/Shared/TableComponents';

export const conditionsColumns = (config) => [
  {
    name: 'Condition id',
    value: 'id',
    width: 110,
    sortable: true,
  },
  {
    name: 'Name',
    value: 'name',
  },
  {
    name: 'Query Alias',
    render: (row) => {
      const queryAliasConfig = config.queryAliases.find((el) => el.value === row.queryAlias);

      return queryAliasConfig?.label ?? row.queryAlias;
    },
  },
  {
    name: 'Value Type',
    render: (row) => {
      const assertionValueTypeConfig = config.assertionValueTypes.find((el) => el.value === row.assertionValueType);

      return assertionValueTypeConfig?.label ?? row.assertionValueType;
    },
  },
  {
    name: 'Value',
    value: 'assertionValue',
  },
  {
    name: 'Operator',
    render: (row) => {
      const assertionOperatorConfig = config.assertionOperators.find((el) => el.value === row.assertionOperator);

      return assertionOperatorConfig?.label ?? row.assertionOperator;
    },
  },
  {
    name: 'Period',
    render: (row) => {
      const assertionPeriodConfig = config.assertionPeriods.find((el) => el.value === row.assertionPeriod);

      return assertionPeriodConfig?.label ?? row.assertionPeriod;
    },
  },
  {
    name: 'View details',
    render: (row) => <DetailsLink row={row} url={`/badges/condition/${row.id}`} />,
    width: 100,
  },
];

export const pages = (badgeId) => [
  { url: '/badges/all-conditions', label: 'Conditions' },
  {
    label: badgeId ? 'Edit Condition' : 'Add Condition',
  },
];

export const booleanSelectValues = [
  { value: 'true', label: 'TRUE' },
  { value: 'false', label: 'FALSE' },
];
