import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, matchPath } from 'react-router-dom';
import qs from 'query-string';
import { omit } from 'lodash-es';
import {
  getToken,
  softLogout,
  renewSession,
  getRoles,
  hasAtLeastOneRole,
  USER_ROLES,
} from '@oneecosystem/authenticate';
import { getMyProfile } from '../../../services/profiles';

const AuthContext = React.createContext({});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = (props) => {
  const { children, renderLogout, renderRedirect } = props;
  const [user, setUser] = useState({ isAuthenticated: !!getToken() });

  useEffect(() => {
    prepareApplication();
  }, [user?.isAuthenticated]);

  useEffect(() => {
    if (user?.loaded) document.getElementById('splash-screen').style.display = 'none';
  }, [user?.loaded]);

  const prepareApplication = async () => {
    // In any case we should call renew session, the user may have valid access and refresh tokens
    // The case where the user comes from other application we pass the refresh token as query param rt
    const refreshToken = qs.parse(window.location.search)?.rt;
    await renewSession(refreshToken);
    // If there is refresh token in the url replace the history record for it so it is destroyed and remove it from current query params
    if (refreshToken) {
      const newUrl = qs.exclude(window.location.href, ['rt']);
      window.history.replaceState(null, '', newUrl);
      window.location.search = qs.extract(newUrl);
    }

    getToken()
      ? prepareUser()
      : !matchPath(window.location.pathname, '/authorization-callback') &&
        setUser({ loaded: true, isAuthenticated: false });
  };

  const prepareUser = async () => {
    try {
      await renewSession();
      const [userData] = await getMyProfile();

      setUser({
        ...userData,
        roles: getRoles(),
        isAuthorized: hasAtLeastOneRole([USER_ROLES.ADMINISTRATOR, USER_ROLES.KYC_OPERATOR]),
        isAuthenticated: true,
        loaded: true,
      });
    } catch (err) {
      setUser({ isAuthenticated: false, loaded: true });
      softLogout();
    }
  };

  const userData = omit(user, ['loaded', 'isAuthorized', 'isAuthenticated']);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user.isAuthenticated,
        isAuthorized: user.isAuthorized,
        user: userData,
        setUser,
        prepareUser,
      }}>
      {renderRedirect && <Route exact path="/authorization-callback" component={renderRedirect} />}
      {renderLogout && <Route exact path="/logout" component={renderLogout} />}
      {user.loaded && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  renderRedirect: PropTypes.func,
  renderLogout: PropTypes.func,
};
