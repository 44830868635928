import { useRef, useEffect, useState, cloneElement, Children } from 'react';
import { isObject, omit, isNil, isFunction } from 'lodash-es';
import hash from 'object-hash';
import { mapPreFilledValues, areEqual } from './utils';
import { getNestedProperty, setNestedValue, deleteNestedValue } from '../../../utils';

export const useForm = ({
  preFilledValues: initPreFilledValues = {},
  values: initValues = {},
  children: childs,
  onSubmit,
  onChange,
  memoDependencies,
  onReset,
  readOnly,
}) => {
  const [preFilledValues, setPreFilledValues] = useState(initPreFilledValues);
  // Every input has it's own state but all values that are typed by the user
  // need to be saved into state attached into the form
  const children = Children.toArray(childs).filter(Boolean);
  const [values, setValues] = useState(mapPreFilledValues(preFilledValues, children));
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [resetKey, setResetKey] = useState('');
  const errors = useRef({});

  useEffect(() => {
    if (isObject(initPreFilledValues)) {
      setValues(mapPreFilledValues(initPreFilledValues, children));
      setPreFilledValues(initPreFilledValues);
    }
  }, [hash({ initPreFilledValues })]);

  // Check if all properties inside errors state are false
  const hasError = Object.keys(errors.current).length;

  // All form values are stored to inputs ref, every input field has a state
  const handleValuesChange = (id, val) => {
    const idKeys = id.split('.');
    const initValue = getNestedProperty(initValues, id.split('.'));

    areEqual(val, id, initValue, children)
      ? setValues((prev) => deleteNestedValue({ ...prev }, idKeys))
      : setValues((prev) => setNestedValue({ ...prev }, idKeys, val));

    isFunction(onChange) && onChange(id, val);
  };

  const handleErrorChange = (id, newError) => {
    errors.current = newError ? { ...errors.current, [id]: newError } : omit({ ...errors.current }, id);
  };

  const handleSubmit = async () => {
    !isTouched && setIsTouched(true);

    if (hasError) return;

    setLoading(true);
    const error = await onSubmit(values);

    if (error) {
      setLoading(false);
      setPreFilledValues(values);
    } else resetForm();
  };

  const reloadForm = () => setResetKey(`FormResetKey${resetKey + 1}`);

  const resetForm = () => {
    setValues({});
    errors.current = {};
    reloadForm();
    setIsTouched(false);
    setLoading(false);
    isFunction(onReset) && onReset();
  };

  const generateChildren = (child, i) => {
    let value;
    const {
      props,
      type: { displayName },
    } = child;
    const { children: elementChilds, id } = props;
    const hasId = props && id;
    const isParagraph = displayName === 'FormParagraph';
    const isCssPropInternal = props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__ !== undefined;
    const noIdKey = isParagraph ? `FormParagraph${i}` : `FormField${i}`;

    if (hasId) {
      const initValue = getNestedProperty(initValues, id.split('.'));
      const filledValue = getNestedProperty(preFilledValues, id.split('.'));
      value = isNil(filledValue) ? initValue : filledValue;
    }

    // emotion css clones the component which has a css prop defined
    // (eg. clones FormParagraph and adds 'EmotionCssPropInternal' as displayName)
    // and breaks the FormParagraph recursion
    if (isParagraph || isCssPropInternal) {
      return cloneElement(child, {
        key: noIdKey,
        preFilledValues,
        initValues,
        children: Children.toArray(elementChilds).filter(Boolean).map(generateChildren),
      });
    }

    return cloneElement(
      child,
      hasId
        ? {
            key: hasId ? id : noIdKey,
            onChange: handleValuesChange,
            onError: handleErrorChange,
            value,
            ...((memoDependencies || child.props.memoDependencies) && {
              memoDependencies: memoDependencies || child.props.memoDependencies,
            }),
            ...((isTouched || child.props.isTouched) && {
              isTouched: isTouched || child.props.isTouched,
            }),
            ...((readOnly || child.props.readOnly) && {
              readOnly: readOnly || child.props.readOnly,
            }),
          }
        : { key: noIdKey },
    );
  };

  // Add additional props to the inputs.
  const newChildren = children.map(generateChildren);

  return {
    handleSubmit,
    loading,
    reset: {
      resetKey,
      resetForm,
      reloadForm,
    },
    formOptions: {
      values,
      errors: errors.current,
      children: newChildren,
    },
    newChildren,
  };
};
