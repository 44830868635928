export const backgroundPicture = ({ size, src, width, height, position, repeat }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: size || 'cover',
  backgroundPosition: position || 'center',
  backgroundRepeat: repeat || 'no-repeat',
  width: width || '100%',
  height: height || '100%',
});

export const imageContainer = ({ size, position }) => ({
  objectFit: size || 'cover',
  objectPosition: position || 'center',
});

export const child = ({ fontSize, color }) => [
  {
    fontWeight: 'bold',
    fontSize: fontSize || 16,
  },
  color && {
    color,
  },
];
