export const wrapper = {
  padding: '48px 40px',
};

export const container = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48,

  '& > h1': {
    marginBottom: 0,
    fontWeight: 600,
  },
};

export const backContainer = {
  marginBottom: 32,
};

export const paperContain = {
  marginBottom: 50,
  maxWidth: 850,
};

export const inputDescriptionContainer = {
  height: 220,
  ' & > textarea': {
    height: 220,
  },
};

export const navLink = (theme) => ({
  borderRadius: 2,
  padding: '10px 20px',
  color: theme.white,
  border: `1px solid ${theme.primaryLight}`,
  textAlign: 'center',
  backgroundColor: theme.primaryLight,
  fontSize: 14,

  '&:hover': {
    backgroundColor: theme.primary,
  },

  '&:focus, &:active': {
    backgroundColor: theme.primaryLight,
    color: theme.textPrimaryFocused,
  },
});
