export const wrap = {
  marginBottom: 32,
  '& > h3': {
    marginBottom: 16,
  },
};

export const body = {
  '& > h3': {
    marginBottom: 16,
  },
  '& > div > div': {
    marginBottom: 0,
  },
};

export const list = {
  marginBottom: 16,
  '& > h3': {
    marginBottom: 16,
  },
};

export const packagesContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: 16,
  '& span, & strong': {
    display: 'inline-block',
    marginRight: 6,
  },
};

export const packagesWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 6,
  backgroundColor: theme.whiteGrayLight,
  border: `1px solid ${theme.borderLight}`,
  borderRadius: 4,
  padding: 6,
  paddingLeft: 12,
  '& > p': {
    fontWeight: 600,
    marginRight: 12,
  },
});

export const rightCol = {
  display: 'flex',
  alignItems: 'center',
};

export const inputWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  maxWidth: 124,
  '& > input': {
    paddingRight: 40,
    paddingBottom: 24,
    paddingTop: 6,
  },
  '& .percent': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: 14,
    paddingRight: 12,
    paddingLeft: 12,
    borderLeft: `1px solid ${theme.borderLight}`,
  },
  '& .max': {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 37,
    borderTop: `1px solid ${theme.borderLight}`,
    paddingLeft: 12,
    paddingBottom: 4,
  },
});

export const saveBtnWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingTop: 16,
};

export const itemLink = (theme) => ({
  '& p': {
    color: theme.highlightsBlue,
    fontWeight: 600,
    marginRight: 16,
    fontSize: 14,
  },
  '&  i': {
    fontSize: 12,
  },
});

export const deleteButton = (theme) => ({
  backgroundColor: theme.white,
  height: 56,
  ' & > i': {
    margin: 0,
  },
});
