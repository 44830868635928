import { Table, tableTitleHeadline } from '../../../components/UI';
import TableFilterBarAcademyAccounts from '../../../components/AcademyAccounts/TableFilterBarAcademyAccounts';
import { getAllAcademyAccounts } from '../../../services/academy-account';
import { columns } from './config';
import { wrapper } from './styles';

const AcademyAccounts = () => {
  const fetchAllAcademyAccounts = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllAcademyAccounts(options);
    return res;
  };

  return (
    <div css={wrapper}>
      <h1 css={tableTitleHeadline}>Academy Accounts List</h1>

      <Table
        getDataKey="data"
        getDataMethod={fetchAllAcademyAccounts()}
        emptyMessage="There are no accounts."
        columns={columns}
        filterBar={TableFilterBarAcademyAccounts}
        pageSize={10}
      />
    </div>
  );
};

export default AcademyAccounts;
