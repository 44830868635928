import { getReq, putReq } from './axios/makeRequest';
import {
  getAcademyAccountCoursesParams,
  getAllAcademyAccountsParams,
  getAcademyAccountQuizTakesParams,
} from './models/academy-account';

const baseUrl = `${apiUrls.oneAcademy}/accounts`;

export const getAllAcademyAccounts = (options = {}) => {
  const params = getAllAcademyAccountsParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};

export const getAcademyAccountById = id => getReq(`${baseUrl}/${id}`);

export const getAcademyAccountCourses = (id, options = {}) => {
  const params = getAcademyAccountCoursesParams(options);
  return getReq(`${baseUrl}/${id}/courses?${params}`);
};

export const getAcademyAccountQuizTakes = (id, options = {}) => {
  const params = getAcademyAccountQuizTakesParams(options);
  return getReq(`${baseUrl}/${id}/quiz-takes/list?${params}`);
};

export const updateAccountCourse = (id, data) => putReq(`${baseUrl}/${id}/update`, data);
