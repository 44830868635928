import { Link } from 'react-router-dom';
import { Table } from '../../../components/UI';
import TableFilterBarLanguages from '../../../components/Languages/TableFilterBarLanguages';
import { getAllLanguages } from '../../../services/languages';
import { columns } from './config';
import { wrapper, container, navLink } from './styles';

const Languages = () => {
  const fetchAllLanguages = () => async (tableOptions) => {
    const options = {
      ...tableOptions,
    };
    const res = await getAllLanguages(options);
    return res;
  };

  return (
    <div css={wrapper}>
      <div css={container}>
        <h1>Languages List</h1>
        <Link to="/languages/add-language" css={navLink}>
          Add Language
        </Link>
      </div>

      <Table
        getDataKey="data"
        getDataMethod={fetchAllLanguages()}
        emptyMessage="There are no languages."
        columns={columns}
        filterBar={TableFilterBarLanguages}
        pageSize={10}
      />
    </div>
  );
};

export default Languages;
