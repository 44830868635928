import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BackButton from '../../../components/Shared/BackButton';
import { Paper, Row, Col, Breadcrumbs, Tabs, Tab, showApiError, Skeleton } from '../../../components/UI';
import { pages } from './config';
import { wrapper, backContainer } from './styles';
import AcademyAccountAchievements from './AccountAchievements';
import AcademyAccountCourses from './AccountCourses';
import AcademyAccountQuizTakes from './AccountQuizTakes';
import { getAcademyAccountById } from '../../../services/academy-account';
import { useParams } from 'react-router-dom';

const AcademyAccountDetails = () => {
  const location = useLocation();
  const { accountId } = useParams();
  const [accountInfo, setAccountInfo] = useState(location?.state?.accountInfo || {});

  useEffect(() => {
    accountInfo?.accomplishment ?? fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    const [res, err] = await getAcademyAccountById(accountId);

    if (err)
      return showApiError(err);


    res && setAccountInfo(res);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages} />
      <BackButton css={backContainer} title="Academy Account Information" />
      <Paper header="Academy Account Info" css={backContainer}>
        <Row gap={16}>
          <Col xl={8} md={8}>
            <Row>
              <Col xl={4} md={4}><h4>User Id</h4></Col>
              <Col xl={8} md={8}>{accountInfo?.userId ? accountInfo.userId : <Skeleton width="100%" />}</Col>
            </Row>
            <Row>
              <Col xl={4} md={4}><h4>Account Name</h4></Col>
              <Col xl={8} md={8}>{accountInfo?.userName ? accountInfo.userName : <Skeleton width="100%"/>}</Col>
            </Row>
          </Col>
          <Col xl={4} md={4}>
            <Row>
              <Col xl={8} md={8}><h4>Account Id</h4></Col>
              <Col xl={4} md={4}>{accountInfo?.id ? accountInfo.id : <Skeleton width="100%" />}</Col>
            </Row>
            <Row>
              <Col xl={8} md={8}><h4>External Account Id</h4></Col>
              <Col xl={4} md={4}>{accountInfo?.externalAccountId ? accountInfo.externalAccountId : <Skeleton width="100%" />}</Col>
            </Row>
          </Col>
        </Row>
      </Paper>

      <Row>
        <Tabs startingRoute={`/academy-accounts/${accountId}`}>
          <Tab label="Achievements" url="/achievements" state={{ accountInfo }} component={() => <AcademyAccountAchievements accomplishment={accountInfo?.accomplishment}/>} />
          <Tab label="Started Courses" url="/courses" state={{ accountInfo }} component={() => <AcademyAccountCourses accountId={accountId} />} />
          <Tab label="Quiz Takes" url="/quiz-takes" state={{ accountInfo }} component={() => <AcademyAccountQuizTakes accountId={accountId} />} />
        </Tabs>
      </Row>
    </div>
  );
};

export default AcademyAccountDetails;
