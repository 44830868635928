import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Image, showError } from '../../UI';
import { processImages } from '../../../utils';
import {
  inputContainer,
  buttonsContainer,
  addButton,
  removeButton,
  iconAdd,
  iconRemove,
  pictureContainer,
} from './styles';

const ImageUploadButtons = (props) => {
  const { id, onChange, value, noRemove, defaultValue, thumbMaxWidth = 300, thumbMaxHeight = 200 } = props;

  const [uploadedImage, setUploadedImage] = useState({});

  const handleRemove = () => {
    setUploadedImage({ picThumbnail: defaultValue });
    onChange(id, { picThumbnail: '', picture: '' });
  };

  const uploadAvatar = async ({ target: { files } }) => {
    const image = await processImages(files, thumbMaxWidth, thumbMaxHeight);

    if (image.error) return showError(image.error);

    setUploadedImage(image);
    onChange(id, image);
  };

  return (
    <div css={inputContainer}>
      <Image
        src={uploadedImage?.picThumbnail ?? value ?? defaultValue}
        width={120}
        height={70}
        size="cover"
        css={pictureContainer}
      />
      <div css={buttonsContainer}>
        <label htmlFor="upload-image" css={addButton}>
          <div css={iconAdd}>
            <Icon iconName="fas fa-camera" color="white" size={17} />
          </div>
          <span>Upload New Image</span>
          <input
            id="upload-image"
            className="pictures-input"
            value=""
            type="file"
            name="file"
            accept="image/*"
            onChange={uploadAvatar}
          />
        </label>
        {!noRemove && (
          <div css={removeButton} role="presentation" onClick={handleRemove}>
            <div css={iconRemove}>
              <Icon iconName="la la-trash" color="gray" size={24} />
            </div>
            <span>Remove</span>
          </div>
        )}
      </div>
    </div>
  );
};

ImageUploadButtons.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noRemove: PropTypes.bool,
  defaultValue: PropTypes.string,
  thumbMaxWidth: PropTypes.number,
  thumbMaxHeight: PropTypes.number,
};

export default ImageUploadButtons;
