import { postReq, getReq, putReq } from './axios/makeRequest';
import { getAllCategoriesParams, addEditCategoryBody } from './models/categories';

const baseUrl = `${apiUrls.oneAcademy}/categories`;

export const postNewCategory = (values) => postReq(`${baseUrl}/new`, addEditCategoryBody(values));

export const postNewCategoryTranslation = (id, data) => postReq(`${baseUrl}/${id}/translations/new`, data);

export const getCategoryById = async (id) => getReq(`${baseUrl}/${id}`);

export const getCategoryByIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrl}/${id}/translations/${languageCode}`);

export const updateCategory = (id, data) => putReq(`${baseUrl}/${id}/update`, addEditCategoryBody(data));

export const updateCategoryTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update`, data);

export const getAllCategories = (options = {}) => {
  const params = getAllCategoriesParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
