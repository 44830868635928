export const wrap = (theme) => ({
  border: `2px solid ${theme.grayLighter}`,
  padding: 8,
  borderRadius: 4,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  '& .selectButtonWrap': {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
    display: 'none',
  },
  '& > h3': {
    textAlign: 'center',
    marginBottom: 16,
    minHeight: 57,
    fontSize: '1.2em',
  },
  '&:hover': {
    '& .selectButtonWrap': {
      display: 'block',
    },
  },
});

export const videoWrap = {
  position: 'relative',
  padding: '56.25% 0 0 0',
  '& > iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

export const searchContainer = {
  padding: 24,
  paddingBottom: 8,
};

export const loadMoreBtn = {
  width: '100%',
  justifyContent: 'center',
  marginTop: 40,
};

export const selectButtonWrap = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 8,
  display: 'none',
};
