import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash-es';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon, Paper, Modal, Row, Col, showApiError, showSuccess } from '../../../../UI';
import ColorBatch from '../../../../Shared/ColorBatch';
import { container, header, modalWrap, bodyWrap, orderItemWrap } from './styles';
import { getAllAnswers, updateAnswer } from '../../../../../services/quizzes';

const AnswersReorderModal = forwardRef((props, ref) => {
  const { onAnswersDataUpdate } = props;
  const [show, setshow] = useState(false);
  const [question, setQuestion] = useState();
  const [answers, setAnswers] = useState([]);

  useImperativeHandle(ref, () => ({
    setIsShow: () => setshow(!show),
    setQuestionData: (data) => setQuestion(data),
  }));

  useEffect(() => {
    question && fetchAllAnswers();
  }, [hash({ question })]);

  const handleClose = () => {
    setshow(!show);
  };

  const fetchAllAnswers = async () => {
    const [res, err] = await getAllAnswers({
      searchCriteria: 'questionId',
      searchInput: String(question?.id),
    });

    if (err) return showApiError(err);
    setAnswers(orderBy(res?.data, ['answerOrder'], ['asc']) ?? []);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const answersPrev = [...answers];
    setAnswers(reorder([...answers], result.source.index, result.destination.index));
    const [, error] = await updateAnswer(result.draggableId, {
      ...answers[result.source.index],
      afterAnswerId: result.destination.index !== 0 ? answers[result.destination.index - 1].id : 0,
    });
    if (error) {
      setAnswers(answersPrev);
      return showApiError(error);
    }
    showSuccess({
      title: 'Reorder answers',
      message: 'Successfully reorder answers.',
    });
    onAnswersDataUpdate();
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  return (
    <Modal css={modalWrap} show={show} withoutClose>
      <Paper
        header={
          <header css={header}>
            <h3>Reorder questions for: {question?.title}</h3>
            <Icon onClick={handleClose} material iconName="close" />
          </header>
        }
        css={container}>
        <div css={bodyWrap}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided) => (
                <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {answers?.map((el, index) => (
                    <Draggable key={String(el.id)} draggableId={String(el.id)} index={index}>
                      {(draggableProvided) => (
                        <div
                          key={el.id}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          css={orderItemWrap}>
                          <Row horizontalGap={6}>
                            <Col flex="1 0 0px " dFlex>
                              <h4>{el.text}</h4>
                            </Col>
                            <Col flex="0 1 auto" dFlex>
                              <ColorBatch type={el.isCorrect ? 'success' : 'error'}>
                                {el.isCorrect ? 'Correct' : 'Incorrect'}
                              </ColorBatch>
                              <ColorBatch type={el.visible ? 'success' : 'error'}>
                                {el.visible ? 'Visible' : 'Invisible'}
                              </ColorBatch>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Paper>
    </Modal>
  );
});

AnswersReorderModal.propTypes = {
  onAnswersDataUpdate: PropTypes.func,
};

export default AnswersReorderModal;
