export const container = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
};

export const content = {
  display: 'flex',
  paddingBottom: '1rem',
  flexGrow: '1',
  flexDirection: 'column',
};

export const item = {
  display: 'flex',
  marginBottom: '2.5rem',
  alignItems: 'center',
};

export const info = {
  flex: 1,
};

export const description = (theme) => ({
  fontSize: '1rem',
  color: theme.gray,
  paddingBottom: '0.5rem',
  fontWeight: 500,
  display: 'block',
});

export const value = (theme) => ({
  fontSize: '1.3rem',
  fontWeight: 600,
  color: theme.gray,
  display: 'block',
});

export const progress = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '0',
  paddingTop: '1rem',
};

export const progressSm = {
  width: '100%',
  height: 10,
};

export const stat = (theme) => ({
  fontSize: '1.3rem',
  fontWeight: 700,
  color: theme.textLightSecondary,
  paddingLeft: '1rem',
});

export const chart = {
  position: 'relative',

  '& > canvas': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
};

export const containerHeader = (theme) => ({
  borderBottom: `1px solid ${theme.borderLight}`,
  padding: '16px 32px',
});
