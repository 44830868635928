import { postReq, getReq, putReq } from './axios/makeRequest';
import {
  uploadCourseMaterialsBody,
  getAllMaterialsParams,
  editMaterialBody,
  postMaterialTranslationBody,
  updateMaterialTranslationBody,
} from './models/materials';

const baseUrl = `${apiUrls.oneAcademy}/materials`;

export const uploadCourseMaterials = (id, values) =>
  postReq(`${baseUrl}/upload`, uploadCourseMaterialsBody(id, values));

export const postNewMaterialTranslation = (id, data) =>
  postReq(`${baseUrl}/${id}/translations/new`, postMaterialTranslationBody(data));

export const getAllMaterials = (options = {}) => {
  const params = getAllMaterialsParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};
export const getMaterialByIdTranslation = async (id, languageCode) =>
  getReq(`${baseUrl}/${id}/translations/${languageCode}`);

export const updateMaterial = (id, data) => putReq(`${baseUrl}/${id}/update`, editMaterialBody(data));

export const updateMaterialTranslations = (id, languageCode, data) =>
  putReq(`${baseUrl}/${id}/translations/${languageCode}/update`, updateMaterialTranslationBody(data));
