import { isNil, isString, isNumber, isArray, isPlainObject, isEmpty } from 'lodash-es';

export const noValue = (val) => isNil(val) || ((isString(val) || isArray(val) || isPlainObject(val)) && isEmpty(val));

export const isStrNum = (val) => isString(val) || isNumber(val);

export const jsonParse = (text, defaultValue = null) => {
  if (typeof text !== 'string') return defaultValue;

  try {
    return JSON.parse(text);
  } catch (error) {
    return defaultValue;
  }
};

export const stringToBoolean = (string) => {
  switch (
    String(string)
      .toLowerCase()
      .trim()
  ) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};
