import { headerContainer } from '../../../UI';

export const wrap = {
  marginBottom: 32,
};

export const container = (theme) => [
  headerContainer(theme),
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
];

export const pictureContainer = {
  marginBottom: 32,
};
export const wordWrap = {
  wordBreak: 'break-all',
};
