import moment from 'moment';
import PropTypes from 'prop-types';
import TableFilterBarAccountQuizTakes from '../../../../components/AcademyAccounts/TableFilterBarAccountQuizTakes';
import { Paper, Table } from '../../../../components/UI';
import { getAcademyAccountQuizTakes } from '../../../../services/academy-account';
import { columns } from './config';

const mapToTableResults = (apiResult = []) => {
  const tableResult = (apiResult || []).map((data) =>
    Object.assign({}, data, {
      scorePercentage: `${Math.floor(Number(data.scorePercentage))} %`,
      quizName: data.step?.title,
      createdOn: moment(data.createdOn).format('DD/MM/YYYY'),
    }),
  );

  return tableResult;
};

const AcademyAccountQuizTakes = ({ accountId }) => {
	const fetchAccountQuizTakes = () => async tableOptions => {
		const options = { ...tableOptions };

		const [res, err] = await getAcademyAccountQuizTakes(accountId, options);

    if (err) return [null, err];

    const mappedResult = Object.assign({}, res, {
      data: mapToTableResults(res.data),
    });

    return [mappedResult, err];
  };

	return (
		<Paper>
			<Table
				getDataKey="data"
				getDataMethod={fetchAccountQuizTakes()}
				emptyMessage="There are no quiz takes."
				columns={columns}
				filterBar={TableFilterBarAccountQuizTakes}
				pageSize={10}
			/>
		</Paper>
	);
};

AcademyAccountQuizTakes.propTypes = {
	accountId: PropTypes.string
};

export default AcademyAccountQuizTakes;
