import { logout } from '@oneecosystem/authenticate';
import { Button } from '../../../components/UI';
import { mainContainer } from './styles';

const Unauthorized = () => (
  <div css={mainContainer}>
    <h1>403</h1>
    <h2>Access forbidden</h2>
    <a href={logout()}>
      <Button outline large>
        Logout
      </Button>
    </a>
  </div>
);

export default Unauthorized;
