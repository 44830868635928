export const wrapper = {
  padding: '48px 40px',
};

export const backContainer = {
  marginBottom: 32,
};

export const pictureContainer = {
  borderRadius: '50%',
};

export const badgeTitle = {
  marginBottom: 16,
};

export const badgeWrap = (theme) => ({
  backgroundColor: theme.borderLight,
  paddingLeft: 16,
  borderRadius: 8,
  marginBottom: 16,
});

export const noBadgesWrap = (theme) => ({
  backgroundColor: theme.grayLightest,
  marginBottom: 24,
  padding: 24,
  textAlign: 'center',
  '& > p': {
    fontWeight: 600,
  },
});
