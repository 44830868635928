import ColorBatch from '../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../components/Shared/TableComponents';

export const columns = [
  {
    name: 'ID',
    value: 'id',
    width: 90,
    sortable: true,
  },
  {
    name: 'Name',
    value: 'name',
    width: 200,
  },
  {
    name: 'Code',
    value: 'code',
    width: 200,
  },
  {
    name: 'Status',
    value: 'active',
    render: (row) => (
      <ColorBatch type={row.active ? 'success' : 'error'}>{row.active ? 'Active' : 'Inactive'}</ColorBatch>
    ),
    sortable: true,
  },
  {
    name: 'Default',
    value: 'isDefault',
    render: (row) => (row.isDefault ? <ColorBatch type="success">Default</ColorBatch> : 'N/A'),
    sortable: true,
  },
  {
    name: 'View details',
    render: (row) => <DetailsLink row={row} url={`/languages/${row.id}`} />,
    width: 100,
  },
];

export const pages = (languageId) => [
  { url: '/languages', label: 'Languages' },
  {
    label: languageId ? 'Edit Language' : 'Add Language',
  },
];
