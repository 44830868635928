import queryString from 'query-string';
import { pick, isNumber, isBoolean, isArray, isString } from 'lodash-es';
import { transactionStatus } from '../../enums/transactions';
import { validDate, endOfTheDay } from '../../utils';

export const getTransactionsModel = (response) =>
  response && {
    ...response,
    completionStatus: transactionStatus[response?.completionStatus],
    modifiedOn: validDate(response.modifiedOn),
    createdOn: validDate(response.createdOn),
    receiver: response.receiver ?? 'Platform',
    sender: response.sender ?? 'Platform',
  };

export const getAllTransactionParams = (options) => {
  const {
    type,
    status,
    senderSearchBy,
    senderSearchFor,
    receiverSearchBy,
    receiverSearchFor,
    walletType,
    startDate,
    endDate,
    senderBalanceFrom,
    senderBalanceTo,
    receiverBalanceFrom,
    receiverBalanceTo,
    sort = {
      sortField: 'createdOn',
      sortOrder: 'desc',
    },
    walletId,
    excludeMaster,
    reason,
    senderFirstName,
    senderLastName,
    receiverFirstName,
    receiverLastName,
  } = options;

  const filterField = [];
  const filterValue = [];
  const dateRange = {};

  const statusOptionsValues = {
    [transactionStatus.Pending]: 'pending',
    [transactionStatus.Completed]: 'completed',
    [transactionStatus.Rejected]: 'rejected',
    [transactionStatus.Blocked]: 'blocked',
  };

  const mapSort = (newSort) => ({
    sortField: newSort.sortField,
    sortOrder: newSort.sortOrder,
  });

  if (statusOptionsValues[status]) {
    filterField.push('completionStatus');
    filterValue.push(statusOptionsValues[status]);
  }

  if (type) {
    filterField.push('type');
    filterValue.push(type);
  }

  if (isString(walletType) && walletType) {
    filterField.push('walletType');
    filterValue.push(walletType);
  }

  if (isArray(walletType)) {
    walletType.forEach((el) => {
      filterField.push('walletType');
      filterValue.push(el);
    });
  }

  if (senderSearchBy && senderSearchFor?.length) {
    filterField.push(options.senderSearchBy);
    filterValue.push(options.senderSearchFor);
  }

  if (receiverSearchBy && receiverSearchFor?.length) {
    filterField.push(options.receiverSearchBy);
    filterValue.push(options.receiverSearchFor);
  }

  if (startDate && endDate) {
    dateRange.DateFrom = endOfTheDay(startDate).format();
    dateRange.DateEnd = endOfTheDay(endDate).format();
  }

  if (reason) {
    filterField.push('reason');
    filterValue.push(reason);
  }

  if (senderFirstName?.length) {
    filterField.push('senderFirstName');
    filterValue.push(senderFirstName);
  }

  if (senderLastName?.length) {
    filterField.push('senderLastName');
    filterValue.push(senderLastName);
  }

  if (receiverFirstName?.length) {
    filterField.push('receiverFirstName');
    filterValue.push(receiverFirstName);
  }

  if (receiverLastName?.length) {
    filterField.push('receiverLastName');
    filterValue.push(receiverLastName);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(sort && mapSort(sort)),
      ...(filterField.length && filterValue.length && { filterField, filterValue }),
      ...(isBoolean(excludeMaster) && {
        excludeMaster,
      }),
      ...dateRange,
      ...(walletId && { walletId }),
      ...(isNumber(senderBalanceFrom) && { senderBalanceFrom }),
      ...(isNumber(senderBalanceTo) && { senderBalanceTo }),
      ...(isNumber(receiverBalanceFrom) && { receiverBalanceFrom }),
      ...(isNumber(receiverBalanceTo) && { receiverBalanceTo }),
    },
    { arrayFormat: 'index' },
  );
};
