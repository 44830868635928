const itemHeight = 51;

export const searchBarContainer = (isOpen, hasMatches) => ({
  borderRadius: '2px',
  boxShadow: isOpen && hasMatches ? '0 2px 4px rgba(0,0,0,0.3)' : 'none',
  position: 'relative',
  zIndex: 50,
  width: '100%',
  minWidth: 200,
  outline: 'none',
  transition: 'all 0.2s ease-out',
});

export const searchBarInput = (hasText) => (theme) => ({
  color: theme[hasText ? 'textLightPrimary' : 'textLightDisabled'],
  border: '1px solid transparent',
  width: '100%',
  cursor: 'text',
  fontSize: '16px',
  lineHeight: '19px',
  padding: '10px 42px',
  outline: 'none',
});

export const searchBarHistoryContainer = (numberOfItems, maxNumber) => (theme) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  color: theme.textLightSecondary,
  maxHeight: numberOfItems
    ? `${numberOfItems > maxNumber ? maxNumber * itemHeight - itemHeight / 2 : numberOfItems * itemHeight}px`
    : 0,
  overflowY: numberOfItems > maxNumber ? 'auto' : 'hidden',
  backgroundColor: 'white',
  borderTop: numberOfItems ? '1px solid #DDDDDD' : 'none',
  borderLeft: numberOfItems ? '1px solid transparent' : 'none',
  borderRight: numberOfItems ? '1px solid transparent' : 'none',
  boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
  transition: 'max-height 0.35s ease-out',
});

export const searchBarHistoryItem = (theme) => ({
  padding: '16px',
  color: theme.primary,
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: theme.secondaryLighter,
  },
  '&:focus': {
    outline: 'none',
  },
});

export const searchBarSearchIcon = {
  position: 'absolute',
  left: '9px',
  top: '9px',
};

export const searchBarDeleteIcon = {
  cursor: 'pointer',
  position: 'absolute',
  right: '9px',
  top: '9px',
};
