import { pick } from 'lodash-es';
import queryString from 'query-string';

export const addLessonBody = (body) => ({
  title: body?.title,
  sourceUrl: body?.sourceUrl,
  duration: body?.duration,
  courseId: body?.courseId,
  visible: body?.visible ?? false,
  isFree: body?.isFree ?? false,
});

export const editLessonsBody = (body) => ({
  title: body?.title,
  sourceUrl: body?.sourceUrl,
  duration: body?.duration,
  visible: body?.visible ?? false,
  isFree: body?.isFree ?? false,
});

export const editLessonsTranslationBody = (body) => ({
  title: body?.title,
  sourceUrl: body?.sourceUrl,
  duration: body?.duration,
});

export const addEditTextLessonBody = (body) => {
  const formData = new FormData();

  formData.append('courseId', body?.courseId);
  formData.append('visible', body?.visible ?? false);
  formData.append('isFree', body?.isFree ?? false);
  formData.append('title', body?.title);
  body?.lessonMaterial && formData.append('lessonMaterial', body.lessonMaterial);

  return formData;
};

export const addEditTextLessonTranslationBody = (body, isEdit) => {
  const formData = new FormData();

  !isEdit && formData.append('languageCode', body?.languageCode);
  formData.append('title', body?.title);
  body?.lessonTranslationMaterial && formData.append('lessonTranslationMaterial', body.lessonTranslationMaterial);

  return formData;
};

export const getAllLessonsParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
