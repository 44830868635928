import { Tabs, Tab } from '../../../components/UI';
import BadgesList from './Badges';
import ConditionsList from './Conditions';
import { tabContainer } from './styles';

const BadgesPage = () => (
  <Tabs startingRoute="/badges" tabsContentClass={tabContainer}>
    <Tab label="Badges " url="/all-badges" component={() => <BadgesList />} />
    <Tab label="Conditions" url="/all-conditions" component={() => <ConditionsList />} />
  </Tabs>
);

export default BadgesPage;
