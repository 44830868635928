import ColorBatch from '../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../components/Shared/TableComponents';

export const columns = [
  {
    name: 'Category id',
    value: 'id',
    sortable: true,
  },
  {
    name: 'Name',
    value: 'name',
    sortable: true,
  },
  {
    name: 'Visibility',
    value: 'visible',
    render: (row) => (
      <ColorBatch type={row.visible ? 'success' : 'error'}>{row.visible ? 'Visible' : 'Invisible'}</ColorBatch>
    ),
  },
  {
    name: 'View details',
    render: (row) => <DetailsLink row={row} url={`/categories/${row.id}`} />,
    width: 100,
  },
];

export const pages = (categoryId) => [
  { url: '/categories', label: 'Categories' },
  {
    label: categoryId ? 'Edit Category' : 'Add Category',
  },
];

export const pagesTranslation = (categoryId, hasTranslation) => [
  { url: '/categories', label: 'Categories' },
  { url: `/categories/${categoryId}`, label: 'Categorie Information' },
  {
    label: hasTranslation ? 'Edit Category Translation' : 'Add Category Translation',
  },
];
