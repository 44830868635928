import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Row, showApiError, Button } from '../../UI';
import { getAllVideos } from '../../../services/videos';
import VideosFilterBar from './VideosFilterBar';
import { loadMoreBtn } from './styles';
import { NoResults } from '../../UI/Table/NoResults';
import { LoadingVideos } from './LoadingVideos';
import { VideoCard } from './VideoCard';

const VideosList = (props) => {
  const { onSelect } = props;
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [folderId, setFolderId] = useState(0);
  const [moreVideos, setMoreVideos] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllVideos();
  }, []);

  const fetchAllVideos = async (params, concatVideos) => {
    const [res, err] = await getAllVideos(params);
    if (err) return showApiError(err);

    setPage(res?.page);

    res?.paging.next ? setMoreVideos(true) : setMoreVideos(false);

    if (concatVideos) setVideos((prev) => [...prev, ...(res?.data || [])]);
    else {
      setVideos(res?.data || []);
      setLoading(false);
    }
  };

  const loadMore = async () => {
    const nextPage = page + 1;
    const params = {
      page: nextPage,
      query,
      folderId,
    };

    setPage(nextPage);
    await fetchAllVideos(params, true);
  };

  const filterData = async (params) => {
    setQuery(params.query);
    setFolderId(params.folderId);
    setLoading(true);

    await fetchAllVideos(params);

    setLoading(false);
  };

  return (
    <Paper header={<VideosFilterBar onChange={filterData} disabled={loading} />}>
      {!loading ? (
        videos && videos.length ? (
          <>
            <Row gap={8}>
              {videos.map((video, ind) => (
                <VideoCard video={video} onSelect={onSelect} key={ind} />
              ))}
            </Row>
            {moreVideos && (
              <Button className={loadMoreBtn} large onClick={loadMore}>
                Load More
              </Button>
            )}
          </>
        ) : (
          <NoResults emptyMessage="There are no videos from this search!" />
        )
      ) : (
        <LoadingVideos />
      )}
    </Paper>
  );
};

VideosList.propTypes = {
  onSelect: PropTypes.func,
};

export default VideosList;
