export const container = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: 'auto',
};

export const content = {
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1rem',
  flexGrow: 1,
};

export const number = (theme) => ({
  color: theme.gray,
  fontWeight: 600,
  fontSize: '1.8rem',
});

export const description = (theme) => ({
  color: theme.grayLight,
  fontSize: '1.1rem',
  fontWeight: 400,
  marginTop: '0.55rem',
});

export const chart = {
  position: 'relative',
  width: '230px',
  height: '100px',

  '& > canvas': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
};
