import { pick } from 'lodash-es';
import queryString from 'query-string';
import avatar from '../../assets/images/default-avatar.png';

export const getExpertModel = (response) =>
  response && {
    ...response,
    picUrl: response.picUrl ?? avatar,
  };

export const addEditExpertsBody = (body) => ({
  firstName: body?.firstName,
  lastName: body?.lastName,
  description: body?.description,
  position: body?.position,
  picEncodedData: body?.picEncodedData,
});

export const editExpertsTranslationBody = (body) => ({
  firstName: body?.firstName,
  lastName: body?.lastName,
  description: body?.description,
  position: body?.position,
});

export const getAllExpertsParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
