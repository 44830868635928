import { pick, isNil } from 'lodash-es';
import queryString from 'query-string';
import badge from '../../assets/images/default-badge.jpeg';

export const getBadgeModel = (response) =>
  response && {
    ...response,
    picUrl: response.pictureUrl ?? badge,
  };

export const addEditBadgeBody = (body) => ({
  name: body?.name,
  visible: body?.visible ?? false,
  active: body?.active ?? false,
  picEncodedData: body?.picEncodedData,
  badgeConditionsIds: body?.badgeConditions.map((el) => el?.id),
});

export const getAllBadgesParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'desc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (!isNil(options.active)) {
    filterFields.push('active');
    filterValues.push(options.active);
  }

  if (!isNil(options.visible)) {
    filterFields.push('visible');
    filterValues.push(options.visible);
  }

  if (options.name?.length) {
    filterFields.push('name');
    filterValues.push(options.name);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};
