import { pick } from 'lodash-es';
import queryString from 'query-string';

export const uploadCourseMaterialsBody = (courseId, body) => {
  const formData = new FormData();

  formData.append('courseId', courseId);
  formData.append('visible', true);
  body.forEach((el, i) => {
    formData.append(`name[${i}]`, el.name);
    formData.append('materials', el);
  });

  return formData;
};

export const postMaterialTranslationBody = (body) => {
  const formData = new FormData();

  formData.append('languageCode', body.languageCode);
  formData.append('name', body.name);
  formData.append('material', body.material);

  return formData;
};

export const getAllMaterialsParams = (options) => {
  // const defaultSort = {
  //   sortBy: 'id',
  //   sortDirection: 'desc',
  // };

  // const mapSort = sort => ({
  //   sortBy: sort.sortField,
  //   sortDirection: sort.sortOrder,
  // });

  const filterFields = [];
  const filterValues = [];

  if (options.searchCriteria && options.searchInput?.length) {
    filterFields.push(options.searchCriteria);
    filterValues.push(options.searchInput);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      // ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};

export const editMaterialBody = (body) => {
  const formData = new FormData();
  formData.append('name', body.name);
  formData.append('courseId', body.courseId);
  body?.material && formData.append('material', body.material);
  formData.append('visible', body.visible);

  return formData;
};

export const updateMaterialTranslationBody = (body) => {
  const formData = new FormData();
  formData.append('name', body.name);
  body?.material && formData.append('material', body.material);

  return formData;
};
