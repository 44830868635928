import { Row, Col, Skeleton } from '../../UI';

export const LoadingVideos = () => (
  <Row gap={8}>
    <Col xl={4} lg={4} sm={6} xs={12}>
      <Skeleton height={270} />
    </Col>
    <Col xl={4} lg={4} sm={6} xs={12}>
      <Skeleton height={270} />
    </Col>
    <Col xl={4} lg={4} sm={6} xs={12}>
      <Skeleton height={270} />
    </Col>
  </Row>
);
