import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash-es';
import { Row, Col, Dropdown, showApiError } from '../../UI';
import SearchInput from '../../Shared/SearchInput';
import { getAllCourses } from '../../../services/courses';
import { optionsCourseProgress, optionsAccountSearchBy } from './config';

const noResultsMessages = {
  searchInput: 'Accounts',
  courseProgress: 'Course Progress',
  courseId: 'Course',
};

const TableFilterBarAcademyAccounts = ({ getData, tableOptions, setEmptyMessage }) => {
  const [courses, setCourses] = useState([]);
  const params = useRef({
    searchCriteria: optionsAccountSearchBy[0].value,
    searchInput: null,
    courseProgress: null,
    courseId: null
  });

  useEffect(() => {
    getData(params.current);
    fetchCourses();
  }, []);

  const fetchResults = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };

    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  const fetchCourses = async () => {
    const [res, err] = await getAllCourses();
    if (err) return showApiError(err);

    setCourses(res?.data ?? []);
  };

  return (
    <Row horizontalGap={16}>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          noClear
          value={optionsAccountSearchBy[0]}
          options={optionsAccountSearchBy}
          onChange={({ value: searchCriteria }) => {
            params.current = {
              ...params.current,
              searchCriteria,
            };

            params.current?.searchInput?.length && fetchResults();
          }}
        />
      </Col>
      <Col xl={6} lg={6} sm={12} xs={12}>
        <SearchInput
          placeholder="Search by username or external account id..."
          onChange={(searchInput) => fetchResults({ searchInput })}
        />
      </Col>

      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          placeholder="Course Progress"
          options={optionsCourseProgress}
          onChange={(val) => fetchResults({ courseProgress: val?.value })}
        />
      </Col>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          placeholder="Courses"
          options={courses}
          displayKey="name"
          uniqueKey="id"
          onChange={(val) => fetchResults({ courseId: val?.id })}
        />
      </Col>
    </Row>
  );
};

TableFilterBarAcademyAccounts.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
};

export default TableFilterBarAcademyAccounts;
