import { pick, isNil } from 'lodash-es';
import queryString from 'query-string';
import avatar from '../../assets/images/package.png';

export const getPackageModel = (response) =>
  response && {
    ...response,
    pictureUrl: response.pictureUrl ?? avatar,
    picThumbnailUrl: response.picThumbnailUrl ?? avatar,
  };

export const getAllPackagesParams = (options) => {
  const defaultSort = {
    sortField: 'id',
    sortOrder: 'desc',
  };

  const mapSort = (sort) => ({
    sortField: sort.sortField === 'catalogueItemId' ? 'id' : sort.sortField,
    sortOrder: sort.sortOrder,
  });

  const filterField = [];
  const filterValue = [];

  if (!isNil(options.active)) {
    filterField.push('active');
    filterValue.push(options.active);
  }

  if (!isNil(options.visible)) {
    filterField.push('visible');
    filterValue.push(options.visible);
  }

  if (!isNil(options.type)) {
    filterField.push('type');
    filterValue.push(options.type);
  }

  if (options.name?.length) {
    filterField.push('name');
    filterValue.push(options.name);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(filterField.length && filterValue.length && { filterField, filterValue }),
    },
    { arrayFormat: 'index' },
  );
};
