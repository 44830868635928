export const container = {
  padding: 0,
};

export const header = {
  padding: '0.5rem 0 1.1rem 0',
  marginBottom: '0.5rem',
};

export const titleContainer = (theme) => ({
  fontSize: '1.3rem',
  fontWeight: 500,
  marginBottom: '0',
  color: theme.gray,
});

export const description = (theme) => ({
  display: 'inline-block',
  marginTop: '0.2rem',
  color: theme.grayLight,
});

export const content = {
  display: 'flex',
  alignItems: 'center',
};

export const legends = {
  paddingLeft: '2rem',
  flexGrow: 1,
};

export const legend = {
  display: 'flex',
  alignItems: 'center',

  '&:not(:first-child):not(:last-child)': {
    padding: '0.5rem 0',
  },
};

export const bullet = {
  width: '1.5rem',
  height: '0.45rem',
  borderRadius: '1.1rem',
};

export const stats = (theme) => ({
  color: theme.gray,
  fontWeight: 500,
  flex: '1',
  paddingLeft: '1rem',
});

export const chart = {
  position: 'relative',

  '& > canvas': {
    position: 'relative',
    zIndex: '1',
  },
};

export const stat = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: '0',
  right: '0',
  bottom: '0',
  top: '0',
  fontSize: '2.2rem',
  fontWeight: '500',
  color: theme.grayDark,
  opacity: '0.7',
});
