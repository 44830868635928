export const inputDescriptionContainer = {
  height: 220,
  ' & > textarea': {
    height: 220,
  },
};

export const packagesContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: 16,
  '& span, & strong': {
    display: 'inline-block',
    marginRight: 6,
  },
};
