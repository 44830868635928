import { loginWithPassword } from '@oneecosystem/authenticate';
import { Paper, showError, Form, FormInput, validate } from '../../../components/UI';
import { container, formTitleContainer, loginTitle, title, forgotStyles } from '../styles';
import { isArray, isPlainObject } from 'lodash-es';

const Login = () => {
  const handleLogin = async data => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map(el => el[0]));
    //     Error from sts identity                  Default behavior
    err && (isArray(err) ? err.forEach(showError) : showError(err));
  };

  return (
    <div css={container}>
      <div css={formTitleContainer}>
        <Paper
          header={
            <div css={loginTitle}>
              <h1 css={title}>One Academy Admin</h1>
            </div>
          }>
          <Form onSubmit={handleLogin} submitButton={{ children: 'Sign In', large: true }}>
            <FormInput
              required
              id="email"
              label="Email"
              type="email"
              validate={val => validate(val, ['required', 'email'])}
            />
            <FormInput
              required
              id="password"
              label="Password"
              type="password"
              validate={val => validate(val, ['required', 'password'])}
            />
          </Form>
          <div css={forgotStyles}>
            <a href={`${identityProvider.identity}/Account/ForgotPassword`}>Forgot your password?</a>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
