import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { colorStatus } from './styles';

const ColorBatch = (props) => {
  const { children, className } = props;

  return (
    <span css={colorStatus(props)} {...(isString(className) && { className })}>
      {children}
    </span>
  );
};

ColorBatch.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default ColorBatch;
