import { type as walletType } from '../../../enums/wallets';
import { currencyByType } from '../../../utils/displayData';
import theme from '../../../styles/theme.json';

export const dataQuickStatsChart = ({ coin, cash }) => [
  {
    id: 'quick-stat-1',
    value: 570,
    desc: 'Total Sales',
    data: [10, 14, 18, 11, 9, 12, 14, 17, 18, 14],
    color: theme.info,
    border: 3,
  },
  {
    id: 'quick-stat-2',
    value: '234+',
    desc: 'Transactions',
    data: [12, 12, 18, 11, 15, 12, 13, 16, 11, 18],
    color: theme.success,
    border: 3,
  },
  {
    id: 'quick-stat-3',
    value: currencyByType(coin?.total, walletType.ONE),
    desc: 'Total ONE Transactions',
    data: coin?.transactions,
    color: theme.gray,
    labels: coin?.transactions,
    border: 3,
  },
  {
    id: 'quick-stat-4',
    value: currencyByType(cash?.total, walletType.CASH),
    desc: 'Total CASH Transactions',
    data: cash?.transactions,
    color: theme.infoDark,
    labels: cash?.transactions,
    border: 3,
  },
];
