export const datePickerContainer =
  ({ label, showClear = true }) =>
  (theme) =>
    [
      {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',

        '& .clearIcon': {
          position: 'absolute',
          right: -30,
          bottom: 0,
          border: `1px solid ${theme.border}`,
          borderTopRightRadius: 17,
          borderBottomRightRadius: 17,
          borderLeft: 'none',
          display: 'flex',
          padding: '5px 5px 5px 20px',
        },
      },
      label && {
        marginBottom: 7,
      },
      showClear && {
        marginRight: 32,
      },
    ];

export const labelStyles = {
  paddingLeft: 8,
  fontWeight: 600,
  fontSize: 12,
};
