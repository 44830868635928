import { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { Paper } from '../../UI';
import { chartOrderStatisticsChart } from '../config';
import {
  container,
  containerHeader,
  content,
  item,
  info,
  description,
  value,
  progress,
  progressSm,
  stat,
  chart,
} from './styles';

const OrderStatisticsChart = () => {
  const ref = useRef();

  useEffect(() => {
    const createdChart = new Chart(ref.current, chartOrderStatisticsChart);

    return () => {
      createdChart.destroy();
    };
  });

  return (
    <Paper
      header={
        <div css={containerHeader}>
          <h3>Order Statistics</h3>
        </div>
      }>
      <div css={container}>
        <div css={content}>
          <div css={item}>
            <div css={info}>
              <span css={description}>Annual Taxes EMS</span>
              <span css={value}>$400,000</span>
            </div>
            <div css={info}>
              <span css={description}>Finance Review Date</span>
              <span css={value}>July 24,2019</span>
            </div>
          </div>
          <div css={item}>
            <div css={info}>
              <span css={description}>Average Revenue</span>
              <span css={value}>$60M</span>
            </div>
            <div css={info}>
              <span css={description}>Revenue Margin</span>
              <div css={progress}>
                <progress max="100" value="40" css={progressSm} />
                <span css={stat}>40%</span>
              </div>
            </div>
          </div>
        </div>
        <div css={chart} style={{ height: '250px' }}>
          <canvas ref={ref} width={683} height={312} id="kt_chart_order_statistics" />
        </div>
      </div>
    </Paper>
  );
};

export default OrderStatisticsChart;
