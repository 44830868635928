import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row } from '../Grid';
import NavLinks from './NavLinks';
import { useAuth } from '../Authenticate';
import { ReactComponent as Logo } from '../../../assets/images/academy-logo.svg';
import { navBarContainer } from './styles';

const NavBar = ({ logoLink, label }) => {
  const { isAuthenticated } = useAuth();

  return (
    <div css={navBarContainer}>
      <Row justify="space-between" align="center" noWrap>
        {isAuthenticated ? (
          <h3>{label}</h3>
        ) : (
          <Link to={logoLink}>
            <Logo />
          </Link>
        )}
        <NavLinks />
      </Row>
    </div>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
  label: PropTypes.string,
};
export default NavBar;
