import { keyframes } from '@emotion/react';

// Form styles start
export const formContainer = {
  width: '100%',
};

export const formFieldsContainer = {
  marginBottom: 24,
};
// Form styles end

// Input styles start (base one)
export const inputContainer = (isHorizontal) => ({
  display: 'flex',
  flexDirection: isHorizontal ? 'row' : 'column',
  alignItems: 'flex-start',
  justifyContent: isHorizontal ? 'space-between' : 'flex-start',
  position: 'relative',
  width: '100%',
  height: isHorizontal ? '48px' : '115px',
  outline: 'none',
});
// Input styles start (base one)
export const wysiwygContainer = {
  position: 'relative',
  width: '100%',
  outline: 'none',
};

export const horizontalContainer = {
  flex: 1,
  position: 'relative',
};

export const inputLabel = (isHorizontal) => (theme) => ({
  fontWeight: 600,
  opacity: 1,
  color: theme.textLightSecondary,
  margin: `
      ${isHorizontal ? '12px' : 0}
      ${isHorizontal ? '24px' : 0}
      ${isHorizontal ? 0 : '8px'}
      0`,
});

export const inputField = (hasError, hasText) => (theme) =>
  [
    {
      fontWeight: 400,
      lineHeight: 'normal',
    },
    hasError
      ? {
          border: `1px solid ${theme.error}`,
        }
      : hasText
      ? {
          border: `1px solid ${theme.success}`,
          borderRadius: 3,
        }
      : {},
  ];

const helpTextAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 24px;
    opacity: 1;
  }
`;

export const inputHelpContainer = (hasError) => (theme) => ({
  fontSize: '13px',
  lineHeight: 1.54,
  color: hasError ? theme.errorDark : theme.successDark,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 6,
  fontWeight: 400,
  animation: `${helpTextAnimation} 400ms ease-out`,
});

export const inputHelpIcon = (hasError) => (theme) => ({
  padding: hasError ? 4 : 6,
  borderRadius: '50%',
  marginRight: 6,
  backgroundColor: hasError ? theme.errorLight : theme.successLight,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > img': {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
});

export const inputFieldRightIcon = (isPasswordType) => ({
  position: 'absolute',
  right: isPasswordType ? 38 : 8,
  bottom: 26,
});

export const inputFieldVisibilityIcon = {
  position: 'absolute',
  right: 8,
  bottom: 26,
};
// Input styles end (base one)

// Dropdown styles start
export const dropdownInput = (hasError) => (theme) => hasError ? { borderColor: theme.errorDark } : '';
// DropDown styles end

// Dynamic dropdown styles start
export const dynamicDropdownField = (hasError) => (theme) =>
  [
    {
      paddingLeft: 9,
    },
    hasError
      ? {
          borderColor: theme.errorDark,
        }
      : {},
  ];

export const dynamicDropdownFieldContainer = {
  outline: 'none',
};

export const requiredText = {
  fontSize: '11px',
  textTransform: 'capitalize',
};

export const dynamicSwitchButtonFieldContainer = {
  outline: 'none',
  paddingTop: 6,
};

export const rightIconsContainer = {
  display: 'flex',
};

// Dynamic dropdown styles end

export const containerCheckBox = {
  fontWeight: 600,
  marginBottom: 16,
};

export const countryContainer = {
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white',
  },
};

export const colorPickerContainer = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const colorPickerWrap = {
  '& > span': {
    height: 47,
    verticalAlign: 'middle',
  },
};

export const videoPickerContainer = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const videoPickerWrap = {
  'div[role=button]': {
    height: 47,
    verticalAlign: 'middle',
  },
};
