import { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Paper, Icon, Button, Form, FormDropdown, Input, showApiError, showSuccess } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import DeleteConfirmationModal from '../../Shared/DeleteConfirmationModal';
import { getAllPackages } from '../../../services/packages';
import { updateCoursePackages } from '../../../services/courses';
import { getAllCatalogueItems } from '../../../services/catalogue-items';
import {
  wrap,
  list,
  body,
  packagesContainer,
  packagesWrap,
  inputWrap,
  saveBtnWrap,
  itemLink,
  deleteButton,
  rightCol,
} from './styles';

const CoursePackages = () => {
  const { courseId } = useParams();
  const [packages, setPackages] = useState([]);
  const [selected, setSelected] = useState([]);
  const allPackagesRef = useRef([]);
  const modalRef = useRef();
  const itemIdRef = useRef();

  useEffect(() => {
    fetchPackages(true, true);
  }, []);

  const fetchPackages = async (getPackages, initPackagesList) => {
    let allPackages = allPackagesRef.current;

    if (getPackages) {
      const [res, err] = await getAllPackages();
      if (err) return showApiError(err);
      allPackages = res?.catalogueItems;
    }

    const [res2, err2] = await getAllCatalogueItems({
      courseId,
      pageIndex: 0,
      pageSize: 100,
    });

    if (err2) return showApiError(err2);

    const allCatalogueItems = res2?.data;

    setSelected(
      allCatalogueItems?.map((el) => ({
        ...el,
        limit: parseFloat(100 - el?.totalPercentageDistributed + el?.tokensPercentage).toFixed(2),
        packageExternalData: allPackages?.find((item) => item?.catalogueItemId === el?.catalogueItemId),
      })),
    );
    if (initPackagesList) {
      const courseCatalogueItemIds = allCatalogueItems?.map((el) => el?.catalogueItemId);
      const notSelectedPackages = allPackages?.filter((el) => !courseCatalogueItemIds.includes(el?.catalogueItemId));

      setPackages(notSelectedPackages ?? []);
      allPackagesRef.current = allPackages;
    }
  };

  const packagesListOptions = packages?.map((el) => ({
    ...el,
    render: () => (
      <div css={packagesContainer}>
        <div>
          <strong>{el.catalogueItemId}</strong>
          <span>{el.name}</span>
        </div>
        <div>
          <ColorBatch type={el.active ? 'success' : 'error'}>{el.active ? 'Active' : 'Inactive'}</ColorBatch>
          <ColorBatch type={el.visible ? 'success' : 'error'}>{el.visible ? 'Visible' : 'Invisible'}</ColorBatch>
        </div>
      </div>
    ),
  }));

  const handleInputChange = (value, id, limit) => {
    if (parseFloat(value) > limit) return;

    const doubleRegEx = /(^$|^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/.test(value);
    const dotsLength = ((value.length && value.match(/\./g)) || []).length;
    const valueValidation = doubleRegEx || value === '100' || (value[value.length - 1] === '.' && dotsLength < 2);

    valueValidation &&
      setSelected((prev) =>
        prev?.map((el) =>
          el?.catalogueItemId !== id
            ? el
            : {
                ...el,
                tokensPercentage: value,
              },
        ),
      );
  };

  const handleSave = async () => {
    const [, error] = await updateCoursePackages(courseId, selected);

    if (error) return showApiError(error);

    showSuccess({
      title: 'Save packages',
      message: 'Successfully save packages.',
    });

    fetchPackages();
  };

  const handleAddPackage = async (formData) => {
    if (isEmpty(formData)) return;
    const [, error] = await updateCoursePackages(courseId, [...(formData?.catalogueItems ?? []), ...selected]);

    if (error) return showApiError(error);

    fetchPackages(false, true);
  };

  const handleRemoveModal = (id) => {
    itemIdRef.current = id;
    modalRef.current.open();
  };

  const handleRemove = async () => {
    const itemId = itemIdRef.current;

    if (!itemId) return;

    const filteredSelected = selected.filter((el) => el?.catalogueItemId !== itemId);

    const [, error] = await updateCoursePackages(courseId, filteredSelected);

    if (error) return showApiError(error);

    showSuccess({
      title: 'Save packages',
      message: 'Successfully save packages.',
    });

    fetchPackages(false, true);
  };

  return (
    <>
      <Paper header="Course packages" css={wrap}>
        <div css={list}>
          <h3>Added packages</h3>
          {selected?.map((el) => (
            <div key={el?.catalogueItemId} css={packagesWrap}>
              <Link
                css={itemLink}
                to={{
                  pathname: `/courses/${courseId}/package`,
                  state: { packageData: el?.packageExternalData },
                }}>
                <p>
                  {`#${el?.catalogueItemId} ${el?.packageExternalData?.name}`}{' '}
                  <Icon iconName="fa fa-arrow-circle-right" />
                </p>
              </Link>
              <div css={rightCol}>
                <div css={inputWrap}>
                  <Input
                    value={String(el?.tokensPercentage ?? 0)}
                    onChange={({ target: { value } }) => handleInputChange(value, el?.catalogueItemId, el?.limit)}
                    onBlur={({ target: { value } }) =>
                      handleInputChange(
                        value[value?.length - 1] === '.'
                          ? parseFloat(value.slice(0, -1))
                          : value?.length
                          ? parseFloat(value)
                          : 0,
                        el?.catalogueItemId,
                        el?.limit,
                      )
                    }
                  />
                  <div className="percent">
                    <span>%</span>
                  </div>
                  <div className="max">
                    <small>
                      <b>Max: {el?.limit}%</b>
                    </small>
                  </div>
                </div>
                <Button
                  leftIcon={{
                    material: true,
                    color: 'red',
                    iconName: 'delete',
                  }}
                  outline
                  small
                  css={deleteButton}
                  onClick={() => handleRemoveModal(el?.catalogueItemId)}
                />
              </div>
            </div>
          ))}
          <div css={saveBtnWrap}>
            <Button large onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>

        <div css={body}>
          <h3>Add packages</h3>
          <Form
            onSubmit={handleAddPackage}
            submitButton={{
              children: 'Add Packages',
            }}>
            <FormDropdown
              id="catalogueItems"
              label="Packages"
              placeholder="Select packages..."
              displayKey="name"
              uniqueKey="catalogueItemId"
              options={packagesListOptions}
              noClear
              withSearch
              multiSelect
              noValidation
            />
          </Form>
        </div>
      </Paper>
      <DeleteConfirmationModal
        ref={modalRef}
        onDelete={handleRemove}
        onClose={() => {
          itemIdRef.current = null;
        }}
      />
    </>
  );
};

export default CoursePackages;
