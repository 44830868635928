import PropTypes from 'prop-types';
import TableFilterBarAccountCourses from '../../../../components/AcademyAccounts/TableFilterBarAccountCourses';
import { Paper, Table } from '../../../../components/UI';
import { getAcademyAccountCourses } from '../../../../services/academy-account';
import { columns } from './config';

const mapToTableResults = (apiResult = []) => {
  const [res, err] = apiResult;
  const tableResult = (res.accountCourses || []).map((data) => {
    const { course, courseId, completedOn, completedLessonProgress } = data;
    const completedLessonProgressLabel = completedOn ? '100 %' : `${Math.floor(Number(completedLessonProgress))} %`;

    return {
      courseId,
      courseName: course.name,
      courseStatus: course.status,
      completedLessonProgress: Number(completedLessonProgress),
      completedLessonProgressLabel,
      completedOn,
    };
  });

  return [{ data: tableResult }, err];
};

const AcademyAccountCourses = ({ accountId }) => {
	const fetchAccountCourses = () => async tableOptions => {
		const options = { ...tableOptions };

		const [res, err] = await getAcademyAccountCourses(accountId, options);

    if (err) return [null, err];

    return mapToTableResults([res, err]);
  };

  return (
    <Paper>
      <Table
        getDataKey="data"
        getDataMethod={fetchAccountCourses()}
        emptyMessage="There are no courses."
        columns={columns}
        filterBar={TableFilterBarAccountCourses}
        pageSize={10}
      />
    </Paper>
  );
};

AcademyAccountCourses.propTypes = {
	accountId: PropTypes.string
};

export default AcademyAccountCourses;
