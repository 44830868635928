export const wrap = {
  marginBottom: 32,
  '& > h3': {
    marginBottom: 16,
  },
};

export const lessonWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 16,
  border: `1px solid ${theme.border}`,
  padding: 10,
  borderRadius: 8,
});

export const contentWrap = {
  marginRight: 16,
  marginLeft: 16,
  textAlign: 'center',
  '& > h3': {
    marginBottom: 6,
  },
  '& > p': {
    marginBottom: 4,
  },
};

export const pdfWrapper = (theme) => ({
  width: 200,
  height: 125,
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.grayLightest,
  '& > i': {
    color: theme.primary,
    fontSize: 40,
  },
});

export const videoWrapper = (theme) => ({
  minWidth: 200,
  backgroundColor: theme.black,
});

export const videoInner = {
  padding: '31.25%',
  position: 'relative',
};

export const videoStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 0,
};

export const noFilesWrap = (theme) => ({
  backgroundColor: theme.grayLightest,
  marginBottom: 24,
  padding: 24,
  textAlign: 'center',
  '& > p': {
    fontWeight: 600,
  },
});

export const lessonFormWrap = (theme) => ({
  marginBottom: 24,
  border: `1px solid ${theme.border}`,
  padding: '24px 16px',
});

export const loadMoreButton = {
  marginTop: 16,
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};
