import { isUndefined, isObject, isEmpty, isPlainObject } from 'lodash-es';

export const getNestedProperty = (object, properties) => properties?.reduce((xs, x) => xs && xs[x], object);

export const setNestedValue = (object, properties, value) => {
  const newObj = { ...object };
  if (properties.length === 1) {
    newObj[properties.pop()] = value;
    return newObj;
  }
  const currentProperty = properties.shift();
  if (isUndefined(object[currentProperty]) || !isObject(object[currentProperty])) newObj[currentProperty] = {};
  newObj[currentProperty] = setNestedValue(newObj[currentProperty], properties, value);
  return newObj;
};

export const deleteNestedValue = (obj, properties) => {
  const newObj = { ...obj };
  if (properties.length === 1) {
    delete newObj[properties.pop()];
    return newObj;
  }
  const curProperty = properties.shift();
  newObj[curProperty] = deleteNestedValue(newObj[curProperty], properties);
  if (isEmpty(newObj[curProperty])) delete newObj[curProperty];
  return newObj;
};

export const flatObjectDeep = (obj, deepLevel) => {
  if (Object.keys(obj).every((el) => !isPlainObject(obj[el]))) return obj;

  const flat = (res, key, val, pre, depth) => {
    const prefix = [pre, key].filter(Boolean).join('.');
    return isPlainObject(val) && depth !== 1
      ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix, depth - 1), res)
      : Object.assign(res, { [prefix]: val });
  };
  return Object.keys(obj).reduce((prev, curr) => flat(prev, curr, obj[curr], '', deepLevel), {});
};
