import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash-es';
import { Row, Col, Dropdown } from '../../UI';
import SearchInput from '../../Shared/SearchInput';
import { optionsLanguagesSearchBy, optionsStatus, optionsVisibility } from './config';

const noResultsMessages = {
  searchInput: 'Language',
  active: 'Status',
  isDefault: 'Default',
};

const TableFilterBarLanguages = ({ getData, tableOptions, setEmptyMessage }) => {
  const params = useRef({
    searchCriteria: optionsLanguagesSearchBy[0].value,
    searchInput: null,
    active: null,
    isDefault: null,
  });

  useEffect(() => {
    getData();
  }, []);

  const fetchResults = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };

    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  return (
    <Row horizontalGap={16}>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          noClear
          value={optionsLanguagesSearchBy[0]}
          options={optionsLanguagesSearchBy}
          onChange={({ value: searchCriteria }) => {
            params.current = {
              ...params.current,
              searchCriteria,
            };

            params.current?.searchInput?.length && fetchResults();
          }}
        />
      </Col>
      <Col xl={6} lg={3} sm={12} xs={12}>
        <SearchInput
          placeholder="Search by name or code..."
          onChange={(searchInput) => fetchResults({ searchInput })}
        />
      </Col>

      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          placeholder="Status"
          options={optionsStatus}
          onChange={(val) => fetchResults({ active: val?.value })}
        />
      </Col>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          placeholder="Default"
          options={optionsVisibility}
          onChange={(val) => fetchResults({ isDefault: val?.value })}
        />
      </Col>
    </Row>
  );
};

TableFilterBarLanguages.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
};

export default TableFilterBarLanguages;
