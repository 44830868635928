import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Paper, Form, FormInput, WysiwygInput, Breadcrumbs, showSuccess, showApiError } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import UploadImage from '../../../components/Shared/UploadImage';
import LanguageSelect from '../../../components/Shared/LanguageSelect';
import { postNewExpert, getExpertById, updateExpert } from '../../../services/experts';
import { pages } from './config';
import avatar from '../../../assets/images/default-avatar.png';
import { wrapper, backContainer, paperContain } from './styles';

const AddEditExperts = () => {
  const history = useHistory();
  const { expertId } = useParams();
  const [expertData, setExpertData] = useState({});

  useEffect(() => {
    expertId && fetchExpertData();
  }, [expertId]);

  const fetchExpertData = async () => {
    const [res, err] = await getExpertById(expertId);
    err ? showApiError(err) : setExpertData(res);
  };

  const handleSubmit = async (formData) => {
    const picEncodedData = formData?.picUrl?.picThumbnail ?? '';
    const [, error] = await (expertId
      ? updateExpert(expertId, {
          ...expertData,
          ...formData,
          picEncodedData,
        })
      : postNewExpert({ ...formData, picEncodedData }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${expertId ? 'Edited' : 'Create'} expert`,
      message: `Successfully ${expertId ? 'edited' : 'create'} expert.`,
    });

    history.push('/experts');
  };

  const handleLanguageChange = (code) => {
    history.push(`/experts/${expertId}/${code}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pages(expertId)} />
      <BackButton css={backContainer} title={expertId ? 'Edit Expert' : 'Add Expert'} />
      {expertId && <LanguageSelect onChange={handleLanguageChange} />}
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: expertId ? 'Edit Expert' : 'Add Expert',
          }}
          values={expertData}>
          <UploadImage id="picUrl" defaultValue={avatar} />
          <FormInput id="firstName" label="First name" required />
          <FormInput id="lastName" label="Last name" required />
          <FormInput id="position" label="Position" required />
          <WysiwygInput id="description" type="textarea" label="Description" placeholder="Description" />
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditExperts;
