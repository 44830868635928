export const wrap = {
  marginBottom: 32,
  '& > h3': {
    marginBottom: 16,
  },
};

export const materialWrap = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 12,
  '& > p': {
    fontWeight: 600,
    marginRight: 12,
    marginLeft: 8,
  },
};

export const noFilesWrap = (theme) => ({
  backgroundColor: theme.grayLightest,
  marginBottom: 24,
  padding: 24,
  textAlign: 'center',
  '& > p': {
    fontWeight: 600,
  },
});

export const materialFormWrap = (theme) => ({
  marginTop: 24,
  paddingTop: 24,
  borderTop: `1px solid ${theme.border}`,
});

export const formBtnsWrap = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 24,
  marginBottom: 24,
  borderBottom: `1px solid ${theme.border}`,
});

export const uploadWrap = {
  marginBottom: 24,
  '& > h3': {
    marginBottom: 16,
  },
};

export const uploadBtnWrap = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
  '& > p': {
    marginLeft: 8,
    fontWeight: 600,
  },
};

export const uploadInput = {
  display: 'none',
};

export const fileSelectedWrap = {
  marginBottom: 16,
  '& > p': {
    '& > a': {
      textDecoration: 'underline',
    },
  },
};

export const loadMoreButton = {
  marginTop: 16,
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};
