export const questionType = {
  multi: {
    text: 'Multiple',
    color: 'warning',
  },
  single: {
    text: 'Single',
    color: 'orange',
  },
};
