export const wrap = {
  marginBottom: 32,
  '& > h3': {
    marginBottom: 16,
  },
};

export const quizWrap = (selected) => (theme) => ({
  marginBottom: selected ? 0 : 16,
  border: `1px solid ${theme.borderLight}`,
  padding: '8px 16px',
});

export const quizTitle = {
  marginLeft: 6,
  marginRight: 6,
};

export const quizEditForm = {
  display: 'flex',
  flexDirection: 'row',
  height: 100,
  '& > div[role=presentation]': {
    width: '100%',
  },
};

export const formBtnsEditQuizWrap = {
  display: 'flex',
  alignItems: 'center',
};

export const formBtnsEditQuizCancel = {
  marginLeft: 8,
};

export const noFilesWrap = (theme) => ({
  backgroundColor: theme.grayLightest,
  marginBottom: 24,
  padding: 24,
  textAlign: 'center',
  '& > p': {
    fontWeight: 600,
  },
});

export const actionsButtonsWrap = {
  marginLeft: -4,
  marginRight: -4,
};

export const iconButton = {
  marginLeft: 4,
  marginRight: 4,
  ' & > i': {
    margin: 0,
  },
};

export const loadMoreButton = {
  marginTop: 16,
  '& > div[role=button]': {
    width: '100%',
    justifyContent: 'center',
  },
};
