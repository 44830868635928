import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Paper, Form, FormInput, Breadcrumbs, showSuccess, showApiError } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import LanguageSelect from '../../../components/Shared/LanguageSelect';
import {
  getCategoryByIdTranslation,
  postNewCategoryTranslation,
  updateCategoryTranslations,
} from '../../../services/categories';
import { pagesTranslation } from './config';
import { wrapper, backContainer, paperContain } from './styles';

const AddEditCategoryTranslations = () => {
  const history = useHistory();
  const { categoryId, languageCode } = useParams();
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    fetchCategoryData();
  }, [categoryId, languageCode]);

  const fetchCategoryData = async () => {
    const [res, err] = await getCategoryByIdTranslation(categoryId, languageCode);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setCategoryData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(categoryData)
      ? updateCategoryTranslations(categoryId, languageCode, formData)
      : postNewCategoryTranslation(categoryId, { ...formData, languageCode }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${!isEmpty(categoryData) ? 'Edited' : 'Create'} category translation`,
      message: `Successfully ${!isEmpty(categoryData) ? 'edited' : 'create'} category translation.`,
    });

    setCategoryData((prev) => ({ ...prev, ...formData }));
  };

  const handleLanguageChange = (code) => {
    history.push(`/categories/${categoryId}/${code}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pagesTranslation(categoryId, !isEmpty(categoryData))} />
      <BackButton
        linkTo="/categories"
        css={backContainer}
        title={!isEmpty(categoryData) ? 'Edit Category Translations' : 'Add Category Translations'}
      />
      <LanguageSelect onChange={handleLanguageChange} />
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: !isEmpty(categoryData) ? 'Edit Category Translations' : 'Add Category Translations',
          }}
          values={categoryData}>
          <FormInput id="name" label="Category name" required />
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditCategoryTranslations;
