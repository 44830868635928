export const navBarContainer = (theme) => ({
  backgroundColor: theme.white,
  color: theme.textLightPrimary,
  position: 'sticky',
  zIndex: 900,
  padding: '16px 40px',
  width: '100%',
  top: 0,
  borderBottom: `1px solid ${theme.borderLight}`,
});

export const rightSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',

  '& > *': {
    marginRight: 5,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
};

export const navLink = (theme) => ({
  color: theme.whiteGray,
  border: 'none',
  marginRight: 50,
  textTransform: 'uppercase',
  '&.active': {
    borderBottom: `1px solid ${theme.whiteGray}`,
    fontWeight: 'bold',
  },
});
