export const type = {
  ONE: 'coin',
  // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
  // COINSAFE: 'coinsafe',
  CASH: 'cash',
  TRADING: 'trading',
  TOKEN: 'token',
  GAS: 'gas',
  RESERVECASH: 'reservecash',
};

export const currencyType = {
  EURO: '€',
  EURO_LETTERS: 'EUR',
  TKN: 'TKN',
  ONE: 'ONE',
};

export const withdrawalMethodType = {
  BTC: 'btc',
  USDT: 'usdt',
};
