import * as tus from 'tus-js-client';
import { postReq, putReq, getReq } from './axios/makeRequest';
import { getAllVideosParams } from './models/videos';

const baseUrl = `${apiUrls.oneAcademy}/videos-management`;

export const createVimeoVideo = async (file) => {
  const [res, err] = await postReq(`${baseUrl}/new`, {
    fileSize: file?.size,
    fileName: file?.name,
  });
  return [res, err];
};

export const uploadToVimeo = async (file) => {
  const [res, err] = await createVimeoVideo(file);

  if (err) return [res, err];

  return new Promise((resolve) => {
    const upload = new tus.Upload(file, {
      uploadUrl: res?.upload?.upload_link,
      headers: { Accept: 'application/vnd.vimeo.*+json;version=3.4' },
      retryDelays: [0, 1000, 3000, 5000, 10000, 20000],
      uploadSize: file.size,
      chunkSize: 130000000,
      //   onProgress: (loaded, total) => {
      //     onProgress(Math.round((loaded / total) * 100));
      //   },
      onError: (newErr) => resolve([null, newErr]),
      onSuccess: () => resolve([res, null]),
    });
    upload.start();
  });
};

export const setVideoWhitelist = (videoId) => putReq(`${baseUrl}/${videoId}/whitelist`);

export const getAllVideos = (options = {}) => {
  const params = getAllVideosParams(options);
  return getReq(`${baseUrl}/list?${params}`);
};

export const getSubfoldersList = async parentFolderId => {
  let url = `${baseUrl}/subfolders/list`;
  if (parentFolderId)
    url = `${url}?parentFolderId=${parentFolderId}`;


  return getReq(url);
};

export const moveVideoToFolder = async (videoId, folderId) => {
  const url = `${baseUrl}/${videoId}/change-location`;
  const body = { folderId };

  return putReq(url, body);
};