import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Row, Col, Form, FormInput, FormParagraph, CheckBoxForm, Button, Tooltip, Icon } from '../../../../UI';
import { rowComp, colComp } from '../../../../UI/Grid';
import {
  wrap,
  inputDescriptionContainer,
  formBtns,
  answerHeader,
  answerDataLabel,
  answerBody,
  answerDataItem,
  deleteButton,
} from './styles';

const AnswersAddForm = (props) => {
  const { data, onSubmit, onRemove } = props;

  const [editAnswer, setEditEditAnswer] = useState(isEmpty(data));

  useEffect(() => {
    setEditEditAnswer(isEmpty(data));
  }, [hash({ data })]);

  const handleSubmit = (formData) => {
    setEditEditAnswer(false);
    onSubmit({
      ...formData,
      // correctText: 'Correct answer!',
      rankingPoints: formData?.isCorrect ?? data?.isCorrect ?? false ? 1 : 0,
      visible: formData?.visible ?? data?.visible ?? false,
      isCorrect: formData?.isCorrect ?? data?.isCorrect ?? false,
    });
  };

  const handleRemove = () => {
    setEditEditAnswer(false);
    onRemove();
  };

  const SubmitBar = (submitProps) => (
    <div css={formBtns}>
      <Button onClick={submitProps.onSubmit}>Save</Button>
      <Button secondary disabled={data && isEmpty(data)} onClick={() => setEditEditAnswer(false)}>
        Cancel
      </Button>
    </div>
  );

  return (
    <div css={wrap}>
      {editAnswer ? (
        <Form renderSubmit={SubmitBar} onSubmit={handleSubmit} submitButton={{ children: 'Save' }} values={data}>
          <FormParagraph css={inputDescriptionContainer}>
            <FormInput
              id="text"
              type="textarea"
              label="Text"
              placeholder="Text"
              required
            />
          </FormParagraph>
          <FormParagraph css={rowComp({ margin: '0 -16px' })}>
            <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
              <CheckBoxForm id="visible" label="Visible" />
            </FormParagraph>
            <FormParagraph css={colComp({ xl: 12, lg: 12, horizontalGap: 16 })}>
              <CheckBoxForm id="isCorrect" label="IsCorrect" />
            </FormParagraph>
          </FormParagraph>
        </Form>
      ) : (
        <div>
          <div css={answerHeader}>
            <div>
              <Tooltip content={data?.visible ? 'visible' : 'not-visible'}>
                <Icon
                  material
                  color={data?.visible ? 'info' : 'gray'}
                  iconName={data?.visible ? 'visibility' : 'visibility_off'}
                />
              </Tooltip>
              <Tooltip content={data?.isCorrect ? 'correct' : 'not-correct'}>
                <Icon
                  material
                  color={data?.isCorrect ? 'green' : 'red'}
                  iconName={data?.isCorrect ? 'task_alt' : 'highlight_off'}
                />
              </Tooltip>
            </div>
            <div>
              <Row horizontalGap={2}>
                <Col flex="0 1 auto" dFlex>
                  <Button onClick={() => setEditEditAnswer(true)}>Edit</Button>
                </Col>
                <Col flex="0 1 auto" dFlex>
                  <Button
                    leftIcon={{
                      material: true,
                      color: 'white',
                      iconName: 'delete',
                    }}
                    css={deleteButton}
                    onClick={() => handleRemove(true)}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div css={answerBody}>
            <div css={answerDataItem}>
              <Row horizontalGap={5}>
                <Col flex="0 1 auto" dFlex>
                  <div css={answerDataLabel}>
                    <h4>Text:</h4>
                  </div>
                </Col>
                <Col flex="1 0 0px " dFlex>
                  <p>{data?.text}</p>
                </Col>
              </Row>
            </div>
            <div css={answerDataItem}>
              <Row horizontalGap={5}>
                <Col flex="0 1 auto" dFlex>
                  <div css={answerDataLabel}>
                    <h4>Ranking Points:</h4>
                  </div>
                </Col>
                <Col flex="1 0 0px " dFlex>
                  <p>{data?.rankingPoints}</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AnswersAddForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
};

export default AnswersAddForm;
