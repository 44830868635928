import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { exchangeCodeForToken } from '@oneecosystem/authenticate';
import { useAuth } from '../../../components/UI';

const RedirectCallback = ({ history }) => {
  const { prepareUser } = useAuth();

  useEffect(() => {
    handleSuccessLogin();
  }, []);

  const handleSuccessLogin = async () => {
    await exchangeCodeForToken();

    await prepareUser();

    history.push('/dashboard');
  };

  return (
    <div className="kt-splash-screen">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

RedirectCallback.propTypes = {
  history: PropTypes.object,
};

export default RedirectCallback;
