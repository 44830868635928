export const columns = [
  {
    name: 'Course ID',
    value: 'courseId',
    width: 100
  },
  {
    name: 'Course Name',
    value: 'courseName'
  },
  {
    name: 'Course Progress',
    value: 'completedLessonProgressLabel',
    width: 150,
  },
  {
    name: 'Completed On',
    value: 'completedOn',
    width: 150
  }
];
