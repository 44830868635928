import '../../../shim-global';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { isFunction, uniqueId, omit } from 'lodash-es';
import moment from 'moment';
import { DateRangePicker, SingleDatePicker, isInclusivelyBeforeDay, isInclusivelyAfterDay } from 'react-dates';
import { Icon } from '../../UI';
import { datePickerContainer, labelStyles } from './styles';

const propsToOmit = ['noFuture', 'onChange', 'label', 'showClear'];

const DatePicker = forwardRef((props, reference) => {
  const {
    startDate: startDateProp = null,
    endDate: endDateProp = null,
    onChange,
    single,
    noFuture = true,
    small = true,
    daySize = 30,
    numberOfMonths = 1,
    minDate,
    maxDate,
    label,
    showClear = true,
  } = props;

  const startDate = startDateProp && (moment.isMoment(startDateProp) ? startDateProp : moment(startDateProp));
  const endDate = endDateProp && (moment.isMoment(endDateProp) ? endDateProp : moment(endDateProp));
  const Component = single ? SingleDatePicker : DateRangePicker;
  const [value, setValue] = useState({ startDate, endDate });
  const [focusedInput, setFocusedInput] = useState(null);

  useImperativeHandle(reference, () => ({
    clearDates,
  }));

  useEffect(() => {
    setValue({ startDate, endDate });
  }, [startDate?.format('DD/MM/YYYY'), endDate?.format('DD/MM/YYYY')]);

  const handleDateChange = (newDates) => {
    setValue(newDates);
    isFunction(onChange) && newDates?.startDate && newDates?.endDate && onChange(newDates);
  };

  const clearDates = () => {
    if (!value.startDate && !value.endDate) return;
    const emptyDates = { startDate: null, endDate: null };
    setValue(emptyDates);
    isFunction(onChange) && onChange(emptyDates);
  };

  const checkIsOutsideRange = (day) => {
    switch (true) {
      case noFuture && !isInclusivelyBeforeDay(day, moment()):
        return true;
      case minDate && !isInclusivelyAfterDay(day, minDate):
        return true;
      case maxDate && !isInclusivelyBeforeDay(day, maxDate):
        return true;
      default:
        return false;
    }
  };

  return (
    <div css={datePickerContainer(props)}>
      {!!label && <label css={labelStyles}>{label}</label>}
      <Component
        small={small}
        daySize={daySize}
        numberOfMonths={numberOfMonths}
        {...omit(props, propsToOmit)}
        startDate={value.startDate}
        startDateId={uniqueId()}
        endDate={value.endDate}
        endDateId={uniqueId()}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        isOutsideRange={checkIsOutsideRange}
        displayFormat="DD/MM/YYYY"
      />
      {!!showClear && (
        <div className="clearIcon">
          <Icon material size={22} iconName="close" color="gray" onClick={clearDates} />
        </div>
      )}
    </div>
  );
});

DatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func,
  single: PropTypes.bool,
  noFuture: PropTypes.bool,
  small: PropTypes.bool,
  daySize: PropTypes.number,
  numberOfMonths: PropTypes.number,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
  showClear: PropTypes.bool,
};

export default DatePicker;
