import { isPlainObject } from 'lodash-es';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../Shared/SearchInput';
import { Col, Dropdown, Row } from '../../UI';
import { optionsAccountCoursesSearchBy } from './config';

const noResultsMessages = {
  catalogueItemId: 'Package',
  courseId: 'Course',
};

const TableFilterBarAccountCourses = ({ getData, tableOptions, setEmptyMessage }) => {
  const params = useRef({
    searchCriteria: optionsAccountCoursesSearchBy[0].value,
    searchInput: null,
    courseId: null,
  });

  useEffect(() => {
    getData(params.current);
  }, []);

  const fetchResults = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };

    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  return (
    <Row horizontalGap={16}>
      <Col xl={2} lg={3} sm={12} xs={12}>
        <Dropdown
          noClear
          value={optionsAccountCoursesSearchBy[0]}
          options={optionsAccountCoursesSearchBy}
          onChange={({ value: searchCriteria }) => {
            params.current = {
              ...params.current,
              searchCriteria,
            };

            params.current?.searchInput?.length && fetchResults();
          }}
        />
      </Col>
      <Col xl={10} lg={6} sm={12} xs={12}>
        <SearchInput
          placeholder="Search by package id or course id..."
          onChange={(searchInput) => fetchResults({ searchInput })}
        />
      </Col>
    </Row>
  );
};

TableFilterBarAccountCourses.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
};

export default TableFilterBarAccountCourses;
