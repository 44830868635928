import image from '../../../assets/images/error-bg.jpg';

export const mainContainer = {
  margin: -48,
  background: `url("${image}") no-repeat fixed center`,
  flex: 1,
};

export const content = {
  position: 'absolute',
  top: 100,
  left: 320,
  '& > h1': {
    fontWeight: 700,
    fontSize: 150,
  },
  '& > p': {
    fontSize: 20,
  },
};
