import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { Input, Icon } from '../../UI';
import { searchContainer, inputSearch, iconSearch, iconClear } from './styles';

const SearchInput = (props) => {
  const { placeholder, onChange, className, value } = props;
  const searchRef = useRef();
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (value) searchRef.current.value = value;
  }, [value]);

  const handleDelete = () => {
    searchRef.current.value = '';
    handleSearch();
    setShowDelete(false);
  };

  const handleKeyPress = ({ keyCode }) => {
    if (keyCode === 13) handleSearch();
    setShowDelete(!!searchRef.current.value);
  };

  const handleSearch = () => onChange(searchRef.current.value);

  return (
    <div css={searchContainer} {...(isString(className) && { className })}>
      <Input ref={searchRef} css={inputSearch} placeholder={placeholder} onKeyUp={handleKeyPress} />
      <Icon iconName="fas fa-search" css={iconSearch} color="grayLightest" size={20} onClick={handleSearch} />
      {showDelete && (
        <Icon iconName="fas fa-times" css={iconClear} color="grayLightest" size={20} onClick={handleDelete} />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
};

export default SearchInput;
