export const optionsAccountCoursesSearchBy = [
  {
    value: 'catalogueItemId',
    label: 'Package ID',
  },
  {
    value: 'courseId',
    label: 'Course ID',
  },
];
