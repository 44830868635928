import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash-es';
import { Row, Col } from '../../UI';
import SearchInput from '../../Shared/SearchInput';

const noResultsMessages = {
  searchInput: 'Category',
};

const TableSearchBarCategories = ({ getData, tableOptions, setEmptyMessage }) => {
  const params = useRef({
    searchCriteria: 'name',
    searchInput: null,
  });

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async (options) => {
    params.current = {
      ...params.current,
      ...options,
    };

    await getData(params.current);

    if (tableOptions.current.totalResults === 0 && isPlainObject(options)) {
      const matchMessage = Object.keys(options)?.find((el) => noResultsMessages[el]);
      matchMessage && setEmptyMessage(`There are no results for this ${noResultsMessages[matchMessage]}`);
    }
  };

  return (
    <Row>
      <Col>
        <SearchInput placeholder="Search by name..." onChange={(searchInput) => fetchResults({ searchInput })} />
      </Col>
    </Row>
  );
};

TableSearchBarCategories.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  setEmptyMessage: PropTypes.func,
};

export default TableSearchBarCategories;
