import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import { Form, FormInput, CheckBoxForm, Button, Tooltip, Icon, FormParagraph } from '../../../../UI';
import { wrap, questionInfo, questionInfoTitle, inputDescriptionContainer, formBtns, removeBtn } from './styles';

const QuestionsAddForm = (props) => {
  const { data, onSubmit, onRemove } = props;

  const [editQuestion, setEditEditQuestion] = useState(isEmpty(data));

  useEffect(() => {
    setEditEditQuestion(isEmpty(data));
  }, [hash({ data })]);

  const handleSubmit = (formData) => {
    setEditEditQuestion(false);
    onSubmit({
      ...formData,
      visible: formData?.visible ?? data?.visible ?? false,
    });
  };

  const SubmitBar = (submitProps) => (
    <div css={formBtns}>
      <Button onClick={submitProps.onSubmit}>Save</Button>
      <Button secondary disabled={data && isEmpty(data)} onClick={() => setEditEditQuestion(false)}>
        Cancel
      </Button>
    </div>
  );

  return (
    <div css={wrap}>
      <div css={removeBtn} onClick={onRemove} role="presentation">
        <Icon material color="primary" iconName="delete" />
      </div>
      {editQuestion ? (
        <Form renderSubmit={SubmitBar} onSubmit={handleSubmit} submitButton={{ children: 'Save' }} values={data}>
          <FormParagraph css={inputDescriptionContainer}>
            <FormInput
              id="title"
              type="textarea"
              label="Title"
              placeholder="Title"
              required
            />
          </FormParagraph>
          <CheckBoxForm id="visible" label="Visible" />
        </Form>
      ) : (
        <div css={questionInfo}>
          <div css={questionInfoTitle}>
            <Tooltip content={data?.visible ? 'visible' : 'not-visible'}>
              <Icon
                material
                color={data?.visible ? 'info' : 'gray'}
                iconName={data?.visible ? 'visibility' : 'visibility_off'}
              />
            </Tooltip>
            <h3>{data?.title}</h3>
          </div>
          <div>
            <Button onClick={() => setEditEditQuestion(true)}>Edit</Button>
          </div>
        </div>
      )}
    </div>
  );
};

QuestionsAddForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
};

export default QuestionsAddForm;
