export const courseData = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const courseInfo = {
  display: 'inline-block',
  marginBottom: 4,
  marginRight: 8,
};

export const infoContainer = {
  fontWeight: 600,

  '& > span': {
    fontWeight: 400,
    marginLeft: 8,
  },
};

export const marginBottom4 = {
  marginBottom: 4,
};
