import { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Modal, Button } from '../../UI';
import { modalWrap } from './styles';

const DeleteConfirmationModal = forwardRef((props, ref) => {
  const { onDelete, onClose } = props;
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false),
  }));
  return (
    <Modal css={modalWrap} show={show}>
      <div className="inner">
        <h3>Delete confirmation!</h3>
        <p>Click &apos;Delete&apos; button to confirm record removal?</p>
        <div className="btn-box">
          <Button
            secondary
            onClick={() => {
              onClose && isFunction(onClose) && onClose();
              setShow(false);
            }}
            margin="0 1em 0 0">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDelete && isFunction(onDelete) && onDelete();
              setShow(false);
            }}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
});

DeleteConfirmationModal.propTypes = {
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteConfirmationModal;
