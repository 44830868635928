import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { orderBy } from 'lodash-es';
import {
  Paper,
  Breadcrumbs,
  Dropdown,
  Row,
  Col,
  headerContainer,
  showSuccess,
  showApiError,
} from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { pagesOrder, courseStatus } from './config';
import { getAllCategories } from '../../../services/categories';
import { getAllCourses, updateCourse } from '../../../services/courses';
import { wrapper, backContainer, courseOrderWrap } from './styles';

const OrderCourses = () => {
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();
    err ? showApiError(err) : setCategories(res?.data ?? []);
  };

  const fetchCoursesByCategory = async (option) => {
    const options = {
      pageSize: 50,
      pageIndex: 0,
      categoryId: option?.id,
    };

    const [res, err] = await getAllCourses(options);
    if (err) return showApiError(err);

    const newCourses = orderBy(res?.data, ['courseOrder'], ['asc']);
    setCourses(newCourses ?? []);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const coursesPrev = [...courses];

    setCourses(reorder([...courses], result.source.index, result.destination.index));

    const [, error] = await updateCourse(result.draggableId, {
      ...courses[result.source.index],
      afterCourseId: result.destination.index !== 0 ? courses[result.destination.index - 1].id : 0,
    });

    if (error) {
      setCourses(coursesPrev);
      return showApiError(error);
    }

    showSuccess({
      title: 'Reorder course',
      message: 'Successfully reorder courses.',
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pagesOrder} />
      <BackButton css={backContainer} title="Order Courses" />
      <Paper
        header={
          <>
            <div css={headerContainer}>
              <Row gap={8} align="center">
                <Col flex="0 1 auto">
                  <h4>Select Category:</h4>
                </Col>
                <Col flex="1 0 0px ">
                  <Dropdown
                    withSearch
                    placeholder="Select category..."
                    noClear
                    options={categories}
                    displayKey="name"
                    uniqueKey="id"
                    onChange={fetchCoursesByCategory}
                  />
                </Col>
              </Row>
            </div>
          </>
        }>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {courses?.map((el, index) => (
                  <Draggable key={String(el.id)} draggableId={String(el.id)} index={index}>
                    {(draggableProvided) => (
                      <div
                        key={el.id}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        css={courseOrderWrap}>
                        <h4>{el.name}</h4>
                        <ColorBatch type={courseStatus[el.status]?.color}>{courseStatus[el.status]?.text}</ColorBatch>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
    </div>
  );
};

export default OrderCourses;
