const MENU_WIDTH = 240;
const MENU_WIDTH_SMALL = 55;

export const mainContainer = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
};

export const sideNavContainer = (isOpen) => (theme) => ({
  backgroundColor: theme.secondaryDark,
  width: isOpen ? MENU_WIDTH : MENU_WIDTH_SMALL,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  color: theme.textDarkSecondary,
  transition: 'all 400ms',

  '@media screen and (max-width: 991px)': {
    paddingTop: 24,
    width: MENU_WIDTH_SMALL,

    '&:hover': {
      width: MENU_WIDTH,
    },
  },
});

export const menuListContent = {
  overflow: 'hidden auto',
  flex: 1,
  paddingBottom: 16,
};

export const contentContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};

export const content = (withPadding) => [
  {
    flex: 1,
  },
  withPadding && {
    padding: 40,
  },
];

export const menuItem = (theme) => ({
  fontWeight: 400,
  color: 'inherit',
  backgroundColor: 'transparent',
  borderLeft: '4px solid transparent',
  display: 'block',
  padding: '12px 0',

  '&:hover': {
    color: theme.white,
  },

  '& i': {
    margin: '0 10px',
    color: 'inherit',
  },

  '&.active': {
    color: theme.textDarkPrimary,
    backgroundColor: theme.secondaryDark,
    borderLeftColor: theme.error,
  },
});

export const menuItemContent = {
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row nowrap',
};

export const menuItemText = {
  width: MENU_WIDTH - 47,
  position: 'absolute',
  left: 56,
};

export const logoContainer = (theme) => ({
  height: 54,
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.borderVeryLight}`,
  padding: '0 10px',

  '@media screen and (max-width: 991px)': {
    display: 'none',
  },
});

export const iconToggler = {
  '& > svg': {
    width: 26,
    height: 26,
    opacity: 0.6,
    cursor: 'pointer',

    '&:hover': {
      opacity: 1,
    },

    '@media screen and (max-width: 991px)': {
      display: 'none',
    },
  },
};

export const logo = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  '& > svg': {
    width: '100%',
    maxWidth: 160,
    height: 'auto',
  },
};

export const menuSeparator = (theme) => ({
  padding: '24px 8px 8px 20px',
  color: theme.white,
  borderBottom: `1px solid ${theme.white}`,

  '&:first-child': {
    paddingTop: 8,
  },
});
