import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Paper, Form, FormInput, Breadcrumbs, showSuccess, showApiError } from '../../../../components/UI';
import BackButton from '../../../../components/Shared/BackButton';
import LanguageSelect from '../../../../components/Shared/LanguageSelect';
import { postNewBadgeTranslation, getBadgeByIdTranslation, updateBadgeTranslations } from '../../../../services/badges';
import { pagesTranslation } from './config';
import { wrapper, backContainer, paperContain } from '../styles';

const AddEditBadgeTranslations = () => {
  const history = useHistory();
  const { badgeId, languageCode } = useParams();
  const [badgeData, setBadgeData] = useState({});

  useEffect(() => {
    fetchBadgeData();
  }, [badgeId, languageCode]);

  const fetchBadgeData = async () => {
    const [res, err] = await getBadgeByIdTranslation(badgeId, languageCode);

    if (err && !(err?.data?.code === 1005)) showApiError(err);

    setBadgeData(res ?? {});
  };

  const handleSubmit = async (formData) => {
    const [, error] = await (!isEmpty(badgeData)
      ? updateBadgeTranslations(badgeId, languageCode, formData)
      : postNewBadgeTranslation(badgeId, { ...formData, languageCode }));
    if (error) return showApiError(error);
    showSuccess({
      title: `${badgeId ? 'Edited' : 'Create'} badge translation`,
      message: `Successfully ${badgeId ? 'edited' : 'create'} badge translation.`,
    });

    setBadgeData((prev) => ({ ...prev, ...formData }));
  };

  const handleLanguageChange = (code) => {
    history.push(`/badges/badge/${badgeId}/${code}`);
  };

  return (
    <div css={wrapper}>
      <Breadcrumbs items={pagesTranslation(badgeId, !isEmpty(badgeData))} />
      <BackButton
        linkTo="/badges/all-badges"
        css={backContainer}
        title={!isEmpty(badgeData) ? 'Edit Badge Translations' : 'Add Badge Translations'}
      />
      {badgeId && <LanguageSelect onChange={handleLanguageChange} />}
      <Paper css={paperContain}>
        <Form
          onSubmit={handleSubmit}
          submitButton={{
            children: !isEmpty(badgeData) ? 'Edit Badge Translations' : 'Add BadgeTranslations',
          }}
          values={badgeData}>
          <FormInput id="name" label="Name" required />
        </Form>
      </Paper>
    </div>
  );
};

export default AddEditBadgeTranslations;
